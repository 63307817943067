






































































































































































































































































































































































































































































































































































































import { ServerError } from "@common/errors";
import { IServerRES } from "@common/server";
import { IConfig } from "@common/config";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Vue } from "vue-property-decorator";
import { GroupType, IGroup, NO_GROUP } from "@common/groups";
import { BRDBuildings, IUser, NO_USER, Roles } from "@common/users";
import { mapGetters } from "vuex";
import { IDesk, NO_DESK } from "@common/desks";
import { IConferenceRoom } from "@common/conferenceRooms";
import { IBRDCredentials } from "@common/brdCredentials";
import DragDropUpload from "@/components/flexioffice/DragDropUpload.vue";
import { ReservationStatus } from "@common/reservations";
import _ from "lodash";

export default Vue.extend({
	name: "AdminSettings",
	data () {
		const params: IConfig[] = [];
		const groups: IGroup[] = [];
		const filteredGroups: IGroup[] = [];
		const changed = false;
		const isGroups = false;
		const isParams = false;
		const isDesk = false;
		const isGroup = false;
		const isUsers = false;
		const selectedGroup: IGroup = NO_GROUP;
		const selectedDesks: IDesk[] = [];
		const selectedDesksFiltered: IDesk[] = [];
		const allUsers: IUser[] = [];
		const filteredUsers: IUser[] = [];
		const allDesks: IDesk[] = [];
		const allRooms: IConferenceRoom[] = [];
		const selectedUsers: IUser[] = [];
		const selectedUsersFiltered: IUser[] = [];
		const selectedUser: IUser = NO_USER;
		const selectedUserGroups: IGroup[] = [];
		const selectedManagerGroups: IGroup[] = [];
		const selectedUserAddGroups: IGroup[] = [];
		const selectedUserManagerAddGroups: IGroup[] = [];
		const selectedDeskAddGroups: IGroup[] = [];
		const selectedDeskManager: IUser = NO_USER;
		const loading = false;
		const groupDetailsType = "";
		const isUserInfo = false;
		const selectedDesk: IDesk = NO_DESK;
		const selectedDeskGroups: IGroup[] = [];
		const isDeskInfo = false;
		const searchQuery = "";
		const searchQuerySecond = "";
		const filteredDesks: IDesk[] = [];
		const newGroup: IGroup = NO_GROUP;
		newGroup.parkingSpots = 0;
		const newGroupUsers: IUser[] = [];
		const newGroupManagers: IUser[] = [];
		const newGroupDesks: IDesk[] = [];
		const newGroupRooms: IConferenceRoom[] = [];
		const emailListObj: {
			email: string,
			name: string,
			username: string,
			mainGroup: string,
			groups: string[],
			manager: string,
			managerOf?: string[],
			director: string,
			parkingRight: boolean,
			mainGroupObj?: IGroup,
			groupsObj?: IGroup[],
			role: Roles,
			valid: string
		}[] = [];
		const accountsDataHeader: {
			text: string,
			align: string,
			value: string,
			sortable: boolean
		}[] = [
			{
				text: "Email",
				align: "center",
				value: "email",
				sortable: true
			},
			{
				text: "Nume complet",
				align: "center",
				value: "name",
				sortable: true
			},
			{
				text: "Username",
				align: "center",
				value: "username",
				sortable: true
			},
			{
				text: "Departament",
				align: "center",
				value: "mainGroup",
				sortable: true
			},
			{
				text: "Directia",
				align: "center",
				value: "groups",
				sortable: true
			},
			{
				text: "Manager",
				align: "center",
				value: "manager",
				sortable: true
			},
			{
				text: "Director",
				align: "center",
				value: "director",
				sortable: true
			},
			{
				text: "Cont valid",
				align: "center",
				value: "valid",
				sortable: true
			},
			{
				text: "Drept Loc de Parcare",
				align: "center",
				value: "parkingRight",
				sortable: true
			}
		];

		const presenceDataHeader: {
			text: string,
			align: string,
			value: string,
			sortable: boolean
		}[] = [
			{
				text: "Data",
				align: "center",
				value: "date",
				sortable: true
			},
			{
				text: "Email",
				align: "center",
				value: "email",
				sortable: true
			}
		];

		const attendingListObj: {
			date: string,
			email: string
		}[] = [];

		const deskTypes: {
			text: string,
			value: string
		}[] = [
			{
				text: "Blocat",
				value: ""
			},
			{
				text: "Alocat",
				value: "Reserved"
			}
		];

		return {
			groups: groups,
			filteredGroups: filteredGroups,
			params: params,
			isGroups,
			isParams,
			isDesk,
			isGroup,
			changed,
			selectedGroup: selectedGroup,
			allUsers: allUsers,
			filteredUsers,
			selectedUsers: selectedUsers,
			selectedUsersFiltered: selectedUsersFiltered,
			loading,
			allDesks,
			groupDetailsType,
			selectedDesks,
			selectedDesksFiltered,
			isUsers,
			selectedUser,
			isUserInfo,
			selectedUserGroups,
			selectedUserAddGroups,
			selectedManagerGroups,
			selectedUserManagerAddGroups,
			selectedDesk,
			selectedDeskGroups,
			isDeskInfo,
			selectedDeskAddGroups,
			selectedDeskManager,
			searchQuery,
			searchQuerySecond,
			filteredDesks,
			isNewGroup: false,
			newGroup: newGroup,
			newGroupType: newGroup.type === GroupType.PROJECT,
			newGroupUsers: newGroupUsers,
			newGroupManagers: newGroupManagers,
			newGroupDesks: newGroupDesks,
			newGroupRooms: newGroupRooms,
			allRooms: allRooms,
			attendingList: false,
			emailList: false,
			emailListObj: emailListObj,
			accountsDataHeader: accountsDataHeader,
			attendingListObj: attendingListObj,
			presenceDataHeader: presenceDataHeader,
			snackbar: false,
			multiline: false,
			snackbarText: "Test",
			snackbarColor: "success",
			deskTypes: deskTypes,
		};
	},
	mounted () {
		this.fetchData();
	},
	components: {
		DragDropUpload
	},
	methods: {
		async fetchData () {
			this.groups = (await this.$store.dispatch("userStore/loadGroups", { role: "admin" })).groupList;
			this.filteredGroups = this.groups.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.type.localeCompare(b.type));
			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					url: `${this.$store.getters.API_URL}/config`
				};
				const response: AxiosResponse<IServerRES<IConfig[]>> = await axios(options);
				if (response.data.err === ServerError.NO_ERROR) {
					this.params = response.data.payload.filter((param: IConfig) => param.building === BRDBuildings.Flexioffice);
				}
			} catch (error) {
				console.error(error);
			}

			this.allUsers = await this.$store.dispatch("userStore/loadAllUsers");
			this.filteredUsers = this.allUsers;
			this.allDesks = await this.$store.dispatch("deskStore/loadAll");
			this.filteredDesks = this.allDesks;

			await this.$store.dispatch("roomStore/loadDetails", {
				token: this.$store.getters["userStore/token"]
			});
			this.allRooms = this.$store.getters["roomStore/roomsDetails"];
			this.$forceUpdate();
		},
		async saveChanges () {
			this.loading = true;

			try {
				for (let index = 0; index < this.params.length; index++) {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${this.$store.getters.API_URL}/config/update`,
						data: this.params[index],
						headers: {
							Authorization: `Bearer ${this.$store.getters["userStore/token"]}`
						}
					};
					const response: AxiosResponse<IServerRES<IConfig>> = await axios(options);
					this.loading = false;
					this.snackbar = true;

					if (response.data.err === ServerError.NO_ERROR) {
						this.params[index] = response.data.payload;
						this.changed = false;
						this.snackbarText = "Parametrii actualizați cu succes!";
						this.snackbarColor = "success";
					} else {
						this.snackbarText = "Eroare la actualizarea parametrilor!";
						this.snackbarColor = "error";
					}
				}
			} catch (error) {
				console.error(error);
			}
		},
		async addUserGroup (user: IUser, group: IGroup) {
			if (group._id) {
				user.groupList.push(group._id);
			}

			const response = await this.$store.dispatch("userStore/update", {
				user: user,
				token: this.userState.token
			});

			this.snackbar = true;

			if (response) {
				this.selectedUsers.push(user);
				this.selectedUsersFiltered = this.selectedUsers;

				this.snackbarText = "Utilizatorul a fost adăugat cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbarText = "Eroare la adăugarea utilizatorului!";
				this.snackbarColor = "error";
			}
		},
		async removeUserGroup (user: IUser, group: IGroup) {
			if (group._id) {
				user.groupList.splice(user.groupList.indexOf(group._id), 1);
			}

			const response = await this.$store.dispatch("userStore/update", {
				user: user,
				token: this.userState.token
			});

			this.snackbar = true;

			if (response) {
				this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
				this.selectedUsersFiltered = this.selectedUsers;

				this.snackbarText = "Utilizatorul a fost eliminat cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbarText = "Eroare la eliminarea utilizatorului!";
				this.snackbarColor = "error";
			}
		},
		async addDeskGroup (desk: IDesk, group: IGroup) {
			if (group._id) {
				desk.groupsIdList.push(group._id);
			}

			const response = await this.$store.dispatch("deskStore/update", {
				desk: desk,
				token: this.userState.token
			});

			this.snackbar = true;

			if (response) {
				this.selectedDesks.push(desk);
				this.selectedDesksFiltered = this.selectedDesks;

				this.snackbarText = "Biroul a fost adăugat cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbarText = "Eroare la adăugarea biroului!";
				this.snackbarColor = "error";
			}
		},
		async deleteDeskGroup (desk: IDesk, group: IGroup) {
			if (group._id) {
				desk.groupsIdList.splice(desk.groupsIdList.indexOf(group._id), 1);
			}

			const response = await this.$store.dispatch("deskStore/update", {
				desk: desk,
				token: this.userState.token
			});

			this.snackbar = true;

			if (response) {
				this.selectedDesks.splice(this.selectedDesks.indexOf(desk), 1);
				this.selectedDesksFiltered = this.selectedDesks;

				this.snackbarText = "Biroul a fost eliminat cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbarText = "Eroare la eliminarea biroului!";
				this.snackbarColor = "error";
			}
		},
		openParams () {
			this.isParams = true;
			this.isGroups = false;
			this.isGroup = false;
			this.isUsers = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = false;

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		openGroups (type: string) {
			this.isParams = false;
			this.isGroups = true;
			this.isGroup = false;
			this.isUsers = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = false;

			this.groupDetailsType = type;

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		openNewDep () {
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = false;
			this.isUsers = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isNewGroup = true;
			this.attendingList = false;
			this.emailList = false;

			this.newGroup = _.cloneDeep(NO_GROUP);
			this.newGroup.parkingSpots = 0;
			this.newGroupUsers = [];
			this.newGroupManagers = [];
			this.newGroupDesks = [];
			this.newGroupRooms = [];

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		openDesks () {
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = false;
			this.isUsers = false;
			this.isUserInfo = false;
			this.isDesk = true;
			this.isDeskInfo = false;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = false;

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		openGroupDesk () {
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = true;
			this.isUsers = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = false;

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		openUsers () {
			this.isUsers = true;
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = false;

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		async getGroupInfo (group: IGroup) {
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = false;
			this.isUsers = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isGroup = true;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = false;

			if (this.groupDetailsType === "users") {
				this.selectedUsers = this.allUsers.filter(user => user.groupList.includes(group._id ? group._id : "-1"));
				this.selectedUsersFiltered = this.selectedUsers;
			} else if (this.groupDetailsType === "desks") {
				this.selectedDesks = this.allDesks.filter(desk => desk.groupsIdList.includes(group._id ? group._id : "-1"));
				this.selectedDesksFiltered = this.selectedDesks;
			}

			this.selectedGroup = _.cloneDeep(group);
			this.$forceUpdate();

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		async getDeskInfo (desk: IDesk) {
			this.isUsers = false;
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = true;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = false;

			this.selectedDesk = _.cloneDeep(desk);
			this.selectedDeskGroups = this.groups.filter(group => this.selectedDesk.groupsIdList.includes(group._id ? group._id : "-1"));

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		async getUserInfo (user: IUser) {
			this.isUsers = false;
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = false;
			this.isUserInfo = true;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = false;

			this.selectedUser = _.cloneDeep(user);
			this.selectedUserGroups = this.groups.filter(group => this.selectedUser.groupList.includes(group._id ? group._id : "-1"));
			this.selectedManagerGroups = this.groups.filter(group => this.selectedUser.managerOf?.includes(group._id ? group._id : "-1"));

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		openAttendingListUpload () {
			this.isUsers = false;
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isNewGroup = false;
			this.attendingList = true;
			this.emailList = false;

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		openEmailList () {
			this.isUsers = false;
			this.isParams = false;
			this.isGroups = false;
			this.isGroup = false;
			this.isUserInfo = false;
			this.isDesk = false;
			this.isDeskInfo = false;
			this.isNewGroup = false;
			this.attendingList = false;
			this.emailList = true;

			this.searchQuery = "";
			this.searchQuerySecond = "";
		},
		addUserGroups () {
			this.selectedUserGroups = this.selectedUserGroups.concat(this.selectedUserAddGroups);
			this.selectedUser.groupList = this.selectedUserGroups.map(group => group._id ? group._id : "-1");
			this.selectedUserAddGroups = [];

			this.$forceUpdate();
		},
		addManagerGroups () {
			this.selectedManagerGroups = this.selectedManagerGroups.concat(this.selectedUserManagerAddGroups);
			this.selectedUser.managerOf = this.selectedManagerGroups.map(group => group._id ? group._id : "-1");
			this.selectedUserManagerAddGroups = [];

			this.$forceUpdate();
		},
		removeGroupUser (group: IGroup) {
			this.selectedUserGroups.splice(this.selectedUserGroups.indexOf(group), 1);
			this.selectedUser.groupList.splice(this.selectedUser.groupList.indexOf(group._id ? group._id : "-1"), 1);
		},
		removeManagerGroupUser (group: IGroup) {
			this.selectedManagerGroups.splice(this.selectedManagerGroups.indexOf(group), 1);

			if (this.selectedUser.managerOf) {
				this.selectedUser.managerOf.splice(this.selectedUser.managerOf.indexOf(group._id ? group._id : "-1"), 1);
			}
		},
		async saveUser () {
			this.loading = true;

			const res = await this.$store.dispatch("userStore/update", {
				user: this.selectedUser,
				token: this.userState.token
			});

			this.allUsers = await this.$store.dispatch("userStore/loadAllUsers");
			this.filteredUsers = this.allUsers;

			this.loading = false;
			this.snackbar = true;
			if (res) {
				this.snackbarText = "Utilizatorul a fost actualizat cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbarText = "A intervenit o eroare la actualizarea utilizatorului!";
				this.snackbarColor = "error";
			}
		},
		async resetUserPassword (user: IUser) {
			this.loading = true;

			const res = await this.$store.dispatch("userStore/randomPassword", {
				user,
				token: this.userState.token
			});

			this.loading = false;
			this.snackbar = true;

			if (res) {
				this.snackbarText = "Parola a fost resetată cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbarText = "A intervenit o eroare la resetarea parolei!";
				this.snackbarColor = "error";
			}
		},
		async deleteUser (user: IUser) {
			this.loading = true;

			const res = await this.$store.dispatch("userStore/delete", {
				user,
				token: this.userState.token
			});

			this.loading = false;
			this.snackbar = true;

			if (res) {
				this.allUsers = await this.$store.dispatch("userStore/loadAllUsers");
				this.filteredUsers = this.allUsers;
				this.openUsers();

				this.snackbarText = "Utilizatorul a fost șters cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbarText = "A intervenit o eroare la ștergerea utilizatorului!";
				this.snackbarColor = "error";
			}

			this.loading = false;
		},
		async deleteGroup (group: IGroup) {
			this.loading = true;

			const usersInGroup = this.allUsers.filter(user => user.groupList.includes(group._id ? group._id : "-1"));
			const managersInGroup = this.allUsers.filter(user => user.managerOf?.includes(group._id ? group._id : "-1"));
			const desksInGroup = this.allDesks.filter(desk => desk.groupsIdList.includes(group._id ? group._id : "-1"));
			const roomsInGroup = this.allRooms.filter(room => room.groupsIdList.includes(group._id ? group._id : "-1"));

			for (const user of usersInGroup) {
				user.groupList.splice(user.groupList.indexOf(group._id ? group._id : "-1"), 1);

				await this.$store.dispatch("userStore/update", {
					user: user,
					token: this.userState.token
				});
			}

			for (const man of managersInGroup) {
				if (man.managerOf) {
					man.managerOf.splice(man.managerOf.indexOf(group._id ? group._id : "-1"), 1);
				}

				await this.$store.dispatch("userStore/update", {
					user: man,
					token: this.userState.token
				});
			}

			for (const desk of desksInGroup) {
				desk.groupsIdList.splice(desk.groupsIdList.indexOf(group._id ? group._id : "-1"), 1);

				await this.$store.dispatch("deskStore/update", {
					desk: desk
				});
			}

			for (const room of roomsInGroup) {
				room.groupsIdList.splice(room.groupsIdList.indexOf(group._id ? group._id : "-1"), 1);

				await this.$store.dispatch("roomStore/update", {
					roomEntry: room,
					token: this.userState.token
				});
			}

			const res = await this.$store.dispatch("userStore/deleteGroup", {
				group: group,
				token: this.userState.token
			});

			if (res) {
				await this.fetchData();
			}
			this.loading = false;
		},
		async saveNewDep () {
			this.loading = true;
			delete this.newGroup._id;
			// this.newGroup.parkingSpots = parseInt(this.newGroup.parkingSpots);

			const dbG = await this.$store.dispatch("userStore/updateGroup", {
				group: this.newGroup,
				token: this.userState.token
			});

			if (dbG) {
				for (const user of this.newGroupUsers) {
					user.groupList.push(dbG._id);
					await this.$store.dispatch("userStore/update", {
						user: user,
						token: this.userState.token
					});
				}

				for (const man of this.newGroupManagers) {
					if (man.managerOf) {
						man.managerOf.push(dbG._id);
					} else {
						man.managerOf = [dbG._id];
					}
					man.groupList.push(dbG._id);

					await this.$store.dispatch("userStore/update", {
						user: man,
						token: this.userState.token
					});
				}

				for (const desk of this.newGroupDesks) {
					desk.groupsIdList.push(dbG._id);
					await this.$store.dispatch("deskStore/update", {
						desk: desk
					});
				}

				for (const room of this.newGroupRooms) {
					room.groupsIdList.push(dbG._id);
					await this.$store.dispatch("roomStore/update", {
						token: this.userState.token,
						roomEntry: room
					});
				}

				this.snackbar = true;
				this.snackbarText = "Departamentul a fost adăugat cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbar = true;
				this.snackbarText = "A intervenit o eroare la crearea departamentului!";
				this.snackbarColor = "error";
			}

			await this.fetchData();
			this.loading = false;
			this.openGroups("users");
		},
		addGroupDesk () {
			this.selectedDeskGroups = this.selectedDeskGroups.concat(this.selectedDeskAddGroups);
			this.selectedDesk.groupsIdList = this.selectedDeskGroups.map(group => group._id ? group._id : "-1");
			this.selectedDeskAddGroups = [];

			this.$forceUpdate();
		},
		removeGroupDesk (group: IGroup) {
			this.selectedDeskGroups.splice(this.selectedDeskGroups.indexOf(group), 1);
			this.selectedDesk.groupsIdList.splice(this.selectedDesk.groupsIdList.indexOf(group._id ? group._id : "-1"), 1);
		},
		async saveDesk () {
			this.loading = true;

			const res = await this.$store.dispatch("deskStore/update", {
				desk: this.selectedDesk,
				token: this.userState.token
			});

			this.allDesks = await this.$store.dispatch("deskStore/loadAll");
			this.filteredDesks = this.allDesks;

			this.loading = false;

			if (res) {
				this.snackbarText = "Biroul a fost actualizat cu succes!";
				this.snackbarColor = "success";
				this.snackbar = true;
				this.multiline = false;
			} else {
				this.snackbarText = "A apărut o eroare la actualizarea biroului!";
				this.snackbarColor = "error";
				this.snackbar = true;
				this.multiline = false;
			}
		},
		async saveGroupSettings () {
			const res = await this.$store.dispatch("userStore/updateGroup", {
				group: this.selectedGroup,
				token: this.userState.token
			});

			this.snackbar = true;

			if (res._id !== -1) {
				this.openGroups("settings");

				this.snackbarText = "Setările parcării au fost actualizate cu succes!";
				this.snackbarColor = "success";
			} else {
				this.snackbarText = "A apărut o eroare la actualizarea setărilor parcării!";
				this.snackbarColor = "error";
			}
		},
		getParamName (param: string) {
			switch (param) {
				case "maxDaysRoom":
					return "Interval maxim de zile pentru care poate fi rezervat o sală de conferințe";
				case "maxDaysDesk":
					return "Interval de zile disponibil pentru care poate fi rezervat un birou";
				case "maxActiveRoom":
					return "Număr maxim de rezervări de săli de conferințe";
				case "maxActiveDesk":
					return "Număr maxim de rezervări de birouri";
				default:
					return "Eroare";
			}
		},
		async uploadAttending () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					url: `${this.$store.getters.API_URL}/reservations/desks/attending`,
					data: {
						attendingList: this.attendingListObj,
						token: this.userState.token
					}
				};
				const response: AxiosResponse<IServerRES<IConfig[]>> = await axios(options);
				if (response.data.err === ServerError.NO_ERROR) {
					this.openUsers();
				}
			} catch (error) {
				console.error(error);
			}

			this.loading = false;
		},
		addAttendingList (attendingList: {date: string, email: string}[]) {
			this.attendingListObj = attendingList;
		},
		removeAttending () {
			this.attendingListObj = [];
		},
		addEmailList (emailList: {email: string, name: string, username: string, mainGroup: string, groups: string[], manager: string, director: string, parkingRight: boolean, role: Roles, valid: string}[]) {
			this.emailListObj = emailList;
			this.emailListObj.forEach(user => {
				const group = this.groups.find(group => group.name === user.mainGroup);
				user.valid = "Adevarat";

				if (group) {
					user.mainGroupObj = group;
				} else {
					user.mainGroupObj = NO_GROUP;
					user.valid = "Fals";
				}
			});
		},
		removeEmailList () {
			this.emailListObj = [];
		},
		async uploadEmailList () {
			this.loading = true;

			const registerUsers: {
				accounts: {
					credentials: IBRDCredentials,
					userInfo: IUser
				}[]
			} = {
				accounts: []
			};

			const registerManagerDepartament: {
				accounts: {
					credentials: IBRDCredentials,
					userInfo: IUser
				}[]
			} = {
				accounts: []
			};

			const managerDepartament: {
				userName: string,
				departamentsId: string[]
			}[] = [];

			for (const newUser of this.emailListObj) {
				let mainGroupId = "-1";
				const groupsId = [];

				if (newUser.mainGroupObj === NO_GROUP) {
					continue;
				} else {
					mainGroupId = newUser.mainGroupObj?._id ? newUser.mainGroupObj?._id : "-1";
					groupsId.push(mainGroupId);
				}
				if (newUser.groups) {
					const managerDepartamentName = newUser.manager !== "-" ? newUser.manager : newUser.name;
					const managerDepartamentObj = managerDepartament.find(managerDepartamentObj => managerDepartamentObj.userName === managerDepartamentName);

					for (const group of newUser.groups) {
						const groupObj = this.groups.find(groupObj => groupObj.name === group);

						if (groupObj) {
							groupsId.push(groupObj._id ? groupObj._id : "-1");

							if (managerDepartamentObj) {
								managerDepartamentObj.departamentsId.push(groupObj._id ? groupObj._id : "-1");
							} else {
								managerDepartament.push({
									userName: managerDepartamentName,
									departamentsId: [groupObj._id ? groupObj._id : "-1"]
								});
							}
						} else {
							const newGroup: IGroup = {
								name: group,
								type: GroupType.PROJECT,
								parkingSpots: 0,
								building: BRDBuildings.Flexioffice
							};

							const dbG = await this.$store.dispatch("userStore/updateGroup", {
								group: newGroup,
								token: this.userState.token
							});

							if (dbG._id !== -1) {
								groupsId.push(dbG._id);
								this.groups.push(dbG);

								if (managerDepartamentObj) {
									managerDepartamentObj.departamentsId.push(dbG._id);
								} else {
									managerDepartament.push({
										userName: newUser.manager,
										departamentsId: [dbG._id]
									});
								}
							}
						}
					}
				} else {
					newUser.groupsObj = [NO_GROUP];
				}

				const userInfo: IUser = {
					uuid: "0",
					email: newUser.email,
					name: newUser.name,
					groupList: groupsId,
					mainGroup: mainGroupId,
					building: BRDBuildings.Flexioffice,
					buildingList: [BRDBuildings.Flexioffice],
					manager: newUser.manager,
					director: newUser.director,
					parkingRight: newUser.parkingRight,
					confirmed: false
				};

				if (newUser.director === "-") {
					newUser.role = Roles.MANAGER;
					userInfo.managerOf = [mainGroupId];
				} else {
					newUser.role = Roles.USER;
				}

				if (!(newUser.manager === "-" && newUser.director !== "-")) {
					const credentials: IBRDCredentials = {
						username: newUser.username,
						password: "123456"
					};

					registerUsers.accounts.push({
						credentials: credentials,
						userInfo: userInfo
					});

					try {
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${this.$store.getters.API_URL}/users/massRegister`,
							data: {
								accounts: registerUsers.accounts
							},
							headers: {
								Authorization: `Bearer ${this.userState.token}`
							}
						};

						await axios(options);
					} catch (err) {
						console.error(err);
					}
					registerUsers.accounts = [];
				} else {
					registerManagerDepartament.accounts.push({
						credentials: {
							username: newUser.username,
							password: "123456"
						},
						userInfo: userInfo
					});
				}
			}

			for (const manager of registerManagerDepartament.accounts) {
				const managerDepartmentObj = managerDepartament.find(managerDepartamentObj => managerDepartamentObj.userName === manager.userInfo.name);

				if (managerDepartmentObj) {
					manager.userInfo.managerOf = managerDepartmentObj.departamentsId.filter(function (item, pos, self) {
						return self.indexOf(item) === pos;
					});
					managerDepartament.slice(managerDepartament.indexOf(managerDepartmentObj), 1);
				}
			}

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					url: `${this.$store.getters.API_URL}/users/massRegister`,
					data: {
						accounts: registerManagerDepartament.accounts
					},
					headers: {
						Authorization: `Bearer ${this.userState.token}`
					}
				};

				await axios(options);
			} catch (err) {
				console.error(err);
			}

			for (const manager of managerDepartament) {
				const user = await this.$store.dispatch("userStore/getUserByUsername", {
					username: manager.userName,
					token: this.userState.token
				});

				if (user) {
					user.managerOf.push(manager.departamentsId);
					user.managerOf = user.managerOf.filter(function (item: string, pos: number, self: string[]) {
						return self.indexOf(item) === pos;
					});

					await this.$store.dispatch("userStore/update", {
						user: user,
						token: this.userState.token
					});
				}
			}

			this.emailListObj = [];
			this.allUsers = await this.$store.dispatch("userStore/loadAllUsers");
			this.filteredUsers = this.allUsers;
			this.openUsers();

			this.loading = false;
		},
		blockDesk () {
			// this.selectedDeskGroups = this.selectedDeskGroups.concat(this.selectedDeskAddGroups);
			// this.selectedDesk.groupsIdList = this.selectedDeskGroups.map(group => group._id ? group._id : "-1");
			// this.selectedDeskAddGroups = [];
			this.selectedDesk.status = ReservationStatus.BUSY;
			this.selectedDesk.manager = this.selectedDeskManager.uuid;
			this.selectedDesk.managerName = this.selectedDeskManager.name;
			this.$forceUpdate();
		},
		removeManagerBlock () {
			this.selectedDesk.status = ReservationStatus.FREE;
			delete this.selectedDesk.manager;
			delete this.selectedDesk.managerName;
			this.$forceUpdate();
		},
		userIsTurn () {
			return (this.selectedUser.buildingList && this.selectedUser.buildingList.includes(BRDBuildings.Turn)) || this.selectedUser.building === BRDBuildings.Turn;
		},
		addToTurn () {
			this.selectedUser.buildingList.push(BRDBuildings.Turn);
		}
	},
	watch: {
		params: {
			handler (newParams: IConfig[]) {
				this.changed = true;

				// eslint-disable-next-line
				newParams = newParams.filter((param: IConfig) => param.building === BRDBuildings.Flexioffice);
			},
			deep: true
		},
		searchQuery () {
			this.filteredUsers = this.allUsers.filter(user => user.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
			this.filteredDesks = this.allDesks.filter(desk => desk.id.toLowerCase().includes(this.searchQuery.toLowerCase()));
			this.filteredGroups = this.groups.filter(group => group.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
		},
		searchQuerySecond () {
			this.selectedUsersFiltered = this.selectedUsers.filter(user => user.name.toLowerCase().includes(this.searchQuerySecond.toLowerCase()));
			this.selectedDesksFiltered = this.selectedDesks.filter(desk => desk.id.toLowerCase().includes(this.searchQuerySecond.toLowerCase()));
		},
		newGroupType () {
			this.newGroup.type = this.newGroupType ? GroupType.PROJECT : GroupType.DEPARTAMENT;
		}
	},
	computed: {
		...mapGetters({
			userState: "userStore/user",
		})
	}
});
