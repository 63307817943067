import { IReservedDesk, isReservedDesk, IReservedConferenceRoom, isReservedConferenceRoom } from "./reservations";
import { IConferenceRoom, isConferenceRoom } from "./conferenceRooms";
import { IUser, isUser } from "./users"
import { IGroup, isGroup, NO_GROUP } from './groups';
import { IDesk, isDesk } from "./desks";
import { IConfig, isConfig } from "./config";
/* eslint-disable */
export interface Selector {
	text: string;
	value: string;
}

export interface RootState {
	version:string,
	API_URL: string
}
export interface GrafData {
	dateBegin: Date;
	dateEnd: Date;
	reserved: number;
}
export function isGrafData(obj: any): obj is GrafData {
	return obj.reserved !== undefined && obj.dateBegin !== undefined && obj.dateEnd !== undefined;
}
export interface ReservationData {
	reservedOffices: number;
	emptyOffices: number;
	reservedConfRooms: number;
	emptyConfRooms: number;
}
export function isReservationData(obj: any): obj is ReservationData {
	const a = obj.reservedOffices !== undefined && obj.emptyOffices !== undefined;
	const b = obj.reservedConfRooms !== undefined && obj.emptyConfRooms !== undefined;
	return a && b;
}

export interface IUserState {
	userInfo: IUser;
	token: string;
}

export function isUserState(obj: any): obj is IUserState {
	return obj.userInfo !== undefined && obj.token !== undefined && isUser(obj.userInfo, false, false, true) ;
}

export interface IUserGroups {
	mainGroup: IGroup;
	groupList: IGroup[];
	managerOf?: IGroup[];
}
export const NO_USER_GROUPS:IUserGroups = {
	mainGroup: NO_GROUP,
	groupList: []
}
export function isUserGroups(obj: any): obj is IUserGroups {
	const isMainGroup = obj.mainGroup !== undefined && isGroup(obj.mainGroup);
	
	let isGroupList = obj.groupList !== undefined;
	for (const group of obj.groupList) {
		isGroupList = isGroupList && isGroup(group)
	}
	
	let result = isMainGroup && isGroupList;
	if (obj.managerOf) {
		let isManagerOf = obj.managerOf !== undefined;
		for (const group of obj.managerOf) {
			isManagerOf = isManagerOf && isGroup(group)
		}
		result = result && isManagerOf;
	}

	return result
}

export interface IDeskState extends IDesk, IReservedDesk {

};
export function isDeskState(obj: any): obj is IDeskState {
	return isDesk(obj) && isReservedDesk(obj);
}

export interface IConfigState extends IConfig {

};
export function isConfigState(obj: any): obj is IConfigState {
	return isConfig(obj);
}

export interface IConferenceRoomState extends IConferenceRoom {
	reservations: IReservedConferenceRoom[]
};
export function isRoomState(obj: any): obj is IConferenceRoomState {
	return isConferenceRoom(obj) && isReservedConferenceRoom(obj);
}

export interface GroupState extends IGroup {
	ceva: string
}
export function isGroupState(obj: any): obj is GroupState {
	return obj.ceva !== undefined && isGroup(obj);
}
