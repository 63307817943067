


















import { IUser } from "@common/users";
import Vue from "vue";

export default Vue.extend({
	name: "UsersList",
	props: {
		users: {
			type: Array as () => IUser[],
			required: true
		}
	},
	data () {
		const filteredUsers: IUser[] = [];

		return {
			searchQuery: "",
			filteredUsers: filteredUsers
		};
	},
	watch: {
		searchQuery (val) {
			this.filteredUsers = this.users.filter((user) => {
				return user.name.toLowerCase().includes(val.toLowerCase());
			});
		}
	}
});
