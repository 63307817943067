

















































import Vue from "vue";
import { IConfig, ConfigType, DefaultConfig } from "@common/config";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import _ from "lodash";
import moment from "moment-timezone";

export default Vue.extend({
	name: "ParamsPageAdmin",
	data () {
		const originalParams: IConfig[] = [];

		return {
			loading: false,
			originalParams: originalParams,
			loadedOriginalParams: false,
			fridayWarningDialog: false,
			everydayWarningDialog: false
		};
	},
	mounted () {
		if (!this.loadedOriginalParams && this.params.length > 0) {
			this.originalParams = _.cloneDeep(this.params);
			this.loadedOriginalParams = true;
		}
	},
	props: {
		params: {
			type: Array as () => IConfig[],
			required: true
		}
	},
	methods: {
		getAppearingNextDay (day: number) {
			if (moment.tz("Europe/Bucharest").isoWeekday(day).isAfter(moment.tz("Europe/Bucharest"))) {
				return moment.tz("Europe/Bucharest").isoWeekday(day).format("DD.MM.YYYY");
			} else {
				return moment.tz("Europe/Bucharest").add(1, "week").isoWeekday(day).format("DD.MM.YYYY");
			}
		},
		getParamName (type: ConfigType) {
			switch (type) {
				default:
					return "Nu s-a putut gasi parametrul";
				case ConfigType.FRIDAY_CUTOFF_HOUR:
					return "Ora de cut-off pentru vineri";
				case ConfigType.CONFIRMATION_DEADLINE_HOUR:
					return "Ora de cut-off pentru listele de numere de parcare";
				case ConfigType.MAX_PARKING_SPACES:
					return "Numarul maxim de locuri de parcare";
				case ConfigType.MAX_PARKING_SPACES_MONDAY:
					return "Numarul maxim de locuri de parcare pentru luni, " + this.getAppearingNextDay(1);
				case ConfigType.MAX_PARKING_SPACES_TUESDAY:
					return "Numarul maxim de locuri de parcare pentru marti, " + this.getAppearingNextDay(2);
				case ConfigType.MAX_PARKING_SPACES_WEDNESDAY:
					return "Numarul maxim de locuri de parcare pentru miercuri, " + this.getAppearingNextDay(3);
				case ConfigType.MAX_PARKING_SPACES_THURSDAY:
					return "Numarul maxim de locuri de parcare pentru joi, " + this.getAppearingNextDay(4);
				case ConfigType.MAX_PARKING_SPACES_FRIDAY:
					return "Numarul maxim de locuri de parcare pentru vineri, " + this.getAppearingNextDay(5);
				case ConfigType.CALCULATE_INDISCIPLINE_DAYS:
					return "Numarul de zile pentru calculul indisciplinei de ban";
				case ConfigType.CALCULATE_SORTING_INDISCIPLINE_DAYS:
					return "Numarul de zile pentru calculul indisciplinei de sortare";
				case ConfigType.BAN_DAYS:
					return "Numarul de zile pentru interzicerea accesului";
				case ConfigType.BAN_AT_SCORE:
					return "Numarul de indiscipline la care se interzice accesul";
				case ConfigType.CAR_CAN_BE_ADDED_AGAIN_AFTER_DAYS:
					return "Numarul de zile dupa care se poate adauga din nou o masina";
				case ConfigType.SEND_PARKING_LIST_TO_EMAIL:
					return "Email-ul la care se trimite lista de numere de parcare";
				case ConfigType.MAX_PERMANENT_ACCESS_CARS:
					return "Numarul maxim de masini cu acces permanent";
			}
		},
		async saveParams () {
			const maxAccess = this.params.find((param) => param.name === ConfigType.MAX_PERMANENT_ACCESS_CARS);
			const maxParking = this.params.find((param) => param.name === ConfigType.MAX_PARKING_SPACES);
			if (maxAccess !== undefined && maxParking !== undefined) {
				if (parseInt(maxAccess.value) > parseInt(maxParking.value)) {
					return this.$emit("showError", "Numarul maxim de masini cu acces permanent nu poate fi mai mare decat numarul maxim de locuri de parcare");
				}
			}

			let fridayCutoffHour = DefaultConfig.FRIDAY_CUTOFF_HOUR;
			let confirmationDeadlineHour = DefaultConfig.CONFIRMATION_DEADLINE_HOUR;
			let newFridayCutoffHour = DefaultConfig.FRIDAY_CUTOFF_HOUR;
			let newConfirmationDeadlineHour = DefaultConfig.CONFIRMATION_DEADLINE_HOUR;

			const fridayCutoffHourParam = this.originalParams.find((param) => param.name === ConfigType.FRIDAY_CUTOFF_HOUR);
			if (fridayCutoffHourParam !== undefined) {
				fridayCutoffHour = parseInt(fridayCutoffHourParam.value);
			}

			const confirmationDeadlineHourParam = this.originalParams.find((param) => param.name === ConfigType.CONFIRMATION_DEADLINE_HOUR);
			if (confirmationDeadlineHourParam !== undefined) {
				confirmationDeadlineHour = parseInt(confirmationDeadlineHourParam.value);
			}

			const newFridayCutoffHourParam = this.params.find((param) => param.name === ConfigType.FRIDAY_CUTOFF_HOUR);
			if (newFridayCutoffHourParam !== undefined) {
				newFridayCutoffHour = parseInt(newFridayCutoffHourParam.value);
			}

			const newConfirmationDeadlineHourParam = this.params.find((param) => param.name === ConfigType.CONFIRMATION_DEADLINE_HOUR);
			if (newConfirmationDeadlineHourParam !== undefined) {
				newConfirmationDeadlineHour = parseInt(newConfirmationDeadlineHourParam.value);
			}

			if (newFridayCutoffHour < 0 || newFridayCutoffHour > 23) {
				this.$emit("showError", "Ora de cut-off pentru vineri trebuie sa fie intre 0 si 23");
				return;
			}

			if (newConfirmationDeadlineHour < 0 || newConfirmationDeadlineHour > 23) {
				this.$emit("showError", "Ora de cut-off pentru listele de numere de parcare trebuie sa fie intre 0 si 23");
				return;
			}

			if (newFridayCutoffHour !== fridayCutoffHour) {
				if (moment().isAfter(moment().day(5).hour(newFridayCutoffHour).minute(0).second(0)) && moment().isBefore(moment().day(5).hour(fridayCutoffHour).minute(0).second(0))) {
					this.fridayWarningDialog = true;
					return;
				}
			}

			if (newConfirmationDeadlineHour !== confirmationDeadlineHour) {
				if (moment().isAfter(moment().hour(newConfirmationDeadlineHour).minute(0).second(0)) && moment().isBefore(moment().hour(confirmationDeadlineHour).minute(0).second(0))) {
					this.everydayWarningDialog = true;
					return;
				}
			}

			await this.realSaveParams();
		},
		async realSaveParams () {
			this.loading = true;

			try {
				for (let index = 0; index < this.params.length; index++) {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${this.$store.getters.API_URL}/config/update`,
						data: this.params[index],
						headers: {
							Authorization: `Bearer ${this.$store.getters["userStore/token"]}`
						}
					};
					const response: AxiosResponse<IServerRES<IConfig>> = await axios(options);
					this.loading = false;

					if (response.data.err === ServerError.NO_ERROR) {
						this.params[index] = response.data.payload;
						this.$emit("showError", "Parametrii actualizați cu succes!", "success");
					} else {
						this.$emit("showError", "Eroare la actualizarea parametrilor!");
					}
				}
			} catch (error) {
				console.error(error);
			}

			this.originalParams = _.cloneDeep(this.params);
		}
	},
	watch: {
		params: {
			handler (newVal: IConfig[]) {
				if (!this.loadedOriginalParams && newVal.length > 0) {
					this.originalParams = _.cloneDeep(newVal);
					this.loadedOriginalParams = true;
				}
			},
			deep: true
		}
	}
});
