























































import { Vue } from "vue-property-decorator";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
export default Vue.extend({
	name: "ResetPassword",
	mounted: async function () {
		return null;
	},
	data () {
		return {
			loading: false,
			email: "",
			error: false,
			errorMessage: ""
		};
	},
	methods: {
		async resetPassword () {
			this.loading = true;

			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/users/sendResetEmail`,
				data: {
					email: this.email
				}
			};

			const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

			if (response.data.err === 0) {
				this.$router.push({ path: "/login", query: { toreset: "true" } });
			} else {
				this.loading = false;
				this.error = true;
				this.errorMessage = "S-a produs o eroare!";
			}
		}
	}
});
