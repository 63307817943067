



































import Vue from "vue";

export default Vue.extend({
	name: "DragDropUpload",
	data () {
		return {
			dragover: false,
			uploaded: false,
			error: "",
		};
	},
	methods: {
		onDrop (e: DragEvent) {
			this.dragover = false;

			if (e.dataTransfer) {
				if (e.dataTransfer.files.length > 1) {
					this.error = "Nu poti uploada mai mult de un fisier";
				} else {
					this.handleFile(e.dataTransfer.files[0]);
				}
			}
		},
		handleFile (file: File) {
			this.uploaded = true;

			const reader = new FileReader();

			reader.onload = (e: ProgressEvent) => {
				const file = (e.target as FileReader).result;

				if (file) {
					this.$emit("upload", file);
				} else {
					this.uploaded = false;
				}
			};

			reader.readAsBinaryString(file);
		},
		changeInput (e: Event) {
			const files = (e.target as HTMLInputElement).files;

			if (files !== undefined && files !== null && files.length > 0) {
				this.handleFile(files[0]);
			}
		},
		clickInput () {
			const input = document.querySelector("input") as HTMLInputElement;

			input.click();
		}
	}
});
