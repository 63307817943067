


















































































































































import { Vue } from "vue-property-decorator";
import Reservations from "@/components/flexioffice/Reservations.vue";
import { mapGetters } from "vuex";
export default Vue.extend({
	name: "Admin",
	components: {
		Reservations
	},
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	created () {
		for (const i in this.rezervari) {
			this.rezervari[i].data = this.getData(this.rezervari[i].data);
		}
	},
	data () {
		return {
			statistics: [
				{
					text: "Lunar",
					value: "monthly"
				},
				{
					text: "Saptamanal",
					value: "weekly"
				},
				{
					text: "Zilnic",
					value: "daily"
				},
			],
			stat: "monthly",
			manag: false,
			depp: "Departament 1",
			dept: "Departament 1",
			depts: [] as string[],
			proj: "",
			items: [
				"Departament 1",
			],
			item1: "Proiect 1",
			items1: [
				"Proiect 1",
				"Proiect 2",
				"Proiect 3"
			],
			items2: [
				"Departament 1",
				"Proiect 1",
				"Proiect 2",
				"Proiect 3"
			],
			month: "Ianuarie",
			week: "Saptamana 1",
			weeks: [
				"Saptamana 1",
				"Saptamana 2",
				"Saptamana 3",
				"Saptamana 4"
			],
			months: [
				"Ianuarie",
				"Februarie",
				"Martie",
				"Aprilie",
				"Mai",
				"Iunie",
				"Iulie",
				"August",
				"Septembrie",
				"Octombrie",
				"Noiembrie",
				"Decembrie"
			],
			headers: [
				{
					text: "Etajele cladirii",
					align: "start",
					sortable: false,
					value: "name",
				},
				{ text: "Nr. total de rezervari", value: "reservations" },
				{ text: "Rezervarile angajatilor", value: "reservationsUser" },
				{ text: "Rezervarile managerilor", value: "reservationsManager" },
				{ text: "Rezervarile administratorilor", value: "reservationsAdmin" },
				{ text: "Rezervarile tuturor birourilor", value: "reservationsOffice" },
				{ text: "Rezervarile tuturor salilor de sedinte", value: "reservationsConference" },
			],
			headers1: [
				{
					text: "Data",
					align: "start",
					sortable: false,
					value: "data",
				},
				{ text: "Nume", value: "nume" },
				{ text: "Prenume", value: "prenume" },
			],
			rezervari: [
				{
					data: "2021-04-01T13:51:50.417-07:00",
					nume: "Doe",
					prenume: "Jon"
				},
				{
					data: "2021-04-02T13:51:50.417-07:00",
					nume: "Doe",
					prenume: "Jan"
				},
				{
					data: "2021-04-05T13:51:50.417-07:00",
					nume: "Ionescu",
					prenume: "Ion"
				},
				{
					data: "2021-04-09T13:51:50.417-07:00",
					nume: "Ionescu",
					prenume: "Maria"
				},
			],
			etaje: [
				{
					name: "Etaj 1",
					reservations: 500,
					reservationsUser: 400,
					reservationsManager: 75,
					reservationsAdmin: 25,
					reservationsOffice: 320,
					reservationsConference: 180,
				},
				{
					name: "Etaj 2",
					reservations: 700,
					reservationsUser: 600,
					reservationsManager: 20,
					reservationsAdmin: 80,
					reservationsOffice: 450,
					reservationsConference: 250,
				}
			]
		};
	},
	methods: {
		getData (date:string) {
			const d = new Date(date);
			const data = (d.getDay() < 10 ? ("0" + d.getDay()) : d.getDay()) + "." + (d.getMonth() < 10 ? ("0" + d.getMonth()) : d.getMonth()) + "." + d.getFullYear();
			return data;
		},
		addToList () {
			this.depts.push(this.depp);
			this.items.splice(this.items.indexOf(this.depp), 1);
			this.depp = "";
		},
		removeFromList (deptt:string) {
			this.depts = this.depts.filter((value) => {
				return value !== deptt;
			});
			this.items.push(deptt);
			this.items.sort();
		},
	},
});
