





























































































































import { IUser } from "@common/users";
import { IUserState, IDeskState } from "@common/ui";
import { IReservedDesk, ReservationStatus } from "@common/reservations";
import Vue from "vue";
import moment from "moment";
import { IGroup } from "@common/groups";

export default Vue.extend({
	name: "Desk",
	props: {
		userState: {
			type: Object as () => IUserState
		},
		deskItem: {
			type: Object as () => IDeskState
		},
		selectedGroup: {
			type: String,
			default: () => "-1"
		},
		date: {
			type: String
		},
		reservationDates: {
			type: Array
		},
		selectedGroupData: {
			type: Object as () => IGroup
		},
		maxDays: {
			type: Number,
			default: () => 0
		}
	},
	async mounted () {
		await this.fetchData();
	},
	data () {
		const users: IUser[] = [];
		const auxDefaultDate = new Date();
		const weekDay = moment().isoWeekday();
		const plus = (weekDay === 6 || weekDay === 7) ? 8 - weekDay : 0;
		const auxDate = new Date();
		const day = auxDefaultDate.getDate();
		auxDefaultDate.setDate(day + plus);
		const defaultDate = auxDefaultDate.toISOString().substr(0, 10);
		const month = auxDate.getMonth();
		auxDate.setMonth(month + 1);
		const maxDate = auxDate.toISOString().substr(0, 10);
		const nrParking = this.selectedGroupData.parkingSpots;
		const reservedDesks: IReservedDesk[] = [];
		return {
			minDate: defaultDate,
			maxDate: maxDate,
			users: users,
			forUserId: "-1",
			forUserBool: false,
			dateMenu: false,
			actualDate: defaultDate,
			ReservedStatus: ReservationStatus,
			parking: false,
			nrParking: nrParking,
			validSelf: true,
			reservedDesks: reservedDesks,
			snackMessage: "",
			snackColor: "",
			reserving: false,
			validForUser: true,
			loading: false,
			loadingForUser: false,
		};
	},
	watch: {
		forUserId: {
			immediate: true,
			async handler (newUserId: string) {
				this.loadingForUser = true;
				this.$forceUpdate();
				if (newUserId !== "-1") {
					this.forUserBool = true;
				} else {
					this.forUserBool = false;
				}

				const userReserved = await this.$store.dispatch("deskStore/reservations", {
					token: this.userState.token,
					uuid: newUserId
				});

				let aux:Date | string = new Date(this.date);
				aux.setHours(11, 0, 0, 0);
				aux = aux.toISOString();
				const existing = userReserved.find((r: IDeskState) => {
					return r.reservationDate.toString() === aux.toString();
				});
				if (!existing) {
					this.validForUser = true;
				} else {
					this.validForUser = false;
				}
				this.loadingForUser = false;
			}
		},
		deskItem: {
			immediate: true,
			handler () {
				this.$forceUpdate();
			}
		},
		date: {
			immediate: true,
			async handler (newDate: string) {
				this.actualDate = newDate;

				if (moment(this.actualDate).isAfter(moment().add(this.maxDays, "days"))) {
					this.actualDate = moment().add(this.maxDays, "days").toISOString().substr(0, 10);

					if (moment(this.actualDate).isoWeekday() === 6) {
						this.actualDate = moment().add(this.maxDays - 1, "days").toISOString().substr(0, 10);
					} else if (moment(this.actualDate).isoWeekday() === 7) {
						this.actualDate = moment().add(this.maxDays - 2, "days").toISOString().substr(0, 10);
					}
				}

				await this.fetchParking();
				await this.checkSelfValidity();
				this.$forceUpdate();
			}
		}
	},
	computed: {
		dateFormatted (): string {
			return moment(this.actualDate).format("DD.MM.YYYY");
		},
		groupName (): string {
			return this.selectedGroupData.name;
		},
		getFloor (): string {
			return this.deskItem.deskId.substring(1, 2);
		},
		isNormalDesk (): boolean {
			return !this.deskItem.manager;
		}
	},
	methods: {
		allowedDates (val:string) {
			return new Date(val).getDay() !== 0 && new Date(val).getDay() !== 6;
		},
		async checkSelfValidity () {
			let aux:Date | string = new Date(this.date);
			aux.setHours(11, 0, 0, 0);
			aux = aux.toISOString();
			const existing = this.reservedDesks.find((r) => {
				return r.reservationDate.toString() === aux.toString();
			});
			if (!existing) {
				this.validSelf = true;
			} else {
				this.validSelf = false;
			}
		},
		async fetchData () {
			this.loading = true;
			try {
				if (this.selectedGroup && this.selectedGroup !== "-1") {
					let aux:Date | string = new Date(this.date);
					aux.setHours(11, 0, 0, 0);
					aux = aux.toISOString();
					await this.$store.dispatch("deskStore/load", {
						token: this.userState.token,
						groupId: this.selectedGroup,
						date: aux
					});
					this.reservedDesks = await this.$store.dispatch("deskStore/reservations", {
						token: this.userState.token,
						uuid: this.userState.userInfo.uuid
					});
					if (this.userState.userInfo.role === "manager" || this.userState.userInfo.role === "admin") {
						let users: IUser[] = [];

						// eslint-disable-next-line
						for (const group of this.userState.userInfo.managerOf!) {
							const gotUsers = await this.$store.dispatch("userStore/loadUsers", {
								groupId: group
							});
							users = users.concat(gotUsers);
						}

						if (users.length > 0) {
							this.users = users.filter((u) => {
								return u.groupList.includes(this.selectedGroup);
							});

							this.users = this.users.filter((u) => {
								return u.uuid !== this.userState.userInfo.uuid;
							});
						}
					}

					this.fetchParking();
					this.checkSelfValidity();
				}

				this.maxDate = moment().add(this.maxDays, "day").toISOString().substr(0, 10);
				this.loading = false;
				await this.$forceUpdate();
			} catch (error) {
				console.error("fetchData Error");
				console.error(error);
			}
		},
		async fetchParking () {
			this.nrParking = await this.$store.dispatch("deskStore/getAvailableParking", {
				groupId: this.selectedGroup,
				date: this.actualDate
			});
		},
		async reserveDesk (creator?:boolean, uuid?:string) {
			this.reserving = true;
			let forUserName = "";

			if (this.forUserBool) {
				const findUser = this.users.find((u) => {
					return u.uuid === this.forUserId;
				});

				if (findUser) {
					forUserName = findUser.name;
				}
			}

			try {
				if (creator !== undefined && creator) {
					this.deskItem.creatorUuid = this.userState.userInfo.uuid;
					if (uuid) {
						this.deskItem.uuid = uuid;
					}
				} else {
					this.deskItem.uuid = this.userState.userInfo.uuid;
					this.deskItem.creatorUuid = this.userState.userInfo.uuid;
				}
				this.deskItem.parkingSpot = this.parking;
				this.deskItem.status = ReservationStatus.BOOKED;
				const auxDate = new Date(this.actualDate);
				auxDate.setHours(11, 0, 0, 0);
				this.deskItem.reservationDate = auxDate;
				const resp = await this.$store.dispatch("deskStore/reserve", {
					deskEntry: this.deskItem,
					groupId: this.selectedGroup,
					userName: (creator !== undefined && creator) ? forUserName : this.userState.userInfo.name,
				});

				if (resp) {
					let aux:Date | string = new Date(this.actualDate);
					aux.setHours(11, 0, 0, 0);
					aux = aux.toISOString();
					await this.$store.dispatch("deskStore/load", {
						token: this.userState.token,
						groupId: this.selectedGroup,
						date: aux
					});

					this.snackColor = "success";
					this.snackMessage = "Biroul a fost rezervat cu succes";
				} else {
					console.error("Didn't update");

					this.snackColor = "error";
					this.snackMessage = "Biroul nu a putut fi rezervat";
				}
				await this.$forceUpdate();
				this.parking = false;
			} catch (error) {
				console.error(error);
			}

			this.createSnack();
			this.close();
		},
		createSnack () {
			this.$emit("createSnack", { message: this.snackMessage, color: this.snackColor });
		},
		close () {
			this.$emit("close-desk-dialog");
		},
		updateDate () {
			this.$emit("updateDate", this.actualDate);
		},
		isNormalReservedDesk () {
			return this.deskItem.status !== this.ReservedStatus.FREE && !this.reserving;
		}
	}
});
