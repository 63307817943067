var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding":"0"}},[_c('v-snackbar',{attrs:{"color":_vm.snackboxColor,"shaped":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","color":"black"},on:{"click":function($event){_vm.snackbox = false}}},'v-btn',attrs,false),[_vm._v(" Închide ")])]}}]),model:{value:(_vm.snackbox),callback:function ($$v) {_vm.snackbox=$$v},expression:"snackbox"}},[_vm._v(" "+_vm._s(_vm.snackboxMessage)+" ")]),_c('v-row',{staticStyle:{"margin":"0"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-spacer'),(_vm.selectedProject !== _vm.defaultGroup)?_c('v-btn',{staticClass:"mr-2",staticStyle:{"margin-bottom":"1em"},on:{"click":function($event){_vm.selectedProject = _vm.defaultGroup}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Înapoi ")],1):_vm._e(),_c('v-btn',{staticStyle:{"margin-bottom":"1em"},attrs:{"to":"/flexioffice/admin/manager"}},[_vm._v("Statistici")])],1),(_vm.selectedProject === _vm.defaultGroup)?_c('v-card',[_c('div',{staticClass:"d-flex align-center"},[_c('v-card-title',[_vm._v("Proiectele mele")])],1),_c('v-card-text',[(_vm.isProiecteEmpty() === true)?_c('span',[_vm._v(" "+_vm._s(_vm.emptyMessage)+" ")]):_vm._e(),_c('v-virtual-scroll',{attrs:{"width":"100%","height":"700","items":_vm.proiecte,"item-height":90},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"ma-2 mr-4",on:{"click":function($event){return _vm.selectProject(item)}}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(item.name))])])],1)]}}],null,false,1191674603)})],1)],1):_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.selectedProject.name))]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_vm._v("Birouri in proiect:")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-virtual-scroll',{attrs:{"height":"600","items":_vm.filteredSelectedProjectDesks,"item-height":50},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(_vm._s(item.id))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{on:{"click":function($event){return _vm.removeDeskGroup(item)}}},[_vm._v("mdi-close-box")])],1)],1)]}}])})],1)],1),_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_vm._v("Birouri disponibile in departamente:")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.searchQuerySecond),callback:function ($$v) {_vm.searchQuerySecond=$$v},expression:"searchQuerySecond"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-virtual-scroll',{attrs:{"height":"600","items":_vm.filteredAvalabileDesks,"item-height":50},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(_vm._s(item.id))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{on:{"click":function($event){return _vm.addDeskGroup(item)}}},[_vm._v("mdi-plus-circle")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }