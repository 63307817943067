import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AdminPage from "../views/Flexioffice/AdminPage.vue";
import Admin from "../views/Flexioffice/Admin.vue";
import CSV from "../views/Flexioffice/CSV.vue";
import Manager from "../views/Flexioffice/Manager.vue";
import Login from "../views/Flexioffice/Login.vue";

import Confirmation from "../views/Flexioffice/Confirmation.vue";
import Cancelation from "../views/Flexioffice/Cancelation.vue";
import NotFound from "../views/Flexioffice/NotFound.vue";
import Statistics from "../views/Flexioffice/Statistics.vue";
import AdminSettings from "../views/Flexioffice/AdminSettings.vue";
import Password from "../views/Flexioffice/Password.vue";
import ResetPassword from "../views/Flexioffice/ResetPassword.vue";
import ManagerDepartments from "../views/Flexioffice/ManagerDepartments.vue";
import ForgotPassword from "../views/Flexioffice/ForgotPassword.vue";
import Flexioffice from "../views/Flexioffice/Flexioffice.vue";
import AddReservation from "../views/Turn/AddReservation.vue";
import Account from "../views/Turn/Account.vue";
import Reservations from "../views/Turn/Reservations.vue";
import CancelationTurn from "../views/Turn/Cancelation.vue";
import AdminTurn from "../views/Turn/Admin.vue";
import StatisticsDays from "../views/Turn/StatisticsDays.vue";
import StatisticsUsers from "@/views/Turn/StatisticsUsers.vue";
import Dropdown from "@/views/Dropdown.vue";

Vue.use(VueRouter);
const BASE = "/";
const routes: Array<RouteConfig> = [
	{
		path: "/login",
		name: "Login",
		component: Login
	},
	{
		path: "/dropdown",
		name: "Dropdown",
		component: Dropdown
	},
	{
		path: "/flexioffice",
		name: "Flexioffice",
		component: Flexioffice
	},
	{
		path: "/reset-password/:token",
		name: "ResetPassword",
		component: ResetPassword
	},
	{
		path: "/reset-password",
		name: "ForgotPassword",
		component: ForgotPassword
	},
	{
		path: "/confirmation/:reservationId",
		name: "Confirmation",
		component: Confirmation
	},
	{
		path: "/cancelation/:reservationId",
		name: "Cancelation",
		component: Cancelation
	},
	{
		path: "/flexioffice/statistics",
		name: "Statistics",
		component: Statistics
	},
	{
		path: "/flexioffice/admin",
		name: "AdminPage",
		component: AdminPage
	},
	{
		path: "/flexioffice/admin/admin",
		name: "Admin",
		component: Admin
	},
	{
		path: "/flexioffice/admin/csv",
		name: "CSV",
		component: CSV
	},
	{
		path: "/flexioffice/admin/manager",
		name: "Manager",
		component: Manager
	},
	{
		path: "/flexioffice/admin/manager/departments",
		name: "ManagerDepartments",
		component: ManagerDepartments
	},
	{
		path: "/flexioffice/admin/settings",
		name: "Settings",
		component: AdminSettings
	},
	{
		path: "/password",
		name: "Password",
		component: Password
	},
	{
		path: "/turn/add",
		name: "AddReservation",
		component: AddReservation
	},
	{
		path: "/turn/account",
		name: "Account",
		component: Account
	},
	{
		path: "/turn/reservations",
		name: "Reservations",
		component: Reservations
	},
	{
		path: "/turn/cancelation/:reservationId",
		name: "CancelationTurn",
		component: CancelationTurn
	},
	{
		path: "/turn/admin",
		name: "AdminTurn",
		component: AdminTurn
	},
	{
		path: "/turn/statisticsDay",
		name: "StatisticsDay",
		component: StatisticsDays
	},
	{
		path: "/turn/statisticsUser",
		name: "StatisticsUser",
		component: StatisticsUsers
	},
	{
		path: "*",
		name: "Not Found",
		component: NotFound
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL || BASE,
	routes
});

export default router;
