import { BRDBuildings } from "./users";

export enum ConfigType {
    MAX_DAYS_ROOM = "maxDaysRoom",
    MAX_DAYS_DESK = "maxDaysDesk",
    MAX_ACTIVE_ROOM = "maxActiveRoom",
    MAX_ACTIVE_DESK = "maxActiveDesk",
    FRIDAY_CUTOFF_HOUR = "fridayCutoffHour",
    CONFIRMATION_DEADLINE_HOUR = "confirmationDeadlineHour",
    MAX_PARKING_SPACES = "maxParkingSpaces",
    MAX_PARKING_SPACES_MONDAY = "maxParkingSpacesMonday",
    MAX_PARKING_SPACES_TUESDAY = "maxParkingSpacesTuesday",
    MAX_PARKING_SPACES_WEDNESDAY = "maxParkingSpacesWednesday",
    MAX_PARKING_SPACES_THURSDAY = "maxParkingSpacesThursday",
    MAX_PARKING_SPACES_FRIDAY = "maxParkingSpacesFriday",
    CALCULATE_INDISCIPLINE_DAYS = "calculateIndisciplineDays",
    CALCULATE_SORTING_INDISCIPLINE_DAYS = "calculateSortingIndisciplineDays",
    BAN_DAYS = "banDays",
    BAN_AT_SCORE = "banAtScore",
    CAR_CAN_BE_ADDED_AGAIN_AFTER_DAYS = "carCanBeAddedAgainAfterDays",
    SEND_PARKING_LIST_TO_EMAIL = "sendParkingListToEmail",
    MAX_PERMANENT_ACCESS_CARS = "maxPermanentAccesCars",
};

export enum DefaultConfig {
    FRIDAY_CUTOFF_HOUR = 16,
    CONFIRMATION_DEADLINE_HOUR = 18,
    MAX_PARKING_SPACES = 100,
    BAN_DAYS = 14,
    BAN_AT_SCORE = 3,
    CALCULATE_INDISCIPLINE_DAYS = 30,
    CALCULATE_SORTING_INDISCIPLINE_DAYS = 60,
    CAR_CAN_BE_ADDED_AGAIN_AFTER_DAYS = 60,
    MAX_PERMANENT_ACCESS_CARS = 0,
};

export enum DefaultConfigString {
    SEND_PARKING_LIST_TO_EMAIL = "andrei.serban@wyliodrin.com"
};

export interface IConfig {
    _id?: string;
    name: ConfigType;
    value: string;
    building: BRDBuildings;
};

export function isConfig(obj: any, withId:boolean = false): boolean {
	const isName = obj.name !== undefined && obj.name !== null;
    const isValue = obj.value !== undefined && obj.value !== null;
    const isBuilding = obj.building !== undefined && obj.building !== null;

    let result = isName && isValue && isBuilding;
    if (withId) {
        const isId = obj._id !== undefined && obj._id !== null;
        result = result && isId;
    }

    return result;
};

export const NO_CONFIG:IConfig = {
	_id: "-1",
	name: ConfigType.MAX_ACTIVE_DESK,
	value: "-1",
    building: BRDBuildings.None
};
