





























import { ICar, NO_CAR } from "@common/cars";
import { IUser, NO_USER } from "@common/users";
import _ from "lodash";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "Car",
	props: {
		car: {
			type: Object as () => ICar,
			required: true
		},
		users: {
			type: Array as () => IUser[],
			required: true
		}
	},
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	data () {
		const selectedCar: ICar = NO_CAR;
		const carsUser: IUser = NO_USER;

		return {
			selectedCar,
			carsUser,
			loading: false,
			oldFunction: false,
			oldPermanent: false
		};
	},
	mounted () {
		this.selectedCar = _.cloneDeep(this.car);
		const findUser = this.users.find((user) => user.uuid === this.car.user_id);

		if (findUser) {
			this.carsUser = findUser;
		} else {
			this.carsUser = {
				...NO_USER,
				name: "BRD Turn"
			};
		}

		this.$forceUpdate();
	},
	methods: {
		async saveCar () {
			this.loading = true;

			const resp = await this.$store.dispatch("carsStore/update", { car: this.selectedCar });

			if (resp) {
				this.$emit("fetchData", false);
				this.$emit("showError", "Masina a fost salvată cu succes", "success");
			} else {
				this.$emit("showError", "Eroare la salvare");
			}

			this.loading = false;
		},
		async deleteCar () {
			this.loading = true;

			const resp = await this.$store.dispatch("carsStore/delete", { car: this.selectedCar });

			if (resp) {
				this.$emit("fetchData", false);
				this.$emit("showError", "Masina a fost ștearsă cu succes", "success");
				this.$emit("changePage", "carsList");
			} else {
				this.$emit("showError", "Eroare la ștergere");
			}

			this.loading = false;
		}
	},
	watch: {
		"selectedCar.permanent_access": {
			immediate: true,
			handler () {
				if (this.selectedCar.function_car && this.selectedCar.permanent_access) {
					this.oldFunction = this.selectedCar.function_car;
					this.selectedCar.function_car = false;
				} else if (this.oldFunction && !this.selectedCar.permanent_access) {
					this.selectedCar.function_car = this.oldFunction;
					this.oldFunction = false;
				}
			}
		},
		"selectedCar.function_car": {
			immediate: true,
			handler () {
				if (this.selectedCar.permanent_access && this.selectedCar.function_car) {
					this.oldPermanent = this.selectedCar.permanent_access;
					this.selectedCar.permanent_access = false;
				} else if (this.oldPermanent && !this.selectedCar.function_car) {
					this.selectedCar.permanent_access = this.oldPermanent;
					this.oldPermanent = false;
				}
			}
		}
	}
});
