






































































































































































































































































































































import { Vue } from "vue-property-decorator";
import { isUserState } from "@common/ui";
import { mapGetters } from "vuex";
import { BRDBuildings } from "@common/users";

export default Vue.extend({
	name: "App",
	data () {
		// Dialogs
		const isAdmin = false;
		const isConfirmed = true;
		const turnLink = this.$route.path.includes("turn") ? this.$route.path.split("/")[2] : "add";
		return {
			isAdmin: isAdmin,
			isConfirmed: isConfirmed,
			isConfirmedDialog: false,
			imgStyle: "",
			admin: false,
			username: "",
			snackbox: false,
			snackboxMessage: "",
			snackboxColor: "",
			showHelpDialog: false,
			loading: true,
			turnLink: turnLink,
			showBar: "none",
			openAdminHamburgher: false,
			openAdminDialog: false
		};
	},
	computed: {
		...mapGetters({
			userState: "userStore/user",
		})
	},
	mounted: async function () {
		if (isUserState(this.userState) && this.userState.token !== "") {
			const validToken:boolean = await this.$store.dispatch("userStore/verifyToken", { token: this.userState.token });
			if (this.userState.userInfo.role === "admin") {
				this.isAdmin = true;
			} else {
				this.isAdmin = false;
			}

			if (this.userState.userInfo.confirmed === false) {
				this.isConfirmed = false;
				this.isConfirmedDialog = true;
			} else {
				this.isConfirmed = true;
			}

			if (validToken) {
				if (this.$route.path === "/login") {
					this.$router.push("/");
				}

				if (this.$route.path === "/" || window.location.href.substring(window.location.href.length - 1) === "/") {
					this.$router.push("/dropdown");
				} else if (this.$route.path.includes("/flexioffice") && this.userState.userInfo.building !== BRDBuildings.Flexioffice && this.userState.userInfo.role !== "admin" && !this.userState.userInfo.buildingList.includes(BRDBuildings.Flexioffice)) {
					this.$router.push("/turn/add");
				} else if (this.$route.path.includes("/turn/add") && this.userState.userInfo.building !== BRDBuildings.Turn && this.userState.userInfo.role !== "admin" && !this.userState.userInfo.buildingList.includes(BRDBuildings.Turn)) {
					this.$router.push("/flexioffice");
				}
			} else {
				if (this.$route.path && (this.$route.path.includes("/cancelation") || this.$route.path.includes("/confirmation") || this.$route.path.includes("/reset-password"))) {
					// TODO prompt
				} else if (window.location.href.includes("/cancelation") || window.location.href.includes("/confirmation") || window.location.href.includes("/reset-password")) {
					// TODO PROMPT
				} else {
					this.$router.push("/login");
				}
			}
		} else {
			if (this.$route.path && (this.$route.path.includes("/cancelation") || this.$route.path.includes("/confirmation") || this.$route.path.includes("/reset-password"))) {
				// TODO prompt
			} else if (window.location.href.includes("/cancelation") || window.location.href.includes("/confirmation") || window.location.href.includes("/reset-password")) {
				// TODO PROMPT
			} else {
				this.$router.push("/login");
			}
		}

		if (this.$route.path.includes("/flexioffice") && !this.$route.path.includes("/cancelation")) {
			this.showBar = "flexioffice";
		} else if (this.$route.path.includes("/turn") && !this.$route.path.includes("/cancelation")) {
			this.showBar = "turn";
		} else {
			this.showBar = "none";
		}
	},
	watch: {
		async $route () {
			if (this.userState.userInfo.role === "admin") {
				this.isAdmin = true;
			} else {
				this.isAdmin = false;
			}

			if (this.userState.userInfo.confirmed === false) {
				this.isConfirmed = false;
				this.isConfirmedDialog = true;
			} else {
				this.isConfirmed = true;
			}

			if (this.userState.userInfo.uuid === "-1") {
				if (!(window.location.href.includes("/cancelation") || window.location.href.includes("/confirmation") || window.location.href.includes("/reset-password") || window.location.href.includes("/login"))) {
					this.$router.push("/login");
				}
			} else if (this.$route.path === "/") {
				this.$router.push("/dropdown");
			} else if (this.$route.path.includes("/flexioffice") && this.userState.userInfo.building !== BRDBuildings.Flexioffice && this.userState.userInfo.role !== "admin" && !this.userState.userInfo.buildingList.includes(BRDBuildings.Flexioffice)) {
				this.$router.push("/turn/add");
			} else if (this.$route.path.includes("/turn/add") && this.userState.userInfo.building !== BRDBuildings.Turn && this.userState.userInfo.role !== "admin" && !this.userState.userInfo.buildingList.includes(BRDBuildings.Turn)) {
				this.$router.push("/flexioffice");
			}

			if (this.$route.path.includes("/flexioffice") && !this.$route.path.includes("/cancelation")) {
				this.showBar = "flexioffice";
			} else if (this.$route.path.includes("/turn") && !this.$route.path.includes("/cancelation")) {
				this.showBar = "turn";
			} else {
				this.showBar = "none";
			}
		},
	},
	methods: {
		createSnack (options:{message:string, color:string}) {
			this.snackbox = true;
			this.snackboxMessage = options.message;
			this.snackboxColor = options.color;
		},
		checkPath () {
			if (this.$route.path !== "/") {
				// console.log("PAth is not /");
				return false;
			} else {
				// console.log("Path is /");
				return true;
			}
		},
		checkNotApp () {
			if (this.$route.path === "/flexioffice" || this.$route.path.includes("/turn")) {
				return false;
			} else {
				return true;
			}
		},
		checkLogin () {
			if (!this.$route.path.includes("/login")) {
				return true;
			} else {
				return false;
			}
		},
		checkReset () {
			if (this.$route.path.includes("reset-password")) {
				return false;
			} else {
				return true;
			}
		},
		checkCancel () {
			if (this.$route.path.includes("cancelation")) {
				return false;
			} else {
				return true;
			}
		},
		async logout () {
			try {
				const res = await this.$store.dispatch("userStore/logout", {
					token: this.userState.token
				});
				if (res) {
					// TODO dispatch notification
					this.$router.push("/login");
				} else {
					console.error("ERROR on logout");
					// TODO error
				}
			} catch (error) {
				console.error(error);
			}
		},
		changeImgStyle (type:string) {
			try {
				if (type === "enter") {
					this.imgStyle = "";
				} else if (type === "leave") {

				}
			} catch (error) {
				console.error(error);
			}
		},
		getUsername (): string {
			this.username = this.userState.userInfo.name;
			return this.username;
		},
		showHelp () {
			this.showHelpDialog = true;
		},
		closeHelp () {
			this.showHelpDialog = false;
		},
		stopLoading () {
			this.loading = false;
		},
		isMobile () {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true;
			} else {
				return false;
			}
		},
		userHasOtherBuilding () {
			if (this.userState.userInfo.building === BRDBuildings.Flexioffice) {
				return this.userState.userInfo.buildingList.includes(BRDBuildings.Turn);
			} else if (this.userState.userInfo.building === BRDBuildings.Turn) {
				return this.userState.userInfo.buildingList.includes(BRDBuildings.Flexioffice);
			}
		},
		getVersion () {
			return this.$store.getters.version;
		}
	},
});
