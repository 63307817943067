



































import { IBRDCredentials } from "@common/brdCredentials";
import { BRDBuildings, IUser, NO_USER } from "@common/users";
import Vue from "vue";

export default Vue.extend({
	name: "AddNewUserTurn",
	data () {
		return {
			name: "",
			email: "",
			phone: "",
			grading: false,
			username: ""
		};
	},
	methods: {
		addUser () {
			if (this.name === "" || this.email === "" || this.phone === "") {
				return this.$emit("showError", "Toate câmpurile sunt obligatorii!");
			}

			const user: IUser = {
				uuid: NO_USER.uuid,
				name: this.name,
				email: this.email,
				building: BRDBuildings.Turn,
				buildingList: [BRDBuildings.Turn],
				phoneNumber: this.phone,
				graded: this.grading,
				confirmed: false,
				parkingRight: true,
				manager: "-1",
				director: "-1",
				mainGroup: "-1",
				groupList: []
			};

			const credentials: IBRDCredentials = {
				username: this.username,
				password: "123456"
			};

			this.$emit("addNewUser", user, credentials);
		}
	}
});
