import Vue from "vue";
import Vuex, { Module } from "vuex";
import { IDeskState, isDeskState, RootState } from "@common/ui";
import { IDesk, isDesk } from "@common/desks";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IReservedDesk, isReservedDesk, NO_RESERVED_DESK, ReservationStatus } from "@common/reservations";

Vue.use(Vuex);
interface State {
	desks:IDeskState[]
}
const desks:IDeskState[] = [];

export default function deskStore ():Module<State, RootState> {
	const store: Module<State, RootState> = {
		namespaced: true,
		state: {
			desks: desks
		},
		getters: {
			desks: state => state.desks
		},
		mutations: {
			desks: (state, newDesks: IDeskState[]) => {
				state.desks = newDesks;
			}
		},
		actions: {
			async load (storeParam, { groupId, date }:{ groupId:string, date:Date}): Promise<boolean> {
				try {
					let desks:IDesk[] = [];
					let reservations:IReservedDesk[] = [];
					const state:IDeskState[] = [];
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/desks/group/${groupId}`,
					};
					const responseDesks: AxiosResponse<IServerRES<IDesk[]>> = await axios(options);
					if (responseDesks.data.err === ServerError.NO_ERROR) {
						desks = responseDesks.data.payload;
					} else {
						console.error("No desks!");
						return false;
					}
					options.method = "POST";
					options.url = `${storeParam.rootState.API_URL}/reservations/desks/group`;
					options.data = {
						groupId: groupId,
						date: date
					};
					const responseReservations: AxiosResponse<IServerRES<IReservedDesk[]>> = await axios(options);
					if (responseReservations.data.err === ServerError.NO_ERROR) {
						reservations = responseReservations.data.payload;
					} else {
						console.error("No reservations!");
						return false;
					}
					for (const desk of desks) {
						const reservation:IReservedDesk | undefined = reservations.find((el) => {
							return el.deskId === desk.id;
						});
						let newDesk: IDeskState | null = null;
						if (reservation) {
							newDesk = {
								...reservation,
								id: reservation._id ? reservation._id : "",
								groupsIdList: desk.groupsIdList,
								coordinates: desk.coordinates,
								type: desk.type
							};
						} else {
							if (desk.manager) {
								newDesk = {
									...NO_RESERVED_DESK,
									id: "",
									groupsIdList: desk.groupsIdList,
									coordinates: desk.coordinates,
									type: desk.type,
									manager: desk.manager,
									managerName: desk.managerName
								};
							} else {
								newDesk = {
									...NO_RESERVED_DESK,
									id: "",
									groupsIdList: desk.groupsIdList,
									coordinates: desk.coordinates,
									type: desk.type
								};
							}
							newDesk.deskId = desk.id;
							if (desk.manager) {
								newDesk.status = ReservationStatus.BUSY;
							} else if (desk.status === "Rezervat") {
								newDesk.status = ReservationStatus.BOOKED;
							} else {
								newDesk.status = ReservationStatus.FREE;
							}
						}
						if (newDesk) {
							newDesk.groupId = groupId;
							state.push(newDesk);
						}
					}
					storeParam.commit("desks", state);
					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async loadGroupReservations (storeParam, { groupId }:{ groupId:string }): Promise<IReservedDesk[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/reservations/desks/group`,
						data: { groupId: groupId }
					};
					const response: AxiosResponse<IServerRES<IReservedDesk[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					} else {
						console.error("No reservations!");
						return [];
					}
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async loadAll (storeParam): Promise<IDesk[]> {
				const options: AxiosRequestConfig = {
					method: "GET",
					url: `${storeParam.rootState.API_URL}/desks`
				};
				const response: AxiosResponse<IServerRES<IDesk[]>> = await axios(options);

				if (response.data.err === ServerError.NO_ERROR) {
					return response.data.payload;
				}

				return [];
			},
			async update (storeParam, { desk }:{ desk:IDesk }): Promise<boolean> {
				try {
					if (isDesk(desk)) {
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${storeParam.rootState.API_URL}/desks/update`,
							data: desk
						};
						const response: AxiosResponse<IServerRES<IDesk>> = await axios(options);
						if (response.data.err === ServerError.NO_ERROR) {
							if (isDesk(response.data.payload)) {
								return true;
							} else {
								return false;
							}
						} else {
							console.error("Can't update1 " + response.data.err);
							return false;
						}
					} else {
						console.error("Can't update2");
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async updateReservedDesk (storeParam, { desk }:{ desk:IReservedDesk }): Promise<boolean> {
				try {
					if (isReservedDesk(desk)) {
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${storeParam.rootState.API_URL}/reservations/desks/update`,
							data: desk
						};
						const response: AxiosResponse<IServerRES<IReservedDesk>> = await axios(options);
						if (response.data.err === ServerError.NO_ERROR) {
							if (isReservedDesk(response.data.payload)) {
								return true;
							} else {
								return false;
							}
						} else {
							console.error("Can't update3");
							return false;
						}
					} else {
						console.error("Can't update4");
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async updateParking (storeParam, { desk }:{ desk:IReservedDesk }): Promise<boolean> {
				try {
					if (isReservedDesk(desk)) {
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${storeParam.rootState.API_URL}/reservations/desks/updateParking`,
							data: desk
						};
						const response: AxiosResponse<IServerRES<IReservedDesk>> = await axios(options);
						if (response.data.err === ServerError.NO_ERROR) {
							if (isReservedDesk(response.data.payload)) {
								return true;
							} else {
								return false;
							}
						} else {
							console.error("Can't update5");
							return false;
						}
					} else {
						console.error("Can't update6");
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			// eslint-disable-next-line
			async reserve (storeParam, { deskEntry, groupId, userName }:{ deskEntry:IDeskState, groupId:string, userName: string }): Promise<boolean> {
				try {
					if (isDeskState(deskEntry)) {
						const reservation:IReservedDesk = JSON.parse(JSON.stringify(NO_RESERVED_DESK));
						reservation.creationDate = new Date();
						reservation.creatorUuid = deskEntry.creatorUuid;
						reservation.deskId = deskEntry.deskId;
						reservation.groupId = deskEntry.groupId;
						reservation.parkingSpot = deskEntry.parkingSpot;
						reservation.reservationDate = deskEntry.reservationDate;
						reservation.status = deskEntry.status;
						reservation.uuid = deskEntry.uuid;
						reservation.name = userName;
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${storeParam.rootState.API_URL}/reservations/desks/update`,
							data: reservation
						};
						const response: AxiosResponse<IServerRES<IReservedDesk>> = await axios(options);
						if (response.data.err === ServerError.NO_ERROR) {
							if (isReservedDesk(response.data.payload)) {
								return true;
							} else {
								return false;
							}
						} else {
							console.error("Can't update5");
							return false;
						}
					} else {
						console.error("Can't update6");
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async cancel (storeParam, { reservationEntry }: { reservationEntry: IReservedDesk }): Promise<boolean> {
				try {
					if (isReservedDesk(reservationEntry)) {
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${storeParam.rootState.API_URL}/reservations/desks/delete`,
							data: reservationEntry
						};
						const response: AxiosResponse<IServerRES<IDesk>> = await axios(options);
						if (response.data.err === ServerError.NO_ERROR) {
							return true;
						} else {
							return false;
						}
					} else {
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async reservations (storeParam, { uuid }: { uuid:string }): Promise<IReservedDesk[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/reservations/desks/user/upcoming`,
						data: {
							uuid: uuid
						},
					};
					const response: AxiosResponse<IServerRES<IReservedDesk[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const reservations = response.data.payload;
						return reservations;
					} else {
						console.error("No Reservations");
						return [];
					}
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async getAvailableParking (storeParam, { groupId, date }: { groupId:string, date: Date }): Promise<number> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/groups/availableParkingSpots`,
						data: {
							groupId: groupId,
							date: date
						},
					};
					const response: AxiosResponse<IServerRES<number>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const availablePark = response.data.payload;
						return availablePark;
					} else {
						console.error("No available spots");
						return 0;
					}
				} catch (error) {
					console.error(error);
					return 0;
				}
			},
			async fetch (storeParam, { reservationId }: { reservationId:string }): Promise<IReservedDesk> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/desks/${reservationId}`
					};
					const response: AxiosResponse<IServerRES<IReservedDesk>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					} else {
						console.error(`No desk with id ${reservationId}`);
						return NO_RESERVED_DESK;
					}
				} catch (error) {
					console.error(error);
					return NO_RESERVED_DESK;
				}
			},
			async fetchAll (storeParam): Promise<IReservedDesk[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/desks`
					};
					const response: AxiosResponse<IServerRES<IReservedDesk[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					} else {
						console.error("No desks");
						return [];
					}
				} catch (error) {
					console.error(error);
					return [];
				}
			}
		},
		modules: {}
	};
	return store;
}
