var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"z-index":"2"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v("Filtre")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.filters.departments,"label":"Departament"},model:{value:(_vm.selectedFilters.departments),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "departments", $$v)},expression:"selectedFilters.departments"}}),_c('v-select',{attrs:{"items":_vm.filters.rooms,"label":"Sală"},model:{value:(_vm.selectedFilters.rooms),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "rooms", $$v)},expression:"selectedFilters.rooms"}}),_c('v-select',{attrs:{"items":_vm.filters.floor,"label":"Etaj"},model:{value:(_vm.selectedFilters.floor),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "floor", $$v)},expression:"selectedFilters.floor"}}),_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de început","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateBeginFormatted),callback:function ($$v) {_vm.dateBeginFormatted=$$v},expression:"dateBeginFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuBegin),callback:function ($$v) {_vm.dateMenuBegin=$$v},expression:"dateMenuBegin"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.disableFutureDates},model:{value:(_vm.selectedFilters.dateBegin),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "dateBegin", $$v)},expression:"selectedFilters.dateBegin"}})],1)],1),_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de sfarsit","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateEndFormatted),callback:function ($$v) {_vm.dateEndFormatted=$$v},expression:"dateEndFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuEnd),callback:function ($$v) {_vm.dateMenuEnd=$$v},expression:"dateMenuEnd"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.disableOldDates},model:{value:(_vm.selectedFilters.dateEnd),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "dateEnd", $$v)},expression:"selectedFilters.dateEnd"}})],1)],1),_c('v-select',{attrs:{"items":_vm.filters.occupier,"label":"Ocupant"},model:{value:(_vm.selectedFilters.occupier),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "occupier", $$v)},expression:"selectedFilters.occupier"}}),_c('v-select',{attrs:{"items":_vm.filters.reserved,"label":"Rezervat de"},model:{value:(_vm.selectedFilters.reserved),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "reserved", $$v)},expression:"selectedFilters.reserved"}}),_c('v-row',{staticClass:"pa-2",attrs:{"justify":"center"}},[_c('v-btn',{on:{"click":_vm.resetFilters}},[_vm._v("Șterge filtrele")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{on:{"click":_vm.createCSV}},[_vm._v("Descarcă CSV")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-data-table',{staticClass:"pl-4 pr-4",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.rezervariFiltrate,"footer-props":{
					'items-per-page-options': [10, 15, 20]
				},"items-per-page":15}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }