import { ReservationStatus } from "./reservations";
import { IUser, NO_USER } from "./users";

export enum DeskType {
	MANAGER = "Manager",
    RESERVED = "Reserved",
    NONE = ""
}

export interface IDesk {
	id: string;
	groupsIdList: string[];
	status: ReservationStatus;
	type: DeskType;
	coordinates: {
        latitude: number;
        longitude: number;
    };
    manager?: string;
    managerName?: string
}

export const NO_DESK: IDesk = {
    id: "",
    groupsIdList: [],
    status: ReservationStatus.NONE,
    type: DeskType.NONE,
    coordinates: {
        latitude: -1,
        longitude: -1
    }
}

export function isDesk(obj: any): boolean {
    const isId = obj.id !== undefined && obj.id !== null;
	const isGroupIdList = obj.groupsIdList !== undefined && obj.groupsIdList !== null && obj.groupsIdList.length !== undefined;
    const isType = obj.type !== undefined && obj.type !== null;
    const isCoordinates = obj.coordinates !== undefined && obj.coordinates !== null

    let result = isId && isGroupIdList && isType && isCoordinates;
    if (isCoordinates) {
        const isLatitude = obj.coordinates.latitude !== undefined && obj.coordinates.latitude !== null;
        const isLongitude = obj.coordinates.longitude !== undefined && obj.coordinates.longitude !== null;

        result = result && isLatitude && isLongitude;
    }

    return result;
}