import Vue from "vue";
import Vuex, { Module } from "vuex";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { RootState } from "@common/ui";
import { ICar } from "@common/cars";

Vue.use(Vuex);
interface State {
	cars: ICar[]
}
const cars: ICar[] = [];

export default function carsStore ():Module<State, RootState> {
	const store: Module<State, RootState> = {
		namespaced: true,
		state: {
			cars: cars
		},
		getters: {
			cars: state => state.cars
		},
		mutations: {
			cars: (state, newCars: ICar[]) => {
				state.cars = newCars;
			}
		},
		actions: {
			async fetch (storeParam, { role, active = true } : { role: string, active: boolean }): Promise<ICar[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: ""
					};

					if (role === "admin") {
						options.url = `${storeParam.rootState.API_URL}/cars`;
					} else if (active) {
						options.url = `${storeParam.rootState.API_URL}/users/cars`;
					} else {
						options.url = `${storeParam.rootState.API_URL}/users/cars/all`;
					}

					const response: AxiosResponse<IServerRES<ICar[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}
					return [];
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async add (storeParam, { car }: { car:ICar }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/cars/add`,
						data: car
					};
					const response: AxiosResponse<IServerRES<ICar>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async delete (storeParam, { car }: { car:ICar }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/cars/delete`,
						data: car
					};
					const response: AxiosResponse<IServerRES<ICar>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async update (storeParam, { car }: { car:ICar }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/cars/update`,
						data: car
					};
					const response: AxiosResponse<IServerRES<ICar>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			}
		},
		modules: {}
	};
	return store;
}
