




























import { Vue } from "vue-property-decorator";
export default Vue.extend({
	name: "NotFound",
	data () {
		return {
		};
	},
	methods: {
		goBack () {
			this.$router.push("/");
		}
	},
});
