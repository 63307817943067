export interface IConferenceRoom {
	id: string;
	groupsIdList: string[];
    details: {
        name: string;
        type?: string;
        hasTV?: boolean;
        hasAudioVideo?: boolean;
        hasPhone?: boolean;
        phoneNo?: string;
    };
    peopleNo: number;
	coordinates: {
        latitude: number;
        longitude: number;
    };
}

export const NO_CONFERENCE_ROOM: IConferenceRoom = {
    id: "",
    details: {
        name: "",
    },
    groupsIdList: [],
    peopleNo: -1,
    coordinates: {
        latitude: -1,
        longitude: -1
    }
}

export function isConferenceRoom (obj: any, withDetails: boolean = false): boolean {
    const isId = obj.id !== undefined && obj.id !== null;
    const isGroupIdList = obj.groupsIdList !== undefined && obj.groupsIdList !== null && obj.groupsIdList.length !== undefined;
    const isPeopleNo = obj.peopleNo !== undefined && obj.peopleNo !== null;
    const isCoordinates = obj.coordinates !== undefined && obj.coordinates !== null

    let result = isId && isGroupIdList && isPeopleNo && isCoordinates;

    if (isCoordinates) {
        const isLatitude = obj.coordinates.latitude !== undefined && obj.coordinates.latitude !== null;
        const isLongitude = obj.coordinates.longitude !== undefined && obj.coordinates.longitude !== null;

        result = result && isLatitude && isLongitude;
    }
    if (withDetails) {
        let isDetails = obj.details !== undefined && obj.details !== null;
        if (isDetails) {
            isDetails = isDetails && (obj.details.name !== undefined && obj.details.name !== null);
        }

        result = result && isDetails;
    }

    return result;
}