var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v("Filtre")]),_c('v-card-text',[_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de început","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateBeginFormatted),callback:function ($$v) {_vm.dateBeginFormatted=$$v},expression:"dateBeginFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuBegin),callback:function ($$v) {_vm.dateMenuBegin=$$v},expression:"dateMenuBegin"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.disableFutureDates},model:{value:(_vm.selectedFilters.dateBegin),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "dateBegin", $$v)},expression:"selectedFilters.dateBegin"}})],1)],1),_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de sfarsit","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateEndFormatted),callback:function ($$v) {_vm.dateEndFormatted=$$v},expression:"dateEndFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuEnd),callback:function ($$v) {_vm.dateMenuEnd=$$v},expression:"dateMenuEnd"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.disableOldDates},model:{value:(_vm.selectedFilters.dateEnd),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "dateEnd", $$v)},expression:"selectedFilters.dateEnd"}})],1)],1),_c('v-row',{staticClass:"mt-2",staticStyle:{"margin":"0","padding":"0"},attrs:{"justify":"center"}},[_c('v-btn',{on:{"click":function($event){_vm.selectedFilters.dateBegin = ''; _vm.selectedFilters.dateEnd = ''; _vm.resetData();}}},[_vm._v("Șterge filtre")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{on:{"click":_vm.createCSV}},[_vm._v("Descarcă CSV")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"pl-4 pr-4",staticStyle:{"margin":"0","font-size":"20px"},attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"footer-props":{
						'items-per-page-options': [10, 15, 20]
					},"items-per-page":15}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }