export enum Roles {
	ADMIN = "admin",
	MANAGER = "manager",
	USER = "user",
	NONE = ""
}

export enum BRDBuildings {
	None = "0",
	Flexioffice = "1",
	Turn = "2"
}

export interface IUser {
	uuid: string;
	name: string;
	email: string;
	mainGroup: string | null;
	groupList: string[];
	building: BRDBuildings;
	buildingList: BRDBuildings[];
	parkingRight: boolean;
	managerOf?: string[];
	adminOf?: string[];
	manager: IUser | string;
	director: IUser | string;
	role?: Roles;
	confirmed?: boolean;
	phoneNumber?: string;
	officeFloor?: number;
	officeRoom?: string;
	graded?: boolean;
	indisciplineScore?: number;
	departmentName?: string;
};

export interface IBanStatus {
	banned: boolean;
	until: Date;
};

export const NO_BAN_STATUS: IBanStatus = {
	banned: false,
	until: new Date()
};

export interface IBanStatus {
	banned: boolean;
	until: Date;
};

export const NO_USER: IUser = {
	uuid: "-1",
	name: "",
	email: "",
	mainGroup: null,
	groupList: [],
	building: BRDBuildings.None,
	buildingList: [],
	parkingRight: false,
	managerOf: [],
	adminOf: [],
	manager: "-",
	director: "-",
	role: Roles.NONE,
	confirmed: false
};


export function isUser (obj: any, withManagerOf:boolean = false, withAdminOf:boolean = false, withRole:boolean = false): obj is IUser {
	const isUuid = obj.uuid !== undefined && obj.uuid !== null;
	const isName = obj.name !== undefined && obj.name !== null;
	const isEmail = obj.email !== undefined && obj.email !== null;
	const isMainGroup = obj.mainGroup !== undefined;
	const isGroupList = obj.groupList !== undefined && obj.groupList !== null && obj.groupList.length !== undefined;
	const isBuilding = obj.building !== undefined && obj.building !== null;
	
	let result = isUuid && isName && isEmail && isMainGroup && isGroupList && isBuilding;
	if (withManagerOf) {
		const isManagerOf = obj.managerOf !== undefined && obj.managerOf !== null && obj.managerOf.length !== undefined;
		result = result && isManagerOf;
	}
	if (withAdminOf) {
		const isAdminOf = obj.adminOf !== undefined && obj.adminOf !== null && obj.adminOf.length !== undefined;
		result = result && isAdminOf;
	}
	if (withRole) {
		const isRole = obj.role !== undefined && obj.role !== null;
		result = result && isRole;
	}
	return result;
};

export function computeRole (user: IUser): Roles {
	if (isUser(user)) {
		if (user.adminOf && user.adminOf.length > 0) {
			return Roles.ADMIN;
		} else if (user.managerOf && user.managerOf.length > 0) {
			return Roles.MANAGER;
		} else {
			return Roles.USER;
		}
	} else {
		return Roles.NONE;
	}
}