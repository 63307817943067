




































































import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { ICar, NO_CAR } from "@common/cars";
import { IParkingReservation } from "@common/parkingReservations";
import { ConfigType, DefaultConfig, IConfig } from "@common/config";

export default Vue.extend({
	name: "AddReservation",
	data () {
		const auxDefaultDate = moment().isBefore(moment().day(5).hour(DefaultConfig.FRIDAY_CUTOFF_HOUR)) ? moment().add(1, "weeks").startOf("isoWeek").add(4, "hours").toDate() : moment().add(2, "weeks").startOf("isoWeek").add(4, "hours").toDate();
		const cars : ICar[] = [];
		const selectedCar = NO_CAR;
		const parkingReservations : IParkingReservation[] = [];
		const minDateBegin = moment().isBefore(moment().hour(20)) ? moment().add(1, "day").startOf("day").toDate() : moment().add(2, "day").startOf("day").toDate();

		return {
			dateMenuBegin: false,
			dateBegin: [],
			dateEnd: auxDefaultDate.toISOString().substring(0, 10),
			minDateBegin: minDateBegin.toISOString().substring(0, 10),
			cars: cars,
			selectedCar: selectedCar,
			errorAlert: false,
			errorMessage: "",
			parkingReservations: parkingReservations,
			validDay: true,
			loading: true,
			isBanned: false,
			banEndDate: moment().format("DD.MM.YYYY"),
			fridayCutoffHour: DefaultConfig.FRIDAY_CUTOFF_HOUR,
			dailyCutoffHour: DefaultConfig.CONFIRMATION_DEADLINE_HOUR
		};
	},
	watch: {
		dateBegin () {
			this.checkReservations();
		},
		userState: {
			immediate: true,
			async handler () {
				await this.fetchData();
			}
		}
	},
	computed: {
		...mapGetters({
			userState: "userStore/user",
			configState: "configStore/configs"
		}),
		dateBeginFormatted (): string {
			let string = "";

			for (const date of this.dateBegin) {
				string += moment(date).format("DD.MM.YYYY") + ", ";
			}

			return string.substring(0, string.length - 2);
		},
		dateEndFormatted () : string {
			return moment(this.dateEnd).format("DD.MM.YYYY");
		},
	},
	methods: {
		checkReservations () {
			this.validDay = true;

			for (const reservation of this.parkingReservations) {
				for (const date of this.dateBegin) {
					if (moment(reservation.reservation_date).isSame(moment(date), "day")) {
						this.validDay = false;
					}
				}
			}
		},
		allowedDates (val:string) {
			var isValid = true;

			// Cannot reserve in weekends
			if (new Date(val).getDay() === 0 || new Date(val).getDay() === 6) {
				isValid = false;
			}

			// Calculate the next week start and end, the nextWeekStart includes the same week also
			const nextWeekStart = moment().add(1, "day").startOf("day").toDate();
			let nextWeekEnd = moment().add(1, "weeks").endOf("isoWeek").toDate();

			// If we are after the friday cutoff hour, the next week is the week after the next
			if (moment().isAfter(moment().isoWeekday(5).hour(this.fridayCutoffHour).minutes(0).seconds(0))) {
				nextWeekEnd = moment().add(2, "weeks").endOf("isoWeek").toDate();
			}

			// If the date is not in the next week or the same week, date is not valid
			// Next week = The week in which there hasn't run the friday 16:00 algorithm
			// Same week = The week in which there has run the friday 16:00 algorithm
			if (!moment(val).add(5, "hours").isBetween(moment(nextWeekStart), moment(nextWeekEnd))) {
				isValid = false;
			// If the date is in the same week, and the current day is friday, saturday or sunday
			// We cannot make reservations in the same week.
			} else if ([5, 6, 7].includes(moment().isoWeekday()) &&
					moment().isAfter(moment().isoWeekday(5).hour(this.fridayCutoffHour).minutes(0).seconds(0)) &&
					moment(val).isBefore(moment(nextWeekEnd).subtract(1, "weeks"))) {
				isValid = false;
			}

			// Check if there is a reservation for this day, if there is
			// The date cannot be selected
			for (const reservation of this.parkingReservations) {
				if (moment(reservation.reservation_date).isSame(moment(val), "day")) {
					isValid = false;
				}
			}

			if (moment(val).subtract(1, "day").isSame(moment(), "day") && moment().hour() >= this.dailyCutoffHour) {
				isValid = false;
			}

			return isValid;
		},
		carSelect (item: ICar) {
			return item.brand + " - " + item.license_plate;
		},
		async fetchData () {
			this.loading = true;

			this.cars = await this.$store.dispatch("carsStore/fetch", { role: "user" });
			this.parkingReservations = await this.$store.dispatch("parkingStore/fetch", { uuid: this.userState.userInfo.uuid });

			const banStatus = await this.$store.dispatch("userStore/fetchBanStatus", { token: this.userState.token });
			this.isBanned = banStatus.banned;
			this.banEndDate = moment(banStatus.until).format("DD.MM.YYYY");

			await this.$store.dispatch("configStore/load");
			const fridayConf = this.configState.find((config: IConfig) => config.name === ConfigType.FRIDAY_CUTOFF_HOUR);

			if (fridayConf) {
				this.fridayCutoffHour = parseInt(fridayConf.value);
			}

			this.checkReservations();

			this.$forceUpdate();
			this.loading = false;
		},
		async createReservation () {
			if (this.selectedCar._id === undefined) {
				this.errorAlert = true;
				this.errorMessage = "Selectează o mașină";

				return;
			}
			if (this.selectedCar._id === "-1") {
				this.errorAlert = true;
				this.errorMessage = "Selectează o mașină";

				return;
			}

			this.loading = true;
			let valid = true;

			for (const date of this.dateBegin) {
				const parkingReservation : IParkingReservation = {
					car_id: this.selectedCar._id,
					date_added: moment().toDate(),
					reservation_date: moment(date + " " + "08:00").toDate(),
					user_id: this.userState.userInfo.uuid
				};

				const response = await this.$store.dispatch("parkingStore/reserve", { parkingReservation });

				if (!response) {
					valid = false;
				}
			}

			if (valid) {
				this.$router.push("/turn/reservations");
			} else {
				this.errorAlert = true;
				this.errorMessage = "Aveti deja o rezervare pentru aceasta data";
			}

			this.loading = false;
		}
	}
});
