import Vue from "vue";
import Vuex, { Module } from "vuex";
import { IUserState, isUserState, IUserGroups, NO_USER_GROUPS, RootState } from "@common/ui";
import { IGroup, NO_GROUP } from "@common/groups";
import { NO_USER, IUser, isUser, IBanStatus, NO_BAN_STATUS, BRDBuildings } from "@common/users";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
Vue.use(Vuex);

const TOKEN = "flexioffice:token";

axios.interceptors.request.use((config:AxiosRequestConfig) => {
	if (window.localStorage.getItem(TOKEN)) {
		config.headers.Authorization = `Bearer ${window.localStorage.getItem(TOKEN)}`;
		config.headers["Access-Control-Allow-Origin"] = "*";
	}
	return config;
});
interface State {
	user:IUserState
}
const user: IUserState = {
	userInfo: NO_USER,
	token: window.localStorage.getItem(TOKEN) || ""
};
export default function userStore ():Module<State, RootState> {
	const store: Module<State, RootState> = {
		namespaced: true,
		state: {
			user: user
		},
		getters: {
			user: state => state.user,
		},
		mutations: {
			user: (state, newUser: IUserState) => {
				state.user = newUser;
				window.localStorage.setItem(TOKEN, newUser.token);
			}
		},
		actions: {
			// eslint-disable-next-line
			async get (storeParam, { token, uuid }: { token:string, uuid:string }): Promise<IUser> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/users/info/${uuid}`,
					};
					const response: AxiosResponse<IServerRES<IUser>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const user = response.data.payload;
						if (user && isUser(user)) {
							return user;
						} else {
							return NO_USER;
						}
					} else {
						console.error("Cannot get user");
						return NO_USER;
					}
				} catch (error) {
					console.error(error);
					return NO_USER;
				}
			},
			async loadUsers (storeParam, { groupId }: { groupId:string }):Promise<IUser[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/users/group/${groupId}`,
					};
					const response: AxiosResponse<IServerRES<IUser[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const users = response.data.payload;
						return users;
					} else {
						console.error("No users");
						return [];
					}
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async loadAllUsers (storeParam, { building } : { building: BRDBuildings } = { building: BRDBuildings.Flexioffice }): Promise<IUser[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/users`,
					};
					const response: AxiosResponse<IServerRES<IUser[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const users = response.data.payload;
						return users.filter((user) => user.building === building || user.buildingList.includes(building));
					} else {
						console.error("No users");
						return [];
					}
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async login (storeParam, { username, password }: { username: string; password: string }): Promise<boolean> {
				// axios to login => session
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/users/login`,
						headers: {
							"Access-Control-Allow-Origin": "*"
						},
						data: {
							username,
							password
						}
					};
					const response: AxiosResponse<IServerRES<IUserState>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const userState = response.data.payload;
						if (userState && isUserState(userState)) {
							storeParam.commit("user", userState);
							return true;
						} else {
							console.error("Cannot commit userState");
							return false;
						}
					} else {
						console.error("No userState");
						console.error(response.data.err);
						return false;
					}
				} catch (e) {
					console.error(e);
					return false;
				}
			},
			async logout (storeParam, { token }:{ token:string }):Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/users/logout`,
						data: {
							token
						},
					};
					const response: AxiosResponse<IServerRES<string>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						storeParam.commit("user", user);
						return true;
					} else {
						console.error("Couldn't logout");
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async loadGroups (storeParam, { role, building }: { role:string, building: BRDBuildings }): Promise<IUserGroups> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: "",
					};
					if (role === "admin") {
						options.url = `${storeParam.rootState.API_URL}/groups/`;
					} else if (role === "manager") {
						options.url = `${storeParam.rootState.API_URL}/users/groups/`;
					} else {
						options.url = `${storeParam.rootState.API_URL}/users/groups/`;
					}
					const response: AxiosResponse<IServerRES<IUserGroups | IGroup[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						let groups = response.data.payload;
						if (role === "admin") {
							if (building === undefined) {
								building = BRDBuildings.Flexioffice;
							}

							groups = (groups as IGroup[]).filter((group) => group.building === building);
							return {
								groupList: groups,
								mainGroup: groups[0]
							} as IUserGroups;
						} else {
							groups = (groups as IUserGroups);
							return groups;
						}
					} else {
						console.error("No Groups");
						return NO_USER_GROUPS;
					}
				} catch (error) {
					console.error(error);
					return NO_USER_GROUPS;
				}
			},
			// eslint-disable-next-line
			async verifyToken (storeParam, { token }: { token:string }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/users/verify/`,
					};
					const response: AxiosResponse<IServerRES<IUser>> = await axios(options);
					let verified = false;
					if (response.data.err === ServerError.NO_ERROR) {
						if (response.data.payload.uuid !== NO_USER.uuid) {
							verified = true;
							storeParam.commit("user", {
								token: user.token,
								userInfo: response.data.payload
							});
						} else {
							storeParam.commit("user", {
								token: "",
								userInfo: NO_USER
							});
							window.localStorage.setItem(TOKEN, "");
						}
					}
					return verified;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async update (storeParam, { user, token }: { user:IUser, token:string }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/users/update`,
						data: user,
						headers: {
							Authorization: `Bearer ${token}`
						}
					};
					const response: AxiosResponse<IServerRES<IUser>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async updateGroup (storeParam, { group, token }: { group:IGroup, token:string }): Promise<IGroup> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/groups/update`,
						data: group,
						headers: {
							Authorization: `Bearer ${token}`
						}
					};
					const response: AxiosResponse<IServerRES<IGroup>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}
					return NO_GROUP;
				} catch (error) {
					console.error(error);
					return NO_GROUP;
				}
			},
			async deleteGroup (storeParam, { group, token }: { group:IGroup, token:string }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/groups/delete`,
						data: group,
						headers: {
							Authorization: `Bearer ${token}`
						}
					};
					const response: AxiosResponse<IServerRES<IGroup>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async delete (storeParam, { user, token }: { user:IUser, token:string }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/users/delete`,
						data: user,
						headers: {
							Authorization: `Bearer ${token}`
						}
					};
					const response: AxiosResponse<IServerRES<IUser>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async randomPassword (storeParam, { user, token }: {user:IUser, token:string}): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/users/setRandomPassword`,
						data: user,
						headers: {
							Authorization: `Bearer ${token}`
						}
					};
					const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async getUserByUsername (storeParam, { username, token }: { username:string, token:string }): Promise<IUser> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/users/get/${username}`,
						headers: {
							Authorization: `Bearer ${token}`
						}
					};
					const response: AxiosResponse<IServerRES<IUser>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}
					return NO_USER;
				} catch (error) {
					console.error(error);
					return NO_USER;
				}
			},
			async fetchBanStatus (storeParam, { token }: { token:string }): Promise<IBanStatus> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/users/banStatus`,
						headers: {
							Authorization: `Bearer ${token}`
						}
					};
					const response: AxiosResponse<IServerRES<IBanStatus>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}
					return NO_BAN_STATUS;
				} catch (error) {
					console.error(error);
					return NO_BAN_STATUS;
				}
			},
			async getIndiscipline (storeParam): Promise<number> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/users/indiscipline`,
					};
					const response: AxiosResponse<IServerRES<number>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}

					return 0;
				} catch (error) {
					console.error(error);
					return 0;
				}
			},
			async deleteIndisciplineScore (storeParam, { user }: { user: string }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/users/indiscipline/delete`,
						data: {
							user: user
						}
					};
					const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}

					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
		},
		modules: {
		}
	};
	return store;
}
