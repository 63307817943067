






















import Vue from "vue";
import DragDropUpload from "@/components/turn/admin/DragDropUpload.vue";
import * as XLSX from "xlsx";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IUser } from "@common/users";

export default Vue.extend({
	name: "UploadUsersTurn",
	components: {
		DragDropUpload
	},
	data () {
		const usersList: {nume: string, email: string, username: string, telefon: string, grading: boolean}[] = [];
		const usersListHeader: {
			text: string,
			align: string,
			value: string,
			sortable: boolean
		}[] = [
			{
				text: "Nume",
				align: "center",
				value: "nume",
				sortable: true
			},
			{
				text: "Email",
				align: "center",
				value: "email",
				sortable: true
			},
			{
				text: "Username",
				align: "center",
				value: "username",
				sortable: true
			},
			{
				text: "Numar de telefon",
				align: "center",
				value: "telefon",
				sortable: true
			},
			{
				text: "Grading",
				align: "center",
				value: "grading",
				sortable: true
			},
		];

		return {
			uploaded: false,
			uploadTry: 0,
			usersList: usersList,
			usersListBinary: "",
			usersListHeader: usersListHeader,
			loading: false
		};
	},
	methods: {
		upload (file: string) {
			// Remount the DragDropUpload component
			this.uploadTry++;
			this.uploaded = true;

			const workbook = XLSX.read(file, { type: "binary" });
			const sheet = workbook.Sheets[workbook.SheetNames[0]];
			const json: {nume: string, email: string, username: string, telefon: string, grading: string}[] = XLSX.utils.sheet_to_json(sheet, { raw: false });

			if (json.length === 0) {
				this.uploaded = false;
				return this.$emit("showError", "Lista de utilizatori este goala");
			} else if (json[0].nume === undefined || json[0].email === undefined || json[0].username === undefined || json[0].telefon === undefined) {
				this.uploaded = false;
				return this.$emit("showError", "Lista de utilizatori nu are formatul corect");
			}

			json.forEach((data) => {
				this.usersList.push({
					nume: data.nume,
					email: data.email,
					username: data.username,
					telefon: data.telefon,
					grading: data.grading !== undefined
				});
			});
			this.usersListBinary = file;
		},
		reuploadUsersList () {
			this.uploaded = false;
			this.usersList = [];
			this.usersListBinary = "";
		},
		async sendUsersList () {
			this.loading = true;

			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/users/massRegisterTurn`,
				data: {
					data: this.usersListBinary,
					encoding: "binary"
				}
			};

			const response: AxiosResponse<IServerRES<IUser[]>> = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				this.$emit("showError", "Lista de utilizatori a fost incarcata cu succes", "success");
				this.$emit("fetchData", false);
				this.$emit("changePage", "usersList");
			} else {
				this.$emit("showError", "Lista de utilizatori nu a putut fi trimisa");
			}

			this.loading = false;
		}
	}
});
