


















import { ICar } from "@common/cars";
import Vue from "vue";

export default Vue.extend({
	name: "CarsList",
	props: {
		cars: {
			type: Array as () => ICar[],
			required: true
		}
	},
	data () {
		const filteredCars: ICar[] = [];

		return {
			searchQuery: "",
			filteredCars: filteredCars
		};
	},
	watch: {
		searchQuery (val) {
			this.filteredCars = this.cars.filter((car) => {
				return car.brand.toLowerCase().includes(val.toLowerCase()) || car.license_plate.toLowerCase().includes(val.toLowerCase());
			});
		}
	}
});
