import Vue from "vue";
import Vuex, { Module } from "vuex";
import { IConferenceRoomState, RootState } from "@common/ui";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IConferenceRoom, isConferenceRoom } from "@common/conferenceRooms";
import { IReservedConferenceRoom, isReservedConferenceRoom, NO_RESERVED_CONFERENCE_ROOM, ReservationStatus } from "@common/reservations";

Vue.use(Vuex);
interface State {
	rooms:IConferenceRoomState[],
	roomsDetails:IConferenceRoom[]
}
const rooms:IConferenceRoomState[] = [];
const roomsDetails: IConferenceRoom[] = [];

export default function roomStore ():Module<State, RootState> {
	const store: Module<State, RootState> = {
		namespaced: true,
		state: {
			rooms: rooms,
			roomsDetails: roomsDetails
		},
		getters: {
			rooms: state => state.rooms,
			roomsDetails: state => state.roomsDetails
		},
		mutations: {
			rooms: (state, newrooms: IConferenceRoomState[]) => {
				state.rooms = newrooms;
			},
			roomsDetails: (state, newDetails: IConferenceRoom[]) => {
				state.roomsDetails = newDetails;
			}
		},
		actions: {
			// eslint-disable-next-line
			async loadDetails (storeParam, { token }: {token: string}): Promise<boolean> {
				try {
					let roomDetails: IConferenceRoom[] = [];
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/conference-rooms`,
					};
					const responseRooms: AxiosResponse<IServerRES<IConferenceRoom[]>> = await axios(options);
					if (responseRooms.data.err === ServerError.NO_ERROR) {
						roomDetails = responseRooms.data.payload;
					} else {
						console.error("No rooms!");
						return false;
					}
					storeParam.commit("roomsDetails", roomDetails);
					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			// eslint-disable-next-line
			async load (storeParam, { token, date, groupId }:{ token:string, date:Date, groupId:string }): Promise<boolean> {
				try {
					let rooms: IConferenceRoom[] = [];
					const newRooms: IConferenceRoomState[] = [];
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/conference-rooms/${groupId}`,
					};
					const responseRooms: AxiosResponse<IServerRES<IConferenceRoom[]>> = await axios(options);
					if (responseRooms.data.err === ServerError.NO_ERROR) {
						rooms = responseRooms.data.payload;
					} else {
						console.error("No rooms!");
						return false;
					}

					let allReservations: IReservedConferenceRoom[] = [];
					options.method = "POST";
					options.url = `${storeParam.rootState.API_URL}/reservations/conference-rooms/all`;
					options.data = {
						date: date
					};
					const responseAllReservations: AxiosResponse<IServerRES<IReservedConferenceRoom[]>> = await axios(options);
					if (responseAllReservations.data.err === ServerError.NO_ERROR) {
						allReservations = responseAllReservations.data.payload;
					}

					for (const room of rooms) {
						let reservations: IReservedConferenceRoom[] = allReservations.filter((reservation) => {
							return reservation.conferenceRoomId === room.id;
						});

						if (reservations.length === 0) {
							reservations = [];
						}

						const auxRoom:IConferenceRoomState = {
							...room,
							reservations: reservations
						};
						newRooms.push(auxRoom);
					}
					storeParam.commit("rooms", newRooms);
					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async update (storeParam, { token, roomEntry }: { token: string, roomEntry: IConferenceRoom }): Promise<boolean> {
				try {
					if (isConferenceRoom(roomEntry)) {
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${storeParam.rootState.API_URL}/conference-rooms/update`,
							data: roomEntry,
							headers: {
								Authorization: `Bearer ${token}`
							}
						};
						const response: AxiosResponse<IServerRES<IConferenceRoom>> = await axios(options);
						if (response.data.err === ServerError.NO_ERROR) {
							const room = response.data.payload;
							if (isConferenceRoom(room)) {
								return true;
							} else {
								console.error("Couldn't update");
								return false;
							}
						} else {
							console.error("Couldn't update");
							return false;
						}
					} else {
						console.error("Bad conferenceRoom");
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async reserve (storeParam, { token, roomEntry, dateBegin, dateEnd, uuid }:{ token:string, roomEntry:IConferenceRoomState, dateEnd: Date, dateBegin:Date, uuid:string }): Promise<boolean> {
				try {
					if (isConferenceRoom(roomEntry)) {
						const reservation:IReservedConferenceRoom = JSON.parse(JSON.stringify(NO_RESERVED_CONFERENCE_ROOM));

						reservation.conferenceRoomId = roomEntry.id;
						reservation.creationDate = new Date();
						reservation.dateBegin = dateBegin;
						reservation.dateEnd = dateEnd;
						reservation.status = ReservationStatus.BOOKED;
						reservation.uuid = uuid;
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${storeParam.rootState.API_URL}/reservations/conference-rooms/update`,
							data: reservation,
							headers: {
								Authorization: `Bearer ${token}`
							}
						};
						const response: AxiosResponse<IServerRES<IReservedConferenceRoom>> = await axios(options);

						if (response.data.err === ServerError.NO_ERROR) {
							if (isReservedConferenceRoom(response.data.payload)) {
								return true;
							} else {
								return false;
							}
						} else {
							console.error("Can't update");
							return false;
						}
					} else {
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async cancel (storeParam, { reservationEntry }:{ reservationEntry:IReservedConferenceRoom }): Promise<boolean> {
				try {
					if (isReservedConferenceRoom(reservationEntry)) {
						const options: AxiosRequestConfig = {
							method: "POST",
							url: `${storeParam.rootState.API_URL}/reservations/conference-rooms/delete`,
							data: reservationEntry,
						};
						const response: AxiosResponse<IServerRES<IReservedConferenceRoom>> = await axios(options);
						if (response.data.err === ServerError.NO_ERROR) {
							return true;
						} else {
							return false;
						}
					} else {
						return false;
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			// eslint-disable-next-line
			async reservations (storeParam, { token, uuid }: { token:string, uuid:string }): Promise<IReservedConferenceRoom[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/reservations/conference-rooms/user/upcoming`,
						data: {
							uuid: uuid
						},
					};
					const response: AxiosResponse<IServerRES<IReservedConferenceRoom[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const reservations = response.data.payload;
						return reservations;
					} else {
						console.error("No Reservations");
						return [];
					}
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async fetch (storeParam, { reservationId }: { reservationId:string }): Promise<IReservedConferenceRoom> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/conference-rooms/${reservationId}`,
					};
					const response: AxiosResponse<IServerRES<IReservedConferenceRoom>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const reservation = response.data.payload;
						return reservation;
					} else {
						console.error("No Reservations");
						return NO_RESERVED_CONFERENCE_ROOM;
					}
				} catch (error) {
					console.error(error);
					return NO_RESERVED_CONFERENCE_ROOM;
				}
			},
			async fetchAll (storeParam): Promise<IConferenceRoom[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/conference-rooms`,
					};
					const response: AxiosResponse<IServerRES<IConferenceRoom[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						const rooms = response.data.payload;
						return rooms;
					} else {
						console.error("No Rooms");
						return [];
					}
				} catch (error) {
					console.error(error);
					return [];
				}
			}
		},
		modules: {}
	};
	return store;
}
