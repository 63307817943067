import Vue from "vue";
import Vuex, { Module } from "vuex";
import { RootState, IConfigState } from "@common/ui";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IConfig } from "@common/config";
Vue.use(Vuex);

const TOKEN = "flexioffice:token";

axios.interceptors.request.use((config: AxiosRequestConfig) => {
	if (window.localStorage.getItem(TOKEN)) {
		config.headers.Authorization = `Bearer ${window.localStorage.getItem(
			TOKEN
		)}`;
		config.headers["Access-Control-Allow-Origin"] = "*";
	}
	return config;
});
interface State {
	configs: IConfigState[];
}
const configs: IConfigState[] = [];

export default function configStore (): Module<State, RootState> {
	const store: Module<State, RootState> = {
		namespaced: true,
		state: {
			configs: configs
		},
		getters: {
			configs: state => state.configs
		},
		mutations: {
			configs: (state, newConfigs: IConfigState[]) => {
				state.configs = newConfigs;
			}
		},
		actions: {
			async load (storeParam): Promise<boolean> {
				try {
					let configs: IConfig[] = [];
					let newConfigs: IConfigState[] = [];
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/config`
					};
					const res: AxiosResponse<IServerRES<
						IConfig[]
					>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						configs = res.data.payload;
					} else {
						console.error("No configs!");
						return false;
					}
					newConfigs = configs.map((config: IConfig) => {
						return {
							name: config.name,
							value: config.value,
							building: config.building
						};
					});
					storeParam.commit("configs", newConfigs);

					return true;
				} catch (error) {
					console.error(error);
					return false;
				}
			}
		},
		modules: {}
	};
	return store;
}
