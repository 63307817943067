



























































































































// import { UserState, RoomState } from "@common/ui";
import { ReservationStatus, IReservedConferenceRoom, IReservedDesk } from "@common/reservations";
import { isConferenceRoom, NO_CONFERENCE_ROOM } from "@common/conferenceRooms";
import { NO_DESK } from "@common/desks";
import { IDeskState, IConferenceRoomState, IUserState, isDeskState } from "@common/ui";
import Vue from "vue";
import { CRS, latLng, latLngBounds } from "leaflet";
import { LMap, LImageOverlay, LMarker, LIcon, LTooltip, LPopup } from "vue2-leaflet";
import Legend from "@/components/flexioffice/Legend.vue";
import moment from "moment";
import { IGroup } from "@common/groups";
export default Vue.extend({
	name: "Map",
	props: {
		userState: {
			type: Object as () => IUserState
		},
		desksState: {
			type: Array as () => IDeskState[]
		},
		roomsState: {
			type: Array as () => IConferenceRoomState[]
		},
		roomReservations: {
			type: Array as () => IReservedConferenceRoom[]
		},
		deskReservations: {
			type: Array as () => IReservedDesk[]
		},
		selectedGroup: {
			type: String,
			default: () => "-1"
		},
		selectedGroupData: {
			type: Object as () => IGroup
		},
		date: {
			type: String
		},
		checkValidity: {
			type: Boolean
		},
		floor: {
			type: String
		},
		loadMap: {
			type: Boolean
		},
		maxActiveRoom: {
			type: Number
		},
		maxActiveDesk: {
			type: Number
		}
	},
	components: {
		LMap,
		LImageOverlay,
		LMarker,
		LIcon,
		LTooltip,
		LPopup,
		Legend
	},
	computed: {
		groupName (): string {
			return this.selectedGroupData.name;
		}
	},
	watch: {
		desksState: {
			immediate: true,
			handler () {
				this.$forceUpdate();
			}
		},
		async $route (to) {
			if (to.path === "/") {
				const center = (this.$refs.officeMap as LMap).center;
				(this.$refs.officeMap as LMap).mapObject.setView(center, -2);
			} else {
				(this.$refs.officeMap as LMap).mapObject.invalidateSize();
			}
		},
		roomsState: {
			immediate: true,
			handler () {
				if (this.roomsState.length > 0) {
					this.loading = false;
					const center = (this.$refs.officeMap as LMap).center;
					(this.$refs.officeMap as LMap).mapObject.setView(center, -2);
					this.activateDisplay();
					(this.$refs.officeMap as LMap).mapObject.invalidateSize();
					(this.$refs.officeMap as LMap).mapObject.setView(center, -2);
				}
			}
		},
		floor: {
			immediate: true,
			handler (newFloor) {
				this.url = "/markers/Design Flexi Offices_ ETAJ " + newFloor + "-1.png";
			}
		}
	},
	data () {
		const pt1 = latLng(2060, -3433);
		const pt2 = latLng(-2060, 3433);
		const bounds = latLngBounds(pt1, pt2);
		const desk = NO_DESK;
		const room = NO_CONFERENCE_ROOM;
		return {
			isPermanent: false,
			loading: true,
			deskItem: desk,
			roomItem: room,
			// url: "/markers/floor4-nn-new.pdf",
			url: "/markers/Design Flexi Offices_ ETAJ 4-1.png",
			bounds: bounds,
			center: latLng(0, 0),
			minZoom: -3,
			maxZoom: 0,
			crs: CRS.Simple,
			w: "1920px",
			h: "1080px",
			baseIconSize: [15, 15],
			ReservedStatus: ReservationStatus,
		};
	},
	methods: {
		activateDisplay () {
			setTimeout(() => {
				(this.$refs.officeMap as LMap).mapObject.invalidateSize();
			}, 1000);
		},
		openDeskDialog (desk:IDeskState) {
			this.$emit("open-desk-dialog", desk);
			this.deskItem = desk;
		},
		openRoomDialog (room:IConferenceRoomState) {
			this.$emit("open-room-dialog", { room: room, status: this.computeColor(room) });
			this.roomItem = room;
		},
		computeColor (entry: (IDeskState | IConferenceRoomState)) {
			if (entry !== undefined && isDeskState(entry)) {
				let icon = "not_available_dot.png";
				if (entry.groupsIdList.includes(this.selectedGroup)) {
					if (entry.status === ReservationStatus.BOOKED || entry.status === ReservationStatus.BUSY) {
						if (entry.uuid.toString() === this.userState.userInfo.uuid.toString()) {
							icon = "occupied_dot.png";
						} else {
							icon = "reserved_dot.png";
						}
					} else if (this.deskReservations.length >= this.maxActiveDesk) {
						icon = "grey_dot.png";
					} else {
						icon = "empty_dot.png";
					}
				} else {
					icon = "not_available_dot.png";
				}
				return icon;
			} else if (entry !== undefined && isConferenceRoom(entry)) {
				let icon = "room_dot.png";
				if (this.roomReservations.length < this.maxActiveRoom || (this.userState.userInfo.role === "admin" || this.userState.userInfo.role === "manager")) {
					for (const res of entry.reservations) {
						const dBegin = res.dateBegin;
						const dEnd = res.dateEnd;
						const dBeginMom = moment(dBegin);
						const dEndMom = moment(dEnd);
						const sameDayEnd = (
							dEndMom.isSame(this.date, "year") &&
							dEndMom.isSame(this.date, "month") &&
							dEndMom.isSame(this.date, "day")
						);
						if (sameDayEnd) {
							const h1Aux = dBeginMom.hours() - 3;
							const h2Aux = dEndMom.hours() - 3;
							const hAux = h2Aux - h1Aux;
							if (hAux < 12.5) {
								icon = "room_dot.png";
							} else {
								icon = "room_dot.png";
							}
						} else {
							icon = "room_dot.png";
						}
					}
				} else {
					icon = "grey_dot.png";
				}
				return icon;
			} else {
				return "not_available_dot.png";
			}
		},
		// eslint-disable-next-line
		updated (e:any) {
			const obj = {
				latitude: 0,
				longitude: 0,
			};
			obj.latitude = e.lat;
			obj.longitude = e.lng;
		}
	}
});
