



















import Vue from "vue";
import DragDropUpload from "@/components/turn/admin/DragDropUpload.vue";
import * as XLSX from "xlsx";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

export default Vue.extend({
	name: "PresenceList",
	components: {
		DragDropUpload
	},
	data () {
		const presenceList: {date: string, licensePlate: string}[] = [];
		const presenceListHeader: {
			text: string,
			align: string,
			value: string,
			sortable: boolean
		}[] = [
			{
				text: "Data",
				align: "center",
				value: "date",
				sortable: true
			},
			{
				text: "Numar de Inmatriculare",
				align: "center",
				value: "licensePlate",
				sortable: true
			}
		];

		return {
			uploaded: false,
			uploadTry: 0,
			presenceList: presenceList,
			presenceListBinary: "",
			presenceListHeader: presenceListHeader
		};
	},
	methods: {
		upload (file: string) {
			// Remount the DragDropUpload component
			this.uploadTry++;
			this.uploaded = true;

			const workbook = XLSX.read(file, { type: "binary" });
			const sheet = workbook.Sheets[workbook.SheetNames[0]];
			const json: {date: string, licensePlate: string}[] = XLSX.utils.sheet_to_json(sheet, { raw: false });

			if (json.length === 0) {
				this.uploaded = false;
				return this.$emit("showError", "Lista de prezenta este goala");
			} else if (json[0].date === undefined || json[0].licensePlate === undefined) {
				this.uploaded = false;
				return this.$emit("showError", "Lista de prezenta nu are formatul corect");
			}

			this.presenceList = json;
			this.presenceListBinary = file;
		},
		reuploadPresenceList () {
			this.uploaded = false;
			this.presenceList = [];
			this.presenceListBinary = "";
		},
		async sendPresenceList () {
			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/reservations/parking/presence`,
				data: {
					data: this.presenceListBinary,
					encoding: "binary"
				}
			};

			const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				this.$emit("showError", "Lista de prezenta a fost trimisa cu succes", "success");
				this.reuploadPresenceList();
			} else {
				this.$emit("showError", "Lista de prezenta nu a putut fi trimisa");
			}
		}
	}
});
