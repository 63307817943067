


















import Vue from "vue";
import DragDropUpload from "@/components/turn/admin/DragDropUpload.vue";
import * as XLSX from "xlsx";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

export default Vue.extend({
	name: "ExitList",
	components: {
		DragDropUpload
	},
	data () {
		const exitList: {date: string, licensePlate: string}[] = [];
		const exitListHeader: {
			text: string,
			align: string,
			value: string,
			sortable: boolean
		}[] = [
			{
				text: "Data",
				align: "center",
				value: "date",
				sortable: true
			},
			{
				text: "Numar de Inmatriculare",
				align: "center",
				value: "licensePlate",
				sortable: true
			}
		];

		return {
			uploaded: false,
			uploadTry: 0,
			exitList: exitList,
			exitListBinary: "",
			exitListHeader: exitListHeader
		};
	},
	methods: {
		upload (file: string) {
			// Remount the DragDropUpload component
			this.uploadTry++;
			this.uploaded = true;

			const workbook = XLSX.read(file, { type: "binary" });
			const sheet = workbook.Sheets[workbook.SheetNames[0]];
			const json: {date: string, licensePlate: string}[] = XLSX.utils.sheet_to_json(sheet, { raw: false });

			if (json.length === 0) {
				this.uploaded = false;
				return this.$emit("showError", "Lista de masini iesite din parcare este goala");
			} else if (json[0].date === undefined || json[0].licensePlate === undefined) {
				this.uploaded = false;
				return this.$emit("showError", "Lista de masini iesite din parcare nu are formatul corect");
			}

			this.exitList = json;
			this.exitListBinary = file;
		},
		reuploadExitList () {
			this.uploaded = false;
			this.exitList = [];
			this.exitListBinary = "";
		},
		async sendExitList () {
			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/reservations/parking/exit`,
				data: {
					data: this.exitListBinary,
					encoding: "binary"
				}
			};

			const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				this.$emit("showError", "Lista de masini iesite din parcare a fost trimisa cu succes", "success");
				this.reuploadExitList();
			} else {
				this.$emit("showError", "Lista de masini iesite din parcare nu a putut fi trimisa");
			}
		}
	}
});
