


















































































































import { Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import moment from "moment";
import { IGroup, NO_GROUP } from "@common/groups";
import { IReservedDesk } from "@common/reservations";
import { IUser } from "@common/users";
export default Vue.extend({
	name: "Manager",
	mounted () {
		this.fetchData();
	},
	methods: {
		async fetchData () {
			this.loading = true;

			const groups = await this.$store.dispatch("userStore/loadGroups", {
				role: "manager"
			});

			this.groups = groups.managerOf;
			this.selectedGroup = this.groups[0];

			// await this.fetchGroupData();

			this.loading = false;
		},
		removeFilters () {
			this.selectedFilters = {
				dateBegin: "",
				dateEnd: ""
			};
			this.selectedGroup = this.groups[0];
		},
		async fetchGroupData () {
			this.items = [];

			this.reservations = await this.$store.dispatch("deskStore/loadGroupReservations", {
				groupId: this.selectedGroup._id
			});
			this.users = await this.$store.dispatch("userStore/loadUsers", {
				groupId: this.selectedGroup._id
			});

			for (const user of this.users) {
				const userReservations = this.reservations.filter((reservation: IReservedDesk) => reservation.uuid === user.uuid);
				const userPresent = this.reservations.filter((reservation: IReservedDesk) => reservation.uuid === user.uuid && reservation.reservation_used === true);

				this.items.push({
					name: user.name,
					reserv: userReservations.length,
					present: userPresent.length,
					percent: (userReservations.length === 0 ? 0 : Math.round((userPresent.length / userReservations.length) * 100)) + "%"
				});
			}
		},
		createCSV () {
			let csvContent = "data:text/csv;charset=utf-8," + "Nume,Total Rezervari,Total Prezenta,Rata Prezenta\n";
			for (const item of this.items) {
				csvContent += `${item.name},${item.reserv}, ${item.present},${item.percent}\n`;
			}

			const url = encodeURI(csvContent);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "Manager.csv");
			document.body.appendChild(link);
			link.click();
		},
		disableOldDates (date: Date) {
			if (this.selectedFilters.dateBegin !== "") {
				if (moment(date).isBefore(this.selectedFilters.dateBegin)) {
					return false;
				}
			}
			return true;
		},
		disableFutureDates (date: Date) {
			if (this.selectedFilters.dateEnd !== "") {
				if (moment(date).isAfter(this.selectedFilters.dateEnd)) {
					return false;
				}
			}
			return true;
		}
	},
	computed: {
		...mapGetters({
			userState: "userStore/user"
		}),
		headers () {
			return [
				{
					text: "Nume",
					align: "center",
					value: "name"
				},
				{
					text: "Total Rezervări",
					align: "center",
					value: "reserv"
				},
				{
					text: "Total Prezență",
					align: "center",
					value: "present"
				},
				{
					text: "Rată Prezență",
					align: "center",
					value: "percent"
				}
			];
		},
		// dateRangeText (): string {
		// 	if (this.selectedFilters.dates[0]) {
		// 		return this.selectedFilters.dates
		// 			.map((date: string) => moment(date).format("DD.MM.YYYY"))
		// 			.join(" - ");
		// 	} else {
		// 		return "Selectează intervalul";
		// 	}
		// }
		dateBeginFormatted (): string {
			if (this.selectedFilters.dateBegin) {
				return moment(this.selectedFilters.dateBegin).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
		dateEndFormatted () : string {
			if (this.selectedFilters.dateEnd) {
				return moment(this.selectedFilters.dateEnd).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
	},
	data: () => {
		const items: {
			name: string;
			reserv: number;
			present: number;
			percent: string;
		}[] = [{
			name: "Ionel Ionut",
			reserv: 10,
			present: 5,
			percent: "50%"
		}];
		const selectedFilters: {
			dateBegin: string,
			dateEnd: string
		} = {
			dateBegin: "",
			dateEnd: ""
		};
		const reservations: IReservedDesk[] = [];
		const groups: IGroup[] = [];
		const selectedGroup: IGroup = NO_GROUP;
		const users: IUser[] = [];
		return {
			reservations: reservations,
			loading: false,
			dateMenu: false,
			selectedFilters: selectedFilters,
			dateMenuBegin: false,
			dateMenuEnd: false,
			groups: groups,
			selectedGroup: selectedGroup,
			items: items,
			users: users
		};
	},
	watch: {
		"selectedFilters.dateEnd" () {
			if (this.selectedFilters.dateBegin === "") {
				this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
			}

			if (this.selectedFilters.dateBegin !== "" && this.selectedFilters.dateEnd !== "") {
				if (
					moment(this.selectedFilters.dateBegin).isAfter(
						this.selectedFilters.dateEnd
					)
				) {
					const temp = this.selectedFilters.dateBegin;
					this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					this.selectedFilters.dateEnd = temp;
				}
				this.items = [];

				for (const user of this.users) {
					const userReservations = this.reservations.filter((reservation: IReservedDesk) => reservation.uuid === user.uuid && moment(reservation.reservationDate).isBetween(this.selectedFilters.dateBegin, this.selectedFilters.dateEnd));
					const userPresent = this.reservations.filter((reservation: IReservedDesk) => reservation.uuid === user.uuid && moment(reservation.reservationDate).isBetween(this.selectedFilters.dateBegin, this.selectedFilters.dateEnd) && reservation.reservation_used === true);

					this.items.push({
						name: user.name,
						reserv: userReservations.length,
						present: userPresent.length,
						percent: (userReservations.length === 0 ? 0 : Math.round((userPresent.length / userReservations.length) * 100)) + "%"
					});
				}
			}
		},
		"selectedFilters.dateBegin" () {
			if (this.selectedFilters.dateEnd === "") {
				this.selectedFilters.dateEnd = this.selectedFilters.dateBegin;
			}

			if (this.selectedFilters.dateBegin !== "" && this.selectedFilters.dateEnd !== "") {
				if (
					moment(this.selectedFilters.dateBegin).isAfter(
						this.selectedFilters.dateEnd
					)
				) {
					const temp = this.selectedFilters.dateBegin;
					this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					this.selectedFilters.dateEnd = temp;
				}
				this.items = [];

				for (const user of this.users) {
					const userReservations = this.reservations.filter((reservation: IReservedDesk) => reservation.uuid === user.uuid && moment(reservation.reservationDate).isBetween(this.selectedFilters.dateBegin, this.selectedFilters.dateEnd));
					const userPresent = this.reservations.filter((reservation: IReservedDesk) => reservation.uuid === user.uuid && moment(reservation.reservationDate).isBetween(this.selectedFilters.dateBegin, this.selectedFilters.dateEnd) && reservation.reservation_used === true);

					this.items.push({
						name: user.name,
						reserv: userReservations.length,
						present: userPresent.length,
						percent: (userReservations.length === 0 ? 0 : Math.round((userPresent.length / userReservations.length) * 100)) + "%"
					});
				}
			}
		},
		"selectedGroup" () {
			this.loading = true;
			this.fetchGroupData();
			this.loading = false;
		}
	}
});
