


























































































import { ICar } from "@common/cars";
import { BRDBuildings, IUser, NO_USER } from "@common/users";
import _ from "lodash";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "User",
	props: {
		user: {
			type: Object as () => IUser,
			required: true
		},
		cars: {
			type: Array as () => ICar[],
			required: true
		}
	},
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	data () {
		const selectedUser: IUser = NO_USER;
		const userCars: ICar[] = [];
		const userBuildings: BRDBuildings[] = [];
		const notUserBuildings: string[] = [];

		return {
			selectedUser,
			userCars,
			loading: false,
			userBuildings,
			allBuildings: ["CityOffices", "Turn"],
			notUserBuildings,
			addBuildingDialog: false,
			addUserBuilding: ""
		};
	},
	mounted () {
		this.selectedUser = _.cloneDeep(this.user);
		this.userCars = _.cloneDeep(this.cars);
		this.userCars = this.userCars.filter((car) => car.user_id === this.selectedUser.uuid);

		this.createBuildingList();
		this.$forceUpdate();
	},
	methods: {
		createBuildingList () {
			this.userBuildings = _.cloneDeep(this.selectedUser.buildingList);
			this.userBuildings.push(this.selectedUser.building);
			this.userBuildings.sort();

			// Add buildings that are not in the user buildings
			this.notUserBuildings = _.cloneDeep(this.allBuildings);
			this.notUserBuildings = this.userBuildings.map((building) => this.buildingToString(building)).reduce((acc, curr) => {
				acc.splice(acc.indexOf(curr), 1);
				return acc;
			}, this.notUserBuildings);
		},
		buildingToString (building: BRDBuildings) {
			switch (building) {
				case BRDBuildings.Flexioffice:
					return "CityOffices";
				case BRDBuildings.Turn:
					return "Turn";
				default:
					return "";
			}
		},
		removeBuilding (building: BRDBuildings) {
			if (this.selectedUser.buildingList.length === 0) {
				return this.$emit("showError", "Utilizatorul trebuie sa aiba cel putin o cladire!");
			}

			if (this.selectedUser.buildingList.includes(building)) {
				this.selectedUser.buildingList.splice(this.selectedUser.buildingList.indexOf(building), 1);
			} else if (this.selectedUser.building === building) {
				this.selectedUser.building = this.selectedUser.buildingList[0];
				this.selectedUser.buildingList.splice(0, 1);
			}

			this.createBuildingList();
			this.$forceUpdate();
		},
		addBuilding () {
			let building;

			switch (this.addUserBuilding) {
				case "CityOffices":
					building = BRDBuildings.Flexioffice;
					break;
				case "Turn":
					building = BRDBuildings.Turn;
					break;
				default:
					return this.$emit("showError", "Cladirea nu exista!");
			}

			if (this.selectedUser.buildingList.includes(building)) {
				return this.$emit("showError", "Utilizatorul are deja aceasta cladire!");
			}

			this.selectedUser.buildingList.push(building);
			this.createBuildingList();
			this.addBuildingDialog = false;
			this.$forceUpdate();
		},
		async resetUserPassword (user: IUser) {
			this.loading = true;

			const res = await this.$store.dispatch("userStore/randomPassword", {
				user,
				token: this.userState.token
			});

			this.loading = false;

			if (res) {
				this.$emit("showError", "Parola a fost resetată cu succes!", "success");
			} else {
				this.$emit("showError", "A apărut o eroare la resetarea parolei!");
			}
		},
		async deleteUser (user: IUser) {
			this.loading = true;

			const res = await this.$store.dispatch("userStore/delete", {
				user,
				token: this.userState.token
			});

			this.loading = false;

			if (res) {
				this.$emit("showError", "Utilizatorul a fost șters cu succes!", "success");
				this.$emit("fetchData", false);
				this.$emit("changePage", "usersList");
			} else {
				this.$emit("showError", "A apărut o eroare la ștergerea utilizatorului!");
			}

			this.loading = false;
		},
		async saveUser () {
			this.loading = true;

			const res = await this.$store.dispatch("userStore/update", {
				user: this.selectedUser,
				token: this.userState.token
			});

			if (res) {
				this.$emit("showError", "Utilizatorul a fost actualizat cu succes!", "success");
				this.$emit("fetchData", false);
			} else {
				this.$emit("showError", "A apărut o eroare la actualizarea utilizatorului!");
			}

			this.loading = false;
		},
		userIsFlexi () {
			return (this.selectedUser.buildingList && this.selectedUser.buildingList.includes(BRDBuildings.Flexioffice)) || this.selectedUser.building === BRDBuildings.Flexioffice;
		},
		addToFlexi () {
			this.selectedUser.buildingList.push(BRDBuildings.Flexioffice);
		}
	}
});
