


























































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import Conf from "@/components/flexioffice/Conf.vue";
import Desk from "@/components/flexioffice/Desk.vue";
import Map from "@/components/flexioffice/Map.vue";
import ConfList from "@/components/flexioffice/ConfList.vue";
import { IReservedConferenceRoom, IReservedDesk, NO_RESERVED_CONFERENCE_ROOM, NO_RESERVED_DESK, ReservationStatus } from "@common/reservations";
import { GroupType, IGroup } from "@common/groups";
import { DeskType, NO_DESK } from "@common/desks";
import { NO_CONFERENCE_ROOM } from "@common/conferenceRooms";
import { IConfig } from "@common/config";
import { IConferenceRoomState, IDeskState, IUserGroups, IUserState } from "@common/ui";
import { BRDBuildings, IUser, isUser } from "@common/users";

export default Vue.extend({
	name: "Flexioffice",
	components: {
		Desk,
		Conf,
		Map,
		ConfList
	},
	data () {
		const isConfirmed = true;
		const checkValidity = false;
		const deskDialog = false;
		const roomDialog = false;
		const reservedDialog = false;
		const anotherDeptDialog = false;
		const roomListDialog = false;
		const cancelDeskDialog = false;
		const cancelRoomDialog = false;
		const floor = "4";

		const auxDefaultDate = new Date();
		const weekDay = moment().isoWeekday();
		const plus = (weekDay === 6 || weekDay === 7) ? 8 - weekDay : 0;
		const day = auxDefaultDate.getDate();
		auxDefaultDate.setDate(day + plus);
		const auxDate = new Date();
		const month = auxDate.getMonth();
		auxDate.setMonth(month + 1);
		const maxDate = auxDate.toISOString().substr(0, 10);

		const roomReservations: IReservedConferenceRoom[] = [];
		const deskReservations: IReservedDesk[] = [];
		const groups: IGroup[] = [];
		const selectedGroup = "-1";
		const selectedGroupData: IGroup = { name: "Not loaded", type: GroupType.DEPARTAMENT, parkingSpots: 0, building: BRDBuildings.Flexioffice };
		const reservationDates: Date[] = [];

		const defaultDate = auxDefaultDate.toISOString().substr(0, 10);

		return {
			randomDeskError: false,
			isConfirmed: isConfirmed,
			date: defaultDate,
			roomReservations: roomReservations,
			deskReservations: deskReservations,
			selectedGroup: selectedGroup,
			selectedGroupData: selectedGroupData,
			checkValidity: checkValidity,
			floor: floor,
			maxRooms: 0,
			maxDesks: 0,
			reservedMentor: reservedDialog,
			reservedNotMentor: reservedDialog,
			deskNotAvailable: reservedDialog,
			reservedMaxDesk: reservedDialog,
			fullConferenceRoom: false,
			cannotReserve: false,
			anotherDept: anotherDeptDialog,
			roomReservedMessage: "",
			deskReservedMessage: "",
			deskItem: NO_DESK,
			loadingDesk: true,
			loadingRoom: true,
			cancelDeskItem: NO_RESERVED_DESK,
			cancelRoomItem: NO_RESERVED_CONFERENCE_ROOM,
			snackbox: false,
			snackboxMessage: "",
			snackboxColor: "",
			desk: deskDialog,
			room: roomDialog,
			roomList: roomListDialog,
			etaje: [
				{
					text: "Etajul 4",
					value: "4"
				},
				{
					text: "Etajul 5",
					value: "5"
				}
			],
			proiecte: groups,
			dateMenu: false,
			reservationDates: reservationDates,
			minDate: defaultDate,
			maxDate: maxDate,
			nrParking: 0,
			cancelDeskDialog: cancelDeskDialog,
			cancelRoomDialog: cancelRoomDialog,
			roomItem: NO_CONFERENCE_ROOM,
			maxDaysRoom: 0,
			maxDaysDesk: 0,
			amfiteatruDialog: false,
			showHelpDialog: false,
			mobileView: false,
		};
	},
	mounted () {
		this.fetchData();
	},
	watch: {
		userState: {
			immediate: true,
			async handler (newState: IUserState) {
				if (newState.userInfo.role && newState.userInfo.uuid && newState.userInfo.uuid !== "-1") {
					const auxProiecte: IUserGroups = await this.$store.dispatch("userStore/loadGroups", {
						role: newState.userInfo.role
					});
					// if (newState.userInfo.role === "manager" && auxProiecte.managerOf) {
					// 	this.proiecte = auxProiecte.managerOf;
					// } else {
						// --- For showing only the departments ---
					for (const group of auxProiecte.groupList) {
						if (group.type === "Departament") {
							this.proiecte.push(group);
						}
					}
					// this.proiecte = auxProiecte.groupList;
					// }
					const mainGroup = auxProiecte.mainGroup;
					if (mainGroup._id) {
						this.selectedGroup = mainGroup._id;
						this.selectedGroupData = mainGroup;
					} else if (auxProiecte.groupList.length > 0 && auxProiecte.groupList[0]._id) {
						this.selectedGroup = auxProiecte.groupList[0]._id;
						this.selectedGroupData = auxProiecte.groupList[0];
					} else {
						this.selectedGroup = "";
						this.selectedGroupData = { name: "Not Loaded", type: GroupType.DEPARTAMENT, parkingSpots: 0, building: BRDBuildings.Flexioffice };
					}
					await this.fetchData();
				}
			}
		},
		desksState: {
			immediate: true,
			async handler (newState: IDeskState[]) {
				try {
					if (newState.length > 0) {
						this.loadingDesk = true;
						this.floor = newState[0].deskId.split("-")[0][1];
						const reservationResponse:IReservedDesk[] = await this.$store.dispatch("deskStore/reservations", {
							token: this.userState.token,
							uuid: this.userState.userInfo.uuid
						});
						let aux:Date | string = new Date(this.date);
						aux.setHours(11, 0, 0, 0);
						aux = aux.toISOString();
						const existing = reservationResponse.find((r) => {
							return r.reservationDate.toString() === aux.toString();
						});
						if (existing) {
							this.checkValidity = true;
						} else {
							this.checkValidity = false;
						}
						if (reservationResponse) {
							this.deskReservations = reservationResponse;
							this.deskReservations.sort((a, b) => a.reservationDate > b.reservationDate ? 1 : -1);

							this.reservationDates = reservationResponse.map((v) => v.reservationDate);
						}
						// Subject to change
						if (newState.length > 0) {
							const modifiedState:IDeskState[] = [];
							for (const state of newState) {
								if (state.uuid !== "-1" && state.uuid !== "") {
									const user: IUser = await this.$store.dispatch("userStore/get", {
										token: this.userState.token,
										uuid: state.uuid
									});
									if (user !== undefined && isUser(user)) {
										(state as IDeskState & {name:string}).name = user.name;
									}
								}
								modifiedState.push(state);
							}
							this.$store.state.desksState = modifiedState;
						}
						// Subject to change
						this.$forceUpdate();
						this.loadingDesk = false;
					}
				} catch (error) {
					console.error("DeskState Error");
					console.error(error);
					this.loadingDesk = false;
				}
			}
		},
		roomsState: {
			immediate: true,
			async handler (newState: IConferenceRoomState[]) {
				try {
					if (newState.length > 0) {
						this.loadingRoom = true;
						const reservationResponse:IReservedConferenceRoom[] = await this.$store.dispatch("roomStore/reservations", {
							token: this.userState.token,
							uuid: this.userState.userInfo.uuid
						});
						if (reservationResponse) {
							this.roomReservations = reservationResponse;
							this.roomReservations.sort((a, b) => a.dateBegin > b.dateBegin ? 1 : -1);

							this.reservationDates = reservationResponse.map((v) => v.dateBegin);
						}
						this.loadingRoom = false;
					}
					this.$forceUpdate();
				} catch (error) {
					console.error("RoomState Error");
					console.error(error);
					this.loadingRoom = false;
				}
			}
		}
	},
	computed: {
		...mapGetters({
			userState: "userStore/user",
			desksState: "deskStore/desks",
			roomsState: "roomStore/rooms",
			roomsDetails: "roomStore/roomsDetails",
			configState: "configStore/configs",
		}),
		dateFormatted (): string {
			return moment(this.date).format("DD.MM.YYYY");
		}
	},
	methods: {
		hasTurnInBuildings () {
			if (this.userState.userInfo.building === BRDBuildings.Turn ||
				this.userState.userInfo.buildingList.includes(BRDBuildings.Turn) ||
				this.userState.userInfo.adminOf.length > 0) {
				return true;
			}
			return false;
		},
		async cancelDesk () {
			try {
				this.loadingDesk = true;
				this.deskReservations = this.deskReservations.filter((desk) => {
					return desk.deskId !== this.cancelDeskItem.deskId;
				});
				const resp = await this.$store.dispatch("deskStore/cancel", {
					token: this.userState.token,
					reservationEntry: this.cancelDeskItem
				});
				if (resp) {
					let aux:Date | string = new Date(this.date);
					aux.setHours(11, 0, 0, 0);
					aux = aux.toISOString();
					const loaded = await this.$store.dispatch("deskStore/load", {
						groupId: this.selectedGroup,
						date: aux
					});
					if (loaded) {
						this.loadingRoom = false;
					}

					this.createSnack({
						message: "Rezervarea a fost anulată",
						color: "success"
					});
				} else {
					console.error("Didn't cancel desk");
					this.loadingRoom = false;

					this.createSnack({
						message: "Rezervarea nu a putut fi anulată",
						color: "error"
					});
				}
				this.$forceUpdate();
				this.loadingRoom = false;
			} catch (error) {
				console.error(error);
				this.loadingRoom = false;
			}
		},
		async cancelRoom () {
			try {
				this.loadingRoom = true;
				this.roomReservations = this.roomReservations.filter((room) => {
					return room.conferenceRoomId !== this.cancelRoomItem.conferenceRoomId;
				});
				const resp = await this.$store.dispatch("roomStore/cancel", {
					token: this.userState.token,
					reservationEntry: this.cancelRoomItem
				});
				if (resp) {
					let aux:Date | string = new Date(this.date);
					aux.setHours(11, 0, 0, 0);
					aux = aux.toISOString();
					const loaded = await this.$store.dispatch("roomStore/load", {
						token: this.userState.token,
						groupId: this.selectedGroup,
						date: aux
					});
					if (loaded) {
						this.loadingRoom = false;
					}

					this.createSnack({
						message: "Rezervarea a fost anulată",
						color: "success"
					});
				} else {
					console.error("Didn't cancel room");
					this.loadingRoom = false;

					this.createSnack({
						message: "Rezervarea nu a putut fi anulată",
						color: "error"
					});
				}
				this.loadingRoom = false;
				this.$forceUpdate();
			} catch (error) {
				console.error(error);
				this.loadingRoom = false;
			}
		},
		createSnack (options:{message:string, color:string}) {
			this.snackbox = true;
			this.snackboxMessage = options.message;
			this.snackboxColor = options.color;
		},
		async fetchData () {
			try {
				if (this.selectedGroup && this.selectedGroup !== "-1") {
					let aux:Date | string = new Date(this.date);
					aux.setHours(11, 0, 0, 0);
					aux = aux.toISOString();
					await this.$store.dispatch("deskStore/load", {
						token: this.userState.token,
						groupId: this.selectedGroup,
						date: aux
					});
					await this.$store.dispatch("roomStore/load", {
						token: this.userState.token,
						groupId: this.selectedGroup,
						date: aux
					});
					await this.$store.dispatch("roomStore/loadDetails", {
						token: this.userState.token
					});

					await this.$store.dispatch("configStore/load");
					this.maxDaysDesk = parseInt(this.configState.find((config: IConfig) => config.name === "maxDaysDesk").value);
					this.maxDaysRoom = parseInt(this.configState.find((config: IConfig) => config.name === "maxDaysRoom").value);
					this.maxRooms = parseInt(this.configState.find((config: IConfig) => config.name === "maxActiveRoom").value);
					this.maxDesks = parseInt(this.configState.find((config: IConfig) => config.name === "maxActiveDesk").value);
				}

				this.proiecte.sort((a: IGroup, b: IGroup) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});

				await this.$forceUpdate();
			} catch (error) {
				console.error("fetchData Error");
				console.error(error);
			}
		},
		updateSelectedGroupData () {
			for (const proiect of this.proiecte) {
				if (proiect._id === this.selectedGroup) {
					this.selectedGroupData = proiect;
				}
			}
		},
		allowedDates (val:string) {
			return new Date(val).getDay() !== 0 && new Date(val).getDay() !== 6;
		},
		openRoomListDialog () {
			this.roomList = true;
			this.room = false;
			this.desk = false;
		},
		randomDeskReserve () {
			const freeDesks = this.desksState.filter((desk: IDeskState) => {
				return desk.status === ReservationStatus.FREE;
			});

			if (freeDesks.length) {
				this.openDeskDialog(freeDesks[Math.floor(Math.random() * freeDesks.length)]);
			} else {
				this.randomDeskError = true;
			}
		},
		async fetchParking (groupId: string, date: Date) {
			this.nrParking = await this.$store.dispatch("deskStore/getAvailableParking", {
				groupId: groupId,
				date: date
			});
		},
		getDataDesk (date:Date) {
			return moment(date).tz("Europe/Bucharest").format("DD.MM.YYYY");
		},
		canCancelDesk (desk: IReservedDesk) {
			return !moment(desk.reservationDate).subtract(1, "days").isBefore(moment());
		},
		openDeskCancelDialog (desk:IReservedDesk) {
			this.cancelDeskDialog = true;
			this.cancelDeskItem = desk;
		},
		async updateParking (reservation: IReservedDesk) {
			const updatedReservation: IReservedDesk = { ...reservation, parkingSpot: !reservation.parkingSpot };

			const response = await this.$store.dispatch("deskStore/updateParking", {
				desk: updatedReservation
			});
			if (response) {
				await this.fetchParking(reservation.groupId, reservation.reservationDate);
				await this.fetchData();
			}
		},
		getData (date:Date) {
			return moment(date).tz("Europe/Bucharest").format("DD.MM.YYYY [la] HH:mm");
		},
		openRoomCancelDialog (room:IReservedConferenceRoom) {
			this.cancelRoomDialog = true;
			this.cancelRoomItem = room;
		},
		closeDeskCancelDialog () {
			this.cancelDeskDialog = false;
			this.cancelDeskItem = NO_RESERVED_DESK;
		},
		closeRoomCancelDialog () {
			this.cancelRoomDialog = false;
			this.cancelRoomItem = NO_RESERVED_CONFERENCE_ROOM;
		},
		closeRoomDialog () {
			this.$forceUpdate();
			this.roomItem = NO_CONFERENCE_ROOM;
			this.room = false;
			this.desk = false;
		},
		closeDeskDialog () {
			this.$forceUpdate();
			this.deskItem = NO_DESK;
			this.room = false;
			this.desk = false;
		},
		async updateDate (actualDate: string) {
			await this.fetchData();
			this.date = actualDate;
		},
		closeRoomListDialog () {
			this.roomList = false;
		},
		openDeskDialog (desk:IDeskState) {
			this.deskItem = desk;

			if (desk.status === ReservationStatus.BOOKED) {
				if (desk.uuid === this.userState.userInfo.uuid) {
					this.deskReservedMessage = " tine";
					this.reservedNotMentor = true;
				} else if (this.userState.userInfo.role === "admin" || this.userState.userInfo.role === "manager") {
					this.cancelDeskItem = desk;
					this.deskReservedMessage = (desk as IDeskState & {name:string}).name;
					this.reservedMentor = true;
				} else {
					this.deskReservedMessage = (desk as IDeskState & {name:string}).name;
					this.reservedNotMentor = true;
				}
			} else if (this.deskReservations.length >= this.maxDesks) {
				this.reservedMaxDesk = true;
			} else if (desk.status === ReservationStatus.BUSY && (desk.manager !== this.userState.userInfo.uuid || desk.type !== DeskType.RESERVED)) {
				this.deskNotAvailable = true;
			} else {
				this.room = false;
				this.roomList = false;
				this.desk = true;
				this.$forceUpdate();
			}
		},
		openRoomDialog ({ room, status }:{ room: IConferenceRoomState, status: string }) {
			if (room.details.name === "AMFITEATRU") {
				this.amfiteatruDialog = true;
			}

			if (this.roomReservations.length < this.maxRooms || this.userState.userInfo.role === "admin") {
				if (status === "room_dot.png") {
					this.roomItem = room;
					this.room = true;
					this.desk = false;
					this.roomList = false;
				} else {
					this.fullConferenceRoom = true;
				}
			} else {
				this.cannotReserve = true;
			}
		},
		getDataReservation (date:string) {
			return moment(date).tz("Europe/Bucharest").format("DD.MM.YYYY [la] HH:mm");
		},
		checkIfCanReserve () {
			if (this.proiecte.length === 0) {
				return false;
			}
			if (this.selectedGroupData.name === "Centrala" || this.selectedGroupData.name === "Regiunea Bucuresti") {
				return false;
			}
			return true;
		},
		isMobile () {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true;
			} else {
				return false;
			}
		}
	}
});
