import { IUser } from "./users";

export enum ReservationStatus {
	FREE = "Liber",
	BOOKED = "Rezervat",
	BUSY = "Ocupat",
	CANCEL = "Anulat",
	NONE = ""
}

export interface IReservedDesk {
    _id?: string;
    creatorUuid: string;
    uuid: string;
    groupId: string;
    deskId: string;
	name: string;
    creationDate?: Date;
    reservationDate: Date;
    status: ReservationStatus;
    parkingSpot: boolean;
	userInfo?: IUser;
	creatorUserInfo?: IUser;
	reservation_used: boolean;
}

export const NO_RESERVED_DESK: IReservedDesk = {
	_id: "",
	creatorUuid: "",
	uuid: "",
	groupId: "",
	deskId: "",
	name: "",
	creationDate: new Date(),
	reservationDate: new Date(),
	status: ReservationStatus.NONE,
	parkingSpot: false,
	reservation_used: false
} 

export function isReservedDesk (obj: any, withId: boolean = false, withCreationDate: boolean = false): boolean {
	const isCreatorUuid = obj.creatorUuid !== undefined && obj.creatorUuid !== null;
	const isUserId = obj.uuid !== undefined && obj.uuid !== null;
	const isGroupId = obj.groupId !== undefined && obj.groupId !== null;
	const isDeskId = obj.deskId !== undefined && obj.deskId !== null;
	const isReservationDate = obj.reservationDate !== undefined && obj.reservationDate !== null;
	const isStatus = obj.status !== undefined && obj.status !== null;
	const isParkingSpot = obj.parkingSpot !== undefined && obj.parkingSpot !== null;
	const isName = obj.name !== undefined && obj.name !== null;
	
	let result = isCreatorUuid && isUserId && isGroupId && isDeskId && isReservationDate && isStatus && isParkingSpot && isName;

	if (withId) {
		const isId = obj._id !== undefined && obj._id !== null;

		result = result && isId;
	}

	if (withCreationDate) {
		const isCreationDate = obj.creationDate !== undefined && obj.creationDate !== null;

		result = result && isCreationDate;
	}

	return result;
}

export interface IReservedConferenceRoom {
    _id?: string;
    uuid: string;
    conferenceRoomId: string;
    creationDate?: Date;
    dateBegin: Date;
    dateEnd: Date;
    status: ReservationStatus;
	userInfo?: IUser;
}

export const NO_RESERVED_CONFERENCE_ROOM: IReservedConferenceRoom = {
	_id: "",
	uuid: "",
	conferenceRoomId: "",
	creationDate: new Date(),
	dateBegin: new Date(),
	dateEnd: new Date(),
	status: ReservationStatus.NONE
} 

export function isReservedConferenceRoom (obj: any, withId: boolean = false, withCreationDate: boolean = false): boolean {
	const isUserId = obj.uuid !== undefined && obj.uuid !== null;
	const isConferenceRoomId = obj.conferenceRoomId !== undefined && obj.conferenceRoomId !== null;
	const isDateBegin = obj.dateBegin !== undefined && obj.dateBegin !== null;
	const isDateEnd = obj.dateEnd !== undefined && obj.dateEnd !== null;
	const isStatus = obj.status !== undefined && obj.status !== null;

	let result = isUserId && isConferenceRoomId && isDateBegin && isDateEnd && isStatus;
	
	if (withId) {
		const isId = obj._id !== undefined && obj._id !== null;

		result = result && isId;
	}

	if (withCreationDate) {
		const isCreationDate = obj.creationDate !== undefined && obj.creationDate !== null;

		result = result && isCreationDate;
	}

	return result;
}

export interface IReservedDeskCSVEntry {
	creatorUser: string;
	user: string;
	createdDate: string;
	reservationDate: string;
	deskId: string;
	status: string;
}

export const EMPTY_CSV_ENTRY: IReservedDeskCSVEntry = {
	creatorUser: "",
	user: "",
	createdDate: "",
	reservationDate: "",
	deskId: "",
	status: ""
}

export interface IReservedDeskCSV extends Array<IReservedDeskCSVEntry>{};
