




























import { Vue } from "vue-property-decorator";
import AdminNavDrawer from "@/components/turn/admin/AdminNavDrawer.vue";
import AddNewUserTurn from "@/components/turn/admin/AddNewUserTurn.vue";
import ParamsAdminPage from "@/components/turn/admin/ParamsPage.vue";
import UsersList from "@/components/turn/admin/UsersList.vue";
import User from "@/components/turn/admin/User.vue";
import CarsList from "@/components/turn/admin/CarsList.vue";
import Car from "@/components/turn/admin/Car.vue";
import PresenceList from "@/components/turn/admin/PresenceList.vue";
import ExitList from "@/components/turn/admin/ExitList.vue";
import UploadUsersTurn from "@/components/turn/admin/UploadUsersTurn.vue";
import UploadPermanentCarList from "@/components/turn/admin/UploadPermanentCarList.vue";
// import Utils from "@/components/turn/admin/Utils.vue";
import { BRDBuildings, IUser, NO_USER, isUser } from "@common/users";
import { IBRDCredentials } from "@common/brdCredentials";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { mapGetters } from "vuex";
import { IConfig } from "@common/config";
import { ServerError } from "@common/errors";
import { IServerRES } from "@common/server";
import { ICar, NO_CAR, isCar } from "@common/cars";

export default Vue.extend({
	name: "AdminTurn",
	data () {
		const users: IUser[] = [];
		const cars: ICar[] = [];
		const params: IConfig[] = [];
		const selectedUser: IUser = NO_USER;
		const selectedCar: ICar = NO_CAR;

		return {
			users: users,
			cars: cars,
			snackbar: false,
			multiline: false,
			snackbarText: "",
			snackbarColor: "error",
			page: "",
			params: params,
			selectedUser: selectedUser,
			selectedCar: selectedCar,
			loading: false,
			key: 0
		};
	},
	computed: {
		...mapGetters({
			userState: "userStore/user"
		}),
	},
	components: {
		AdminNavDrawer,
		AddNewUserTurn,
		ParamsAdminPage,
		UsersList,
		User,
		CarsList,
		Car,
		PresenceList,
		ExitList,
		UploadUsersTurn,
		UploadPermanentCarList,
		// Utils,
	},
	methods: {
		changePage (page: string, params?: IUser | ICar) {
			this.page = page;

			if (this.page === "user" && params !== undefined && isUser(params)) {
				this.selectedUser = params;
			} else if (this.page === "car" && params !== undefined && isCar(params)) {
				this.selectedCar = params;
			}
		},
		async addNewUser (user: IUser, credentials: IBRDCredentials) {
			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/users/registerTurn`,
				data: {
					credentials: credentials,
					userInfo: user
				},
				headers: {
					Authorization: `Bearer ${this.userState.token}`
				}
			};

			const response = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				this.showError("Utilizatorul a fost adăugat cu succes!", "success");

				this.users = await this.$store.dispatch("userStore/loadAllUsers", { building: BRDBuildings.Turn });

				this.changePage("usersList");
			} else {
				this.showError("A apărut o eroare la adăugarea utilizatorului!");
			}
		},
		showError (error: string, color = "error") {
			this.snackbar = true;
			this.snackbarText = error;
			this.snackbarColor = color;
		},
		async fetchData (loader = true) {
			this.loading = loader;

			await this.$store.dispatch("configStore/load");
			this.users = await this.$store.dispatch("userStore/loadAllUsers", { building: BRDBuildings.Turn });
			this.cars = (await this.$store.dispatch("carsStore/fetch", { role: "admin" })).filter((car: ICar) => car.active === true);

			this.key++;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					url: `${this.$store.getters.API_URL}/config`
				};
				const response: AxiosResponse<IServerRES<IConfig[]>> = await axios(options);
				if (response.data.err === ServerError.NO_ERROR) {
					this.params = response.data.payload.filter((param: IConfig) => param.building === BRDBuildings.Turn);
				}
			} catch (error) {
				console.error(error);
			}

			this.loading = false;
			this.$forceUpdate();
		}
	},
	watch: {
		userState: {
			handler () {
				if (this.userState.userInfo.uuid !== "-1") {
					this.fetchData();
				}
			},
			immediate: true
		}
	}
});
