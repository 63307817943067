









































































































import { Vue } from "vue-property-decorator";
import { isUserState } from "@common/ui";
import { mapGetters } from "vuex";
export default Vue.extend({
	name: "Login",
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	mounted: async function () {
		if (isUserState(this.userState) && this.userState.token !== "") {
			const validToken:boolean = await this.$store.dispatch("userStore/verifyToken", { token: this.userState.token });
			if (validToken) {
				this.$router.push("/");
			}
		}

		if (this.$route.query.reset === "true") {
			this.resetedPassword = true;
		} else if (this.$route.query.toreset === "true") {
			this.toResetPassword = true;
		}
	},
	data () {
		return {
			username: "",
			password: "",
			inc: false,
			loading: true,
			showPassword: false,
			resetedPassword: false,
			toResetPassword: false
		};
	},
	methods: {
		async loginFunction ():Promise<void> {
			try {
				const response:string = await this.$store.dispatch("userStore/login", {
					username: this.username,
					password: this.password
				});
				if (response) {
					this.$router.push("/");
				} else {
					console.error("LOGIN FAILED");
					this.inc = true;
					setTimeout(() => {
						this.inc = false;
					}, 5000);
				}
			} catch (error) {
				console.error(error);
			}
		},
		stopLoading () {
			this.loading = false;
		},
		resetPassword () {
			this.$router.push("/reset-password");
		}
	}
});
