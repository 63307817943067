import Vue from "vue";
import Vuex from "vuex";
import deskStore from "./deskStore";
import roomStore from "./roomStore";
import userStore from "./userStore";
import configStore from "./configStore";
import parkingStore from "./parkingStore";
import carsStore from "./carsStore";

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		version: "1.1.5",
		API_URL: "https://brd.flexioffice.ro/api"
	},
	getters: {
		API_URL: state => state.API_URL,
		version: state => state.version
	}
});

store.registerModule("deskStore", deskStore());
store.registerModule("roomStore", roomStore());
store.registerModule("userStore", userStore());
store.registerModule("configStore", configStore());
store.registerModule("parkingStore", parkingStore());
store.registerModule("carsStore", carsStore());

export default store;
