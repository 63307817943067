
























































































import { Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { NO_RESERVED_DESK, ReservationStatus } from "@common/reservations";
import { NO_DESK } from "@common/desks";
import moment from "moment";
export default Vue.extend({
	name: "Confirmation",
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	async mounted () {
		try {
			if (this.userState.token !== "") {
				await this.checkReservation();
			} else {
				// console.log("notLoggedIN");
				this.loggedIn = false;
				// TODO FORCE LOGIN
			}
		} catch (error) {
			console.error(error);
		}
	},
	watch: {
		loggedIn: {
			immediate: false,
			handler (newLogging) {
				// console.log("NewLogging");
				if (newLogging) {
					this.checkReservation();
				}
			}
		}
	},
	data () {
		const res = { ...NO_DESK, ...NO_RESERVED_DESK };
		return {
			currentReservation: res,
			username: "",
			password: "",
			inc: false,
			loggedIn: true
		};
	},
	methods: {
		moment (value:string) {
			return moment(value);
		},
		getData (data:string) {
			return moment(data).locale("ro").subtract(moment.duration("03:00:00")).calendar();
		},
		async confirmReservation () {
			try {
				this.currentReservation.status = ReservationStatus.BUSY;
				const response = await this.$store.dispatch("deskStore/updateReservedDesk", { desk: this.currentReservation });
				// console.log(response);
				if (response) {
					await this.$store.dispatch("deskStore/load");
					this.$router.push("/flexioffice");
				} else {
					// TODO ADD ERR
				}
			} catch (error) {
				console.error(error);
				this.$router.push("/flexioffice");
			}
		},
		dashboard () {
			this.$router.push("/flexioffice");
		},
		async checkReservation ():Promise<void> {
			try {
				const reservationId = this.$route.params.reservationId;
				if (reservationId !== undefined && reservationId !== "") {
					const reservation = await this.$store.dispatch("deskStore/fetch", { reservationId });
					if (reservation && reservation._id !== undefined && reservation._id !== "") {
						if (reservation.uuid === this.userState.userInfo.uuid && reservation.status !== ReservationStatus.BUSY) {
							this.currentReservation = reservation;
						} else {
							// console.log("NOT THE USER");
							this.$router.push("/flexioffice");
						}
					} else {
						// console.log("NO RESERVATION");
						// TODO ADD ERROR
						this.$router.push("/flexioffice");
					}
				} else {
					// console.log("NOID");
					this.$router.push("/flexioffice");
				}
			} catch (error) {
				console.error(error);
			}
		}
	},
});
