












































import axios, { AxiosResponse } from "node_modules/axios";
import { ReservationData, isReservationData } from "@common/ui";
import Vue from "vue";
import { IServerRES } from "@common/server";

export default Vue.extend({
	name: "Reservations",
	async created () {
		this.interval = setInterval(async () => {
			try {
				const succ = "success";
				const err = "error";

				const response:AxiosResponse<IServerRES<ReservationData>> = await axios.get("/reservations/status");
				if (isReservationData(response.data.payload)) {
					const tempData = response.data.payload;
					if (tempData.reservedOffices > this.actualData.reservedOffices) {
						this.cOO = succ;
						this.cOE = err;
					}
					if (tempData.reservedConfRooms > this.actualData.reservedConfRooms) {
						this.cMO = succ;
						this.cME = err;
					}
					this.actualData = tempData;
				}
			} catch (error) {
				console.error(error);
			}
		}, 5 * 60 * 1000);
	},
	beforeDestroy () {
		if (this.interval !== -1) {
			clearInterval(this.interval);
		}
	},
	data: () => {
		const defaultColor = "black";
		const actualData:ReservationData = {
			reservedOffices: 0,
			reservedConfRooms: 0,
			emptyOffices: 0,
			emptyConfRooms: 0,
		};
		return {
			interval: -1,
			actualData: actualData,
			cOO: defaultColor,
			cOE: defaultColor,
			cMO: defaultColor,
			cME: defaultColor,
		};
	}
});
