














































import { BRDBuildings } from "@common/users";
import { Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
export default Vue.extend({
	name: "Login",
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	mounted () {
		if (this.userState.userInfo.buildingList.length === 0 && this.userState.userInfo.adminOf.length === 0) {
			if (this.userState.userInfo.building === BRDBuildings.Flexioffice) {
				this.$router.push("/flexioffice");
			} else if (this.userState.userInfo.building === BRDBuildings.Turn) {
				this.$router.push("/turn/add");
			} else {
				this.$router.push("/flexioffice");
			}
		}
	},
});
