



































































































import { Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { IParkingReservationType, NO_PARKING_RESERVATION } from "@common/parkingReservations";
export default Vue.extend({
	name: "Confirmation",
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	async mounted () {
		try {
			if (this.userState.userInfo.uuid !== "-1") {
				await this.checkReservation();
			} else {
				// console.log("notLoggedIN");
				this.loggedIn = false;
				// TODO FORCE LOGIN
			}
		} catch (error) {
			console.error(error);
		}
	},
	watch: {
		loggedIn: {
			immediate: false,
			handler (newLogging) {
				// console.log("NewLogging");
				if (newLogging) {
					this.checkReservation();
				}
			}
		}
	},
	data () {
		const res = { ...NO_PARKING_RESERVATION };
		return {
			currentReservation: res,
			type: "desk",
			username: "",
			password: "",
			inc: false,
			loggedIn: true,
			roomName: "",
			showPassword: false
		};
	},
	methods: {
		moment (value:string) {
			return moment(value);
		},
		getData (data:string) {
			return moment(data).subtract(moment.duration("03:00:00")).format("DD.MM.YYYY");
		},
		async cancelReservation () {
			const reservationId = this.$route.params.reservationId;

			const status = await this.$store.dispatch("parkingStore/delete", { parkingReservationId: reservationId });
			if (status) {
				this.$router.push("/turn/add");
			}
		},
		dashboard () {
			this.$router.push("/turn/add");
		},
		async checkReservation ():Promise<void> {
			try {
				const reservationId = this.$route.params.reservationId;
				if (reservationId !== undefined && reservationId !== "") {
					const reservation = await this.$store.dispatch("parkingStore/getReservation", { reservationId });

					if (reservation && reservation._id !== undefined && reservation._id !== "") {
						if (reservation.user_id === this.userState.userInfo.uuid) {
							this.currentReservation = reservation;
						} else {
							// console.log(reservation);
							// console.log(this.userState.userInfo);
							// console.log("NOT THE USER");
							this.$router.push("/turn/add");
						}
					} else {
						// console.log("NO RESERVATION");
						this.$router.push("/turn/add");
					}
				} else {
					// console.log("NOID");
					this.$router.push("/turn/add");
				}
			} catch (error) {
				console.error(error);
			}
		},
		async loginFunction ():Promise<void> {
			try {
				const response:string = await this.$store.dispatch("userStore/login", {
					username: this.username,
					password: this.password
				});
				if (response) {
					this.loggedIn = true;
				} else {
					console.error("LOGIN FAILED");
					this.inc = true;
					setTimeout(() => {
						this.inc = false;
					}, 5000);
				}
			} catch (error) {
				console.error(error);
			}
		},
		canCancelReservation () {
			if (!(this.currentReservation.type === IParkingReservationType.FIRST_ROUND_RESERVED || this.currentReservation.type === IParkingReservationType.SECOND_ROUND_RESERVED || this.currentReservation.type === IParkingReservationType.IN_QUEUE)) {
				return false;
			}

			if (moment(this.currentReservation.reservation_date).subtract(1, "day").set({ hour: 18, minute: 0, second: 0 }).isBefore(moment())) {
				return false;
			}

			return true;
		}
	},
});
