






































import Vue from "vue";
import DragDropUpload from "@/components/turn/admin/DragDropUpload.vue";
import * as XLSX from "xlsx";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IUser } from "@common/users";
import { ConfigType, IConfig } from "@common/config";

export default Vue.extend({
	name: "UploadPermanentCarList",
	components: {
		DragDropUpload
	},
	props: {
		params: {
			type: Array as () => IConfig[],
			required: true
		}
	},
	data () {
		const carsList: {nrInmatriculare: string, marca: string}[] = [];
		const carsListHeader: {
			text: string,
			align: string,
			value: string,
			sortable: boolean
		}[] = [
			{
				text: "Numar de Inmatriculare",
				align: "center",
				value: "nrInmatriculare",
				sortable: true
			},
			{
				text: "Marca",
				align: "center",
				value: "marca",
				sortable: true
			}
		];

		return {
			uploaded: false,
			uploadTry: 0,
			carsList: carsList,
			carsListBinary: "",
			carsListHeader: carsListHeader,
			loading: false,
			showWarningDialog: false,
			maxParking: 0,
			carsInList: 0
		};
	},
	methods: {
		upload (file: string) {
			// Remount the DragDropUpload component
			this.uploadTry++;
			this.uploaded = true;

			const workbook = XLSX.read(file, { type: "binary" });
			const sheet = workbook.Sheets[workbook.SheetNames[0]];
			const json: {nrInmatriculare: string, marca: string}[] = XLSX.utils.sheet_to_json(sheet, { raw: false });

			if (json.length === 0) {
				this.uploaded = false;
				return this.$emit("showError", "Lista de vehicule cu acces permanent este goala");
			} else if (json[0].nrInmatriculare === undefined || json[0].marca === undefined) {
				this.uploaded = false;
				return this.$emit("showError", "Lista de vehicule cu acces permanent nu are formatul corect");
			}

			const maxAccess = this.params.find((param) => param.name === ConfigType.MAX_PERMANENT_ACCESS_CARS);
			if (maxAccess !== undefined) {
				if (parseInt(maxAccess.value) < json.length) {
					this.maxParking = parseInt(maxAccess.value);
					this.carsInList = json.length;
					this.showWarningDialog = true;
				}
			}

			this.carsList = json;
			this.carsListBinary = file;
		},
		reuploadCarsList () {
			this.uploaded = false;
			this.carsList = [];
			this.carsListBinary = "";
		},
		async sendCarsList () {
			this.loading = true;

			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/cars/massAdd`,
				data: {
					data: this.carsListBinary,
					encoding: "binary"
				}
			};

			const response: AxiosResponse<IServerRES<IUser[]>> = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				this.$emit("showError", "Lista de vehicule cu acces permanent a fost trimisa cu succes", "success");
				this.$emit("fetchData", false);
				this.$emit("changePage", "carsList");
			} else {
				this.$emit("showError", "Lista de vehicule cu acces permanent nu a putut fi trimisa");
			}

			this.loading = false;
		}
	}
});
