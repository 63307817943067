
























































































































































import { IUserState } from "@common/ui";
import { IReservedConferenceRoom } from "@common/reservations";
import Vue from "vue";
import { IConferenceRoom } from "@common/conferenceRooms";
import moment from "moment-timezone";

export default Vue.extend({
	name: "Conf",
	props: {
		userState: {
			type: Object as () => IUserState
		},
		roomItem: {
			type: Object as () => IConferenceRoom
		},
		selectedGroup: {
			type: String,
			default: () => "-1"
		},
		date: {
			type: String
		},
		reservationDates: {
			type: Array
		},
		maxDays: {
			type: Number,
			default: () => 0
		}
	},
	computed: {
		dateBeginFormatted (): string {
			return moment(this.dateBegin).format("DD.MM.YYYY");
		},
		dateEndFormatted () : string {
			return moment(this.dateEnd).format("DD.MM.YYYY");
		},
		featuresObj (): {
			Tip?: string,
			TV?: string,
			"Sistem Audio-Video"?: string,
			Telefon?: string,
			"Nr. Telefon"?: string
			} {
			if (this.roomItem.details.type) {
				const hasTV = (this.roomItem.details.hasTV) ? "Da" : "Nu";
				const hasAudioVideo = (this.roomItem.details.hasAudioVideo) ? "Da" : "Nu";
				const hasPhone = (this.roomItem.details.hasPhone) ? "Da" : "Nu";
				if (this.roomItem.details.phoneNo) {
					return {
						Tip: this.roomItem.details.type,
						TV: hasTV,
						"Sistem Audio-Video": hasAudioVideo,
						Telefon: hasPhone,
						"Nr. Telefon": this.roomItem.details.phoneNo
					};
				}
				return {
					Tip: this.roomItem.details.type,
					TV: hasTV,
					"Sistem Audio-Video": hasAudioVideo,
					Telefon: hasPhone
				};
			}
			return {};
		}
	},
	watch: {
		roomItem: {
			immediate: true,
			handler (newRoomItem: (IConferenceRoom & {reservations:IReservedConferenceRoom[]})) {
				this.reservations = [];
				for (const res of newRoomItem.reservations) {
					const dBegin = res.dateBegin;
					const dEnd = res.dateEnd;
					const dBeginMom = moment(dBegin);
					const dEndMom = moment(dEnd);
					const dayBegin = moment(res.dateBegin).toISOString().substring(0, 10);
					const dayEnd = moment(res.dateEnd).toISOString().substring(0, 10);
					this.reservations.push({
						hBegin: ((dBeginMom.hour()) < 10 ? "0" + (dBeginMom.hour()) : (dBeginMom.hour())).toString(),
						mBegin: (dBeginMom.minutes() === 0 ? "00" : "30"),
						hEnd: ((dEndMom.hour()) < 10 ? "0" + (dEndMom.hour()) : (dEndMom.hour())).toString(),
						mEnd: (dEndMom.minutes() === 0 ? "00" : "30"),
						dayBegin: dayBegin,
						dayEnd: dayEnd
					});
					const sameDayEnd = (
						dEndMom.isSame(this.dateBegin, "year") &&
						dEndMom.isSame(this.dateBegin, "month") &&
						dEndMom.isSame(this.dateBegin, "day")
					);
					if (sameDayEnd) {
						const h1Aux = dBeginMom.hours() - 3;
						const h2Aux = dEndMom.hours() - 3;
						const hAux = h2Aux - h1Aux;
						if (hAux >= 12.5) {
							const auxDate = new Date(this.dateBegin);
							const day = auxDate.getDay();
							auxDate.setDate(day + 1);
							this.dateBegin = auxDate.toISOString().substr(0, 10);
							this.minDateBegin = this.dateBegin;
						}
					} else {
						const auxDate = new Date(dEnd);
						const h1Aux = dBeginMom.hours() - 3;
						const h2Aux = dEndMom.hours() - 3;
						const hAux = h2Aux - h1Aux;
						if (hAux < 12.5) {
							this.dateBegin = auxDate.toISOString().substr(0, 10);
						} else {
							const day = auxDate.getDay();
							auxDate.setDate(day + 1);
							this.dateBegin = auxDate.toISOString().substr(0, 10);
							this.minDateBegin = this.dateBegin;
						}
					}
				}
				this.reservations.sort((a, b) => a.dayBegin + a.hBegin + a.mBegin > b.dayBegin + b.hBegin + b.mBegin ? 1 : -1);
				this.checkValidity();
			}
		},
		dateBegin: {
			immediate: true,
			handler (newDate) {
				const auxDate = new Date(newDate);
				this.minDate = auxDate.toISOString().substring(0, 10);
				this.maxDate = moment(this.minDate).add(this.maxDays, "days").toISOString().substring(0, 10);
				if (moment(newDate).isAfter(this.dateEnd)) {
					this.dateEnd = newDate;
				}

				if (moment(this.dateEnd).isAfter(moment(this.maxDate))) {
					this.dateEnd = this.maxDate;
				}

				if (newDate === this.dateEnd) {
					if (this.hourEnd < this.hourBegin) {
						this.hourEnd = this.hourBegin;
					}

					if (this.hourEnd === this.hourBegin && this.minuteEnd === this.minuteBegin) {
						this.minutesEnd = ["30"];
						this.minuteEnd = "30";
					} else if (this.hourEnd === this.hourBegin && this.minuteEnd < this.minuteBegin) {
						this.minuteEnd = "00";
						this.minutesEnd = ["00", "30"];

						this.hourEnd = "0" + (parseInt(this.hourBegin) + 1).toString();
					}
				}

				this.checkValidity();
				// this.$forceUpdate();
			}
		},
		dateEnd: {
			immediate: true,
			handler (newDate) {
				if (moment(newDate).isAfter(this.dateBegin)) {
					this.hourEnd = "08";

					if (this.hourEnd === "08") {
						this.minutesEnd = ["00", "30"];
					}

					this.hoursEnd = JSON.parse(JSON.stringify(this.hoursBegin));
				} else {
					if (this.hourEnd === "08") {
						this.minutesEnd = ["30"];
					}
				}
				this.checkValidity();
				// this.$forceUpdate();
			}
		},
		hourBegin: {
			immediate: true,
			handler (newHour) {
				if (this.hourBegin === "00") {
					this.minuteBegin = "00";
					this.minuteEnd = "30";
					this.hourEnd = newHour;
				}

				if (this.dateBegin === this.dateEnd) {
					let auxHours:string[] = JSON.parse(JSON.stringify(this.hoursBegin));
					if (parseInt(newHour) > parseInt(this.hourEnd)) {
						if (this.minuteBegin === "30") {
							if (parseInt(newHour) < 9) {
								this.hourEnd = "0" + (parseInt(newHour) + 1).toString();
							} else {
								this.hourEnd = (parseInt(newHour) + 1).toString();
							}
							auxHours = auxHours.filter((hour) => {
								return !(parseInt(hour) <= parseInt(newHour));
							});
							this.minutesEnd = ["00", "30"];
						} else {
							auxHours = auxHours.filter((hour) => {
								return !(parseInt(hour) < parseInt(newHour));
							});
							this.hourEnd = newHour;
							this.minutesEnd = ["30"];
							if (this.minuteEnd === "00") {
								this.minuteEnd = "30";
							}
						}
					} else if (parseInt(newHour) === parseInt(this.hourEnd)) {
						if (this.minuteBegin === "30") {
							if (parseInt(newHour) < 9) {
								this.hourEnd = "0" + (parseInt(newHour) + 1).toString();
							} else {
								this.hourEnd = (parseInt(newHour) + 1).toString();
							}
							auxHours = auxHours.filter((hour) => {
								return !(parseInt(hour) <= parseInt(newHour));
							});
							this.minuteEnd = "00";
							this.minutesEnd = ["00", "30"];
						} else {
							auxHours = auxHours.filter((hour) => {
								return !(parseInt(hour) < parseInt(newHour));
							});
							if (this.minuteEnd === "00") {
								this.minuteEnd = "30";
							}
							this.minutesEnd = ["30"];
						}
					} else {
						auxHours = auxHours.filter((hour) => {
							return !(parseInt(hour) < parseInt(newHour));
						});
						this.minutesEnd = ["00", "30"];
					}
					this.hoursEnd = auxHours;
				}
				this.checkValidity();
				this.$forceUpdate();
			}
		},
		hourEnd: {
			immediate: true,
			handler (newHour) {
				// ora de final poate fi doar egala sau mai mare decat ora de inceput
				if (parseInt(newHour) === parseInt(this.hourBegin)) {
					if (this.minuteBegin === "00") {
						this.minuteEnd = "30";
					}
					this.minutesEnd = ["30"];
				} else {
					this.minutesEnd = ["00", "30"];
				}
				this.checkValidity();
				this.$forceUpdate();
			}
		},
		minuteBegin: {
			immediate: true,
			handler (newMinute) {
				if (this.dateBegin === this.dateEnd) {
					let auxHours:string[] = JSON.parse(JSON.stringify(this.hoursBegin));
					if (newMinute === "30") {
						// ora de inceput poate fi doar egala sau mai mica decat ora de final
						if (parseInt(this.hourBegin) === parseInt(this.hourEnd)) {
							if (parseInt(this.hourBegin) < 9) {
								this.hourEnd = "0" + (parseInt(this.hourBegin) + 1).toString();
							} else {
								this.hourEnd = (parseInt(this.hourBegin) + 1).toString();
							}
							auxHours = auxHours.filter((hour) => {
								return !(parseInt(hour) <= parseInt(this.hourBegin));
							});
							this.minutesEnd = ["00", "30"];
						} else {
							this.minutesEnd = ["00", "30"];
							auxHours = auxHours.filter((hour) => {
								return !(parseInt(hour) <= parseInt(this.hourBegin));
							});
						}
					} else {
						// ora de inceput poate fi doar egala sau mai mica decat ora de final
						if (parseInt(this.hourBegin) === parseInt(this.hourEnd)) {
							auxHours = auxHours.filter((hour) => {
								return !(parseInt(hour) < parseInt(this.hourBegin));
							});
							this.minuteEnd = "30";
							this.minutesEnd = ["30"];
						} else {
							this.minutesEnd = ["00", "30"];
							auxHours = auxHours.filter((hour) => {
								return !(parseInt(hour) < parseInt(this.hourBegin));
							});
						}
					}
					this.hoursEnd = auxHours;
				}
				this.checkValidity();
				this.$forceUpdate();
			}
		},
		minuteEnd: {
			immediate: true,
			handler () {
				this.checkValidity();
			}
		}
	},
	data () {
		const auxDefaultDate = new Date(this.date);
		const weekDay = moment().isoWeekday();
		const plus = (weekDay === 6 || weekDay === 7) ? 8 - weekDay : 0;
		const day = auxDefaultDate.getDate();
		auxDefaultDate.setDate(day + plus);
		const minDate = new Date().toISOString().substring(0, 10);
		const maxDate = moment(minDate).add(this.maxDays, "days").toISOString().substring(0, 10);
		const minutes = [
			"00",
			"30"
		];
		const hours = [
			"08",
			"09",
			"10",
			"11",
			"12",
			"13",
			"14",
			"15",
			"16",
			"17",
			"18",
			"19",
			"20",
		];
		const reservs:{
			hBegin: string,
			mBegin: string,
			hEnd: string,
			mEnd: string,
			dayBegin: string,
			dayEnd: string
		}[] = [];
		const featuresHeader: {
			text: string,
			align: string,
			value: string,
			sortable: boolean
		}[] = [
			{
				text: "",
				align: "center",
				value: "features",
				sortable: true
			},
			{
				text: "",
				align: "center",
				value: "hasFeatures",
				sortable: true
			}
		];
		return {
			reservations: reservs,
			minutesBegin: minutes,
			hoursBegin: hours,
			minutesEnd: minutes,
			hoursEnd: hours,
			dateMenuBegin: false,
			dateMenuEnd: false,
			dateBegin: auxDefaultDate.toISOString().substring(0, 10),
			dateEnd: auxDefaultDate.toISOString().substring(0, 10),
			hourBegin: "08",
			minuteBegin: "00",
			hourEnd: "08",
			minuteEnd: "30",
			minDateBegin: minDate,
			minDate: minDate,
			maxDate: maxDate,
			validReservation: true,
			showFeatures: false,
			featuresHeader: featuresHeader,
			isFeaturesBtnHovered: false,
			snackMessage: "",
			snackColor: "",
			reserving: false,
			loading: false
		};
	},
	methods: {
		setIsFeaturesBtnHovered () {
			this.isFeaturesBtnHovered = true;

			setTimeout(() => {
				this.isFeaturesBtnHovered = false;
			}, 2500);
		},
		getDDMM (val: string) {
			return moment(val).tz("Europe/Bucharest").format("DD/MM");
		},
		getHHmm (val: string) {
			return moment(val).tz("Europe/Bucharest").format("HH:mm");
		},
		allowedDates (val:string) {
			return new Date(val).getDay() !== 0 && new Date(val).getDay() !== 6;
		},
		checkValidity () {
			let valid = true;

			for (const reservation of this.reservations) {
				const reservationBeginTs = moment(reservation.dayBegin + " " + reservation.hBegin + ":" + reservation.mBegin, "YYYY-MM-DD HH:mm").format("X");
				const reservationEndTs = moment(reservation.dayEnd + " " + reservation.hEnd + ":" + reservation.mEnd, "YYYY-MM-DD HH:mm").format("X");

				const beginTs = moment(this.dateBegin + " " + this.hourBegin + ":" + this.minuteBegin, "YYYY-MM-DD HH:mm").format("X");
				const endTs = moment(this.dateEnd + " " + this.hourEnd + ":" + this.minuteEnd, "YYYY-MM-DD HH:mm").format("X");

				if (beginTs <= reservationEndTs && endTs >= reservationBeginTs) {
					valid = false;
				}
			}

			this.validReservation = valid;
		},
		async reserveRoom () {
			this.reserving = true;

			try {
				this.$emit("load-map", true);
				const beginDate = moment.tz(this.dateBegin + "T" + this.hourBegin + ":" + this.minuteBegin, "Europe/Bucharest").utc();
				const endDate = moment.tz(this.dateEnd + "T" + this.hourEnd + ":" + this.minuteEnd, "Europe/Bucharest").utc();
				const resp = await this.$store.dispatch("roomStore/reserve", {
					token: this.userState.token,
					roomEntry: this.roomItem,
					dateBegin: beginDate,
					dateEnd: endDate,
					uuid: this.userState.userInfo.uuid
				});
				if (resp) {
					let aux:Date | string = new Date(this.date);
					// aux.setHours(0, 0, 0, 0);
					aux = aux.toISOString();
					const loaded = await this.$store.dispatch("roomStore/load", {
						token: this.userState.token,
						groupId: this.selectedGroup,
						date: aux
					});

					this.snackMessage = "Rezervarea a fost efectuată cu succes";
					this.snackColor = "success";

					if (loaded) {
						this.$emit("load-map", false);
					}
					this.$emit("load-map", false);
				} else {
					console.error("Didn't update");

					let aux:Date | string = new Date(this.date);
					// aux.setHours(0, 0, 0, 0);
					aux = aux.toISOString();

					await this.$store.dispatch("roomStore/load", {
						token: this.userState.token,
						groupId: this.selectedGroup,
						date: aux
					});

					this.$emit("load-map", false);

					this.snackMessage = "A apărut o eroare la rezervare";
					this.snackColor = "error";
				}
			} catch (error) {
				console.error(error);
				this.$emit("load-map", false);
			}

			this.$emit("createSnack", { message: this.snackMessage, color: this.snackColor });
			this.close();
		},
		async fetchData () {
			this.loading = true;

			try {
				if (this.selectedGroup && this.selectedGroup !== "-1") {
					let aux:Date | string = new Date(this.date);
					// aux.setHours(0, 0, 0, 0);
					aux = aux.toISOString();
					await this.$store.dispatch("roomStore/load", {
						token: this.userState.token,
						date: aux,
						groupId: this.selectedGroup
					});
					// if (this.userState.userInfo.role === "manager" || this.userState.userInfo.role === "admin") {
					// 	const users:IUser[] = await this.$store.dispatch("userStore/loadUsers", {
					// 		token: this.userState.token,
					// 		groupId: this.selectedGroup
					// 	});
					// 	if (users.length > 0) {
					// 		this.users = users;
					// 		this.users.push(NO_USER);
					// 	}
					// }
				}

				await this.$forceUpdate();
			} catch (error) {
				console.error("fetchData Error");
				console.error(error);
			}

			this.loading = false;
		},
		updateDate (date: string) {
			if (date === "end") {
				this.$emit("updateDate", this.dateEnd);
			} else {
				this.$emit("updateDate", this.dateBegin);
			}
		},
		close () {
			this.$emit("close-room-dialog");
		},
		hasFeatures () : boolean {
			if (Object.keys(this.featuresObj).length === 0) {
				return false;
			}
			return true;
		}
	}
});
