


































































import { Vue } from "vue-property-decorator";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
export default Vue.extend({
	name: "ResetPassword",
	mounted: async function () {
		return null;
	},
	data () {
		return {
			loading: false,
			password: "",
			repeatPassword: "",
			error: false,
			errorMessage: "",
			showPassword1: false,
			showPassword2: false,
		};
	},
	methods: {
		async resetPassword () {
			this.loading = true;

			if (this.password !== this.repeatPassword) {
				this.loading = false;
				this.error = true;
				this.errorMessage = "Parolele nu coincid!";

				return;
			}

			if (this.password.length < 6) {
				this.loading = false;
				this.error = true;
				this.errorMessage = "Parola trebuie să conțină minim 6 caractere!";

				return;
			}

			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/users/resetPassword`,
				data: {
					password: this.password,
					token: this.$route.params.token
				}
			};

			const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

			if (response.data.err === 0) {
				this.$router.push({ path: "/login", query: { reset: "true" } });
			} else {
				this.loading = false;
				this.error = true;
				this.errorMessage = "S-a produs o eroare!";
			}
		}
	}
});
