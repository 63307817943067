import Vue from "vue";
import Vuex, { Module } from "vuex";
import { IParkingReservation, IParkingReservationType, NO_PARKING_RESERVATION } from "@common/parkingReservations";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { RootState } from "@common/ui";

Vue.use(Vuex);
interface State {
	parkings: IParkingReservation[]
}
const parkings: IParkingReservation[] = [];

export default function parkingStore ():Module<State, RootState> {
	const store: Module<State, RootState> = {
		namespaced: true,
		state: {
			parkings: parkings
		},
		getters: {
			parkings: state => state.parkings
		},
		mutations: {
			parkings: (state, newParkings: IParkingReservation[]) => {
				state.parkings = newParkings;
			}
		},
		actions: {
			async load (storeParam): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/parking`
					};
					const response: AxiosResponse<IServerRES<IParkingReservation[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						storeParam.commit("parkings", response.data.payload.filter((parking: IParkingReservation) => { return parking.type !== IParkingReservationType.RESERVATION_CANCELED; }));
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async fetch (storeParam, { uuid }: { uuid: string}): Promise<IParkingReservation[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/reservations/parking/user`,
						data: {
							uuid
						}
					};
					const response: AxiosResponse<IServerRES<IParkingReservation[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload.filter((parking: IParkingReservation) => { return parking.type !== IParkingReservationType.RESERVATION_CANCELED; });
					}
					return [];
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async reserve (storeParam, { parkingReservation }: { parkingReservation:IParkingReservation }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/reservations/parking/add`,
						data: parkingReservation
					};
					const response: AxiosResponse<IServerRES<IParkingReservation>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async delete (storeParam, { parkingReservationId } : { parkingReservationId: string }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/parking/delete/${parkingReservationId}`,
					};
					const response: AxiosResponse<IServerRES<IParkingReservation>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async update (storeParam, { parkingReservation } : { parkingReservation: IParkingReservation }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/reservations/parking/update`,
						data: parkingReservation
					};

					const response: AxiosResponse<IServerRES<IParkingReservation>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}

					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async getReservation (storeParam, { reservationId } : { reservationId: string }): Promise<IParkingReservation> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/parking/${reservationId}`,
					};
					const response: AxiosResponse<IServerRES<IParkingReservation>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}
					return NO_PARKING_RESERVATION;
				} catch (error) {
					console.error(error);
					return NO_PARKING_RESERVATION;
				}
			},
			async fetchAll (storeParam): Promise<IParkingReservation[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/parking`,
					};
					const response: AxiosResponse<IServerRES<IParkingReservation[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}
					return [];
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async fetchParkingDayLogging (storeParam): Promise<IParkingReservation[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/parking/parkingSize`,
					};
					const response: AxiosResponse<IServerRES<IParkingReservation[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}
					return [];
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			async modifyCar (storeParam, { parkingReservationId, carId } : { parkingReservationId: string, carId: string }): Promise<boolean> {
				try {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${storeParam.rootState.API_URL}/reservations/parking/modifyCar`,
						data: {
							parkingReservationId,
							carId
						}
					};
					const response: AxiosResponse<IServerRES<IParkingReservation>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return true;
					}
					return false;
				} catch (error) {
					console.error(error);
					return false;
				}
			},
			async fetchFreeSpaces (storeParam): Promise<number[]> {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						url: `${storeParam.rootState.API_URL}/reservations/parking/freeSpaces`,
					};
					const response: AxiosResponse<IServerRES<number[]>> = await axios(options);
					if (response.data.err === ServerError.NO_ERROR) {
						return response.data.payload;
					}
					return [];
				} catch (error) {
					console.error(error);
					return [];
				}
			}
		},
		modules: {}
	};
	return store;
}
