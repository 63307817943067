



































import Vue from "vue";
import moment from "moment";

export default Vue.extend({
	name: "DragDropUpload",
	props: {
		checkAttending: {
			type: Boolean
		},
	},
	data () {
		const dataOut: {
			date: string;
			email: string;
		}[] = [];
		const dataOutEmail: {
			email: string,
			username: string,
			name: string,
			mainGroup: string,
			groups: string[],
			manager:string,
			director: string,
			parkingRight: boolean
		}[] = [];
		return {
			dragover: false,
			uploaded: false,
			error: "",
			dataOut: dataOut,
			dataOutEmail: dataOutEmail
		};
	},
	methods: {
		onDrop (e: DragEvent) {
			this.dragover = false;

			if (e.dataTransfer) {
				if (e.dataTransfer.files.length > 1) {
					this.error = "Nu poti uploada mai mult de un fisier";
				} else {
					this.handleFile(e.dataTransfer.files[0]);
				}
			}
		},
		handleFile (file: File) {
			if (file.name.substring(file.name.length - 4) !== ".csv") {
				this.uploaded = false;
				this.error = "Fisierul trebuie sa fie un fisier .csv";
			} else {
				this.uploaded = true;

				const reader = new FileReader();

				reader.onload = (e: ProgressEvent) => {
					const csv = (e.target as FileReader).result;
					const csvString = csv?.toString();

					if (csvString) {
						if (this.checkAttending) {
							this.handleCSVAttending(csvString);
						} else {
							this.handleCSVEmailList(csvString);
						}
					} else {
						this.uploaded = false;
					}
				};

				reader.readAsText(file);
			}
		},
		handleCSVAttending (csv: string) {
			const csvLines = csv.split("\n");
			csvLines.shift();

			for (const line of csvLines) {
				let data = line.split(",");
				if (data.length === 1) {
					data = line.split(";");
				}

				if (data.length > 2) {
					this.uploaded = false;
					this.error = "Fisierul nu contine doar 2 coloane (Data, Email)";

					break;
				}

				if (data[0].includes("/")) {
					const components = data[0].split("/");

					data[0] = components[2] + "-" + components[1] + "-" + components[0];
				}

				if (data[0].includes(".")) {
					const components = data[0].split(".");

					data[0] = components[2] + "-" + components[1] + "-" + components[0];
				}

				if (moment(data[0]).isValid()) {
					this.dataOut.push({
						date: data[0],
						email: data[1].replace("\r", "")
					});
				} else if (this.dataOut.length === 0) {
					this.uploaded = false;
					this.error = "Fisierul contine date invalide";

					break;
				} else {
					continue;
				}
			}

			if (this.uploaded) {
				this.$emit("uploaded", this.dataOut);
			}
		},
		handleCSVEmailList (csv: string) {
			const csvLines = csv.split("\n");
			csvLines.shift();

			for (const line of csvLines) {
				let data = line.split(",");
				if (data.length === 1) {
					data = line.split(";");
				}

				if (data.length !== 8 && data.length !== 1 && data[8] !== "\r") {
					this.uploaded = false;
					this.error = "Fisierul nu contine doar 8 coloane (Email, Nume Complet, Username, Departament, Directia, Manager, Director, Drept loc de parcare)";
					break;
				} else if (data.length === 1) {
					continue;
				}

				const email = data[0].replace("\r", "");
				const name = data[1].replace("\r", "");
				const username = data[2].replace("\r", "");
				const mainGroup = data[3].replace("\r", "");
				const groups = data[4].replace("\r", "").split("| ") ? [data[4].replace("\r", "")] : data[4].replace("\r", "").split("| ");
				const manager = data[5].replace("\r", "");
				const director = data[6].replace("\r", "");
				const parking = data[7].replace("\r", "").toLowerCase() === "da";

				this.dataOutEmail.push({
					email: email,
					username: username,
					name: name,
					mainGroup: mainGroup,
					groups: groups,
					manager: manager,
					director: director,
					parkingRight: parking,
				});
			}
			if (this.uploaded) {
				this.$emit("uploaded", this.dataOutEmail);
			}
		},
		changeInput (e: Event) {
			const files = (e.target as HTMLInputElement).files;

			if (files !== undefined && files !== null && files.length > 0) {
				this.handleFile(files[0]);
			}
		},
		clickInput () {
			const input = document.querySelector("input") as HTMLInputElement;

			input.click();
		}
	}
});
