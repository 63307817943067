




















































































import { Vue } from "vue-property-decorator";
import { IGroup, NO_GROUP } from "@common/groups";
import { IDesk } from "@common/desks";
import { mapGetters } from "vuex";
export default Vue.extend({
	name: "ManagerDepartments",
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	mounted () {
		this.fetchData();
	},
	watch: {
		"searchQuery" () {
			this.filteredSelectedProjectDesks = this.selectedProjectDesks.filter(desk => desk.id.toLowerCase().includes(this.searchQuery.toLowerCase()));
		},
		"searchQuerySecond" () {
			this.filteredAvalabileDesks = this.availableDesks.filter(desk => desk.id.toLowerCase().includes(this.searchQuerySecond.toLowerCase()));
		}
	},
	methods: {
		isProiecteEmpty (): boolean {
			return (this.proiecte.length === 0);
		},
		async addDeskGroup (desk: IDesk) {
			if (this.selectedProject._id) {
				desk.groupsIdList.push(this.selectedProject._id);
			}

			const response = await this.$store.dispatch("deskStore/update", {
				desk: desk,
				token: this.userState.token
			});

			this.snackbox = true;

			if (response) {
				this.selectedProjectDesks.push(desk);
				this.availableDesks.splice(this.availableDesks.indexOf(desk), 1);
				this.filteredAvalabileDesks = this.availableDesks;
				this.filteredSelectedProjectDesks = this.selectedProjectDesks;

				this.searchQuery = "";
				this.searchQuerySecond = "";

				this.snackboxMessage = "Biroul a fost adăugat cu succes!";
				this.snackboxColor = "success";
			} else {
				this.snackboxMessage = "Biroul nu a putut fi adăugat!";
				this.snackboxColor = "error";
			}
		},
		async removeDeskGroup (desk: IDesk) {
			if (this.selectedProject._id) {
				desk.groupsIdList.splice(desk.groupsIdList.indexOf(this.selectedProject._id), 1);
			}

			const response = await this.$store.dispatch("deskStore/update", {
				desk: desk,
				token: this.userState.token
			});

			this.snackbox = true;

			if (response) {
				this.selectedProjectDesks.splice(this.selectedProjectDesks.indexOf(desk), 1);
				this.availableDesks.push(desk);
				this.filteredAvalabileDesks = this.availableDesks;
				this.filteredSelectedProjectDesks = this.selectedProjectDesks;

				this.searchQuery = "";
				this.searchQuerySecond = "";

				this.snackboxMessage = "Biroul a fost scos cu succes din proiect!";
				this.snackboxColor = "success";
			} else {
				this.snackboxMessage = "Biroul nu a putut fi scos din proiect!";
				this.snackboxColor = "error";
			}
		},
		async fetchData () {
			this.loading = true;

			const groups = await this.$store.dispatch("userStore/loadGroups", {
				role: "manager"
			});
			if (groups.managerOf === "") {

			}

			this.departamente = groups.managerOf.filter((group: IGroup) => group.type === "Departament");
			this.proiecte = groups.managerOf.filter((group: IGroup) => group.type === "Proiect");
			this.allDesks = await this.$store.dispatch("deskStore/loadAll");

			for (const departament of this.departamente) {
				this.allAvailableDesks = this.allDesks.filter((desk: IDesk) => desk.groupsIdList.includes(departament._id ? departament._id : "-1")).concat(this.availableDesks);
			}

			this.allAvailableDesks = Array.from(new Set(this.allAvailableDesks));
			this.allAvailableDesks.sort((a: IDesk, b: IDesk) => a.id.localeCompare(b.id));

			this.loading = false;
		},
		selectProject (project: IGroup) {
			this.selectedProject = project;

			this.selectedProjectDesks = this.allDesks.filter((desk: IDesk) => desk.groupsIdList.includes(project._id ? project._id : "-1"));
			this.filteredSelectedProjectDesks = this.selectedProjectDesks;

			this.availableDesks = this.allAvailableDesks.filter((desk: IDesk) => !this.selectedProjectDesks.includes(desk));
			this.filteredAvalabileDesks = this.availableDesks;
		}
	},
	data: () => {
		const departamente: IGroup[] = [];
		const proiecte: IGroup[] = [];
		const selectedProjectDesks: IDesk[] = [];
		const filteredSelectedProjectDesks: IDesk[] = [];
		const allAvailableDesks: IDesk[] = [];
		const availableDesks: IDesk[] = [];
		const filteredAvalabileDesks: IDesk[] = [];
		const allDesks: IDesk[] = [];
		return {
			loading: false,
			departamente,
			proiecte,
			selectedProject: NO_GROUP,
			defaultGroup: NO_GROUP,
			searchQuery: "",
			searchQuerySecond: "",
			selectedProjectDesks,
			filteredSelectedProjectDesks,
			allDesks,
			availableDesks,
			filteredAvalabileDesks,
			allAvailableDesks,
			emptyMessage: "Nu exista proiecte de afisat momentan.",
			snackbox: false,
			snackboxMessage: "",
			snackboxColor: ""
		};
	},
});
