export enum IParkingReservationType {
    IN_QUEUE = 0,
	FIRST_ROUND_RESERVED = 1,
	SECOND_ROUND_RESERVED = 2,
	ABSENT = 3,
	NO_RESERVATION = 4,
    RESERVATION_CANCELED = 5
};

export interface IParkingReservation {
    _id?: string;
    date_added: Date;
    reservation_date: Date;
    user_id: string;
    car_id: string;
    reservation_confirmed?: boolean;
    type?: IParkingReservationType;
    indiscipline_score?: number;
    queue_position?: number;
};

export function isParkingReservation(obj: any): boolean {
    const isDateAdded = obj.date_added !== undefined && obj.date_added !== null;
    const isReservationDate = obj.reservation_date !== undefined && obj.reservation_date !== null;
    const isUserId = obj.user_id !== undefined && obj.user_id !== null;
    const isCarId = obj.car_id !== undefined && obj.car_id !== null;

    return isDateAdded && isReservationDate && isUserId && isCarId;
}

export const NO_PARKING_RESERVATION : IParkingReservation = {
    _id: "-1",
    date_added: new Date(),
    reservation_date: new Date(),
    user_id: "",
    car_id: ""
}