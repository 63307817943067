
































































































































































































































































import Vue from "vue";
import moment from "moment";
import { IParkingReservation, IParkingReservationType, NO_PARKING_RESERVATION } from "@common/parkingReservations";
import _ from "lodash";
import * as XLSX from "xlsx";
import { mapGetters } from "vuex";
import { ConfigType, DefaultConfig, IConfig } from "@common/config";
import { ICar } from "@common/cars";
import { BRDBuildings, IUser } from "@common/users";

interface IFilterReservations {
	id?: string;
	data: string;
	vehicul: string;
	utilizator: string;
	email: string;
	status: string;
	grading: string;
	masinaFunctie: string;
}

export default Vue.extend({
	name: "StatisticsDays",
	data () {
		const selectedFilters: {
			dateBegin: string;
			dateEnd: string;
		} = {
			dateBegin: "",
			dateEnd: "",
		};

		const allReservations: IParkingReservation[] = [];
		const filteredReservations: IParkingReservation[] = [];
		const rezervariFiltrate: IFilterReservations[] = [];

		const allVehicles: ICar[] = [];
		const allUsers: IUser[] = [];
		const deleteReservationItem = NO_PARKING_RESERVATION;
		const selectedReservationCar = "";
		const selectedReservationUser = "";

		return {
			loading: false,
			selectedFilters: selectedFilters,
			dateMenuAdd: false,
			dateMenuBegin: false,
			dateMenuEnd: false,
			allReservations: allReservations,
			filteredReservations: filteredReservations,
			rezervariFiltrate: rezervariFiltrate,
			allVehicles: allVehicles,
			allUsers: allUsers,
			deleteReservationDialog: false,
			deleteReservationItem,
			editReservationDialog: false,
			userVehicles: allVehicles,
			selectedReservationCar,
			reservationDialogNew: false,
			selectedReservationUser,
			dateAdd: [],
		};
	},
	mounted () {
		this.fetchData();
	},
	computed: {
		...mapGetters({
			configState: "configStore/configs"
		}),
		dateBeginFormatted (): string {
			if (this.selectedFilters.dateBegin) {
				return moment(this.selectedFilters.dateBegin).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
		dateEndFormatted () : string {
			if (this.selectedFilters.dateEnd) {
				return moment(this.selectedFilters.dateEnd).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
		dateAddFormatted (): string {
			let string = "";

			for (const date of this.dateAdd) {
				string += moment(date).format("DD.MM.YYYY") + ", ";
			}

			return string.substring(0, string.length - 2);
		},
		headers () {
			return [
				{
					text: "Data",
					align: "center",
					value: "data"
				},
				{
					text: "Vehicul",
					align: "center",
					value: "vehicul"
				},
				{
					text: "Utilizator",
					align: "center",
					value: "utilizator"
				},
				{
					text: "Status",
					align: "center",
					value: "status"
				},
				{
					text: "Grading",
					value: "grading",
					align: "center"
				},
				{
					text: "Masina de Functie",
					value: "masinaFunctie",
					align: "center"
				},
				{
					text: "Actiuni",
					value: "actions",
					align: "center",
				}
			];
		},
	},
	methods: {
		async fetchData () {
			this.loading = true;

			this.allReservations = await this.$store.dispatch("parkingStore/fetchAll");
			this.allVehicles = await this.$store.dispatch("carsStore/fetch", { role: "admin" });
			this.allUsers = await this.$store.dispatch("userStore/loadAllUsers", { building: BRDBuildings.Turn });
			this.filteredReservations = this.allReservations;
			this.selectedFilters = _.cloneDeep(this.selectedFilters);

			this.loading = false;
		},
		disableFutureDates (date: Date) {
			if (this.selectedFilters.dateEnd !== "") {
				if (moment(date).isAfter(this.selectedFilters.dateEnd)) {
					return false;
				}
			}

			return true;
		},
		disableOldDates (date: Date) {
			if (this.selectedFilters.dateBegin !== "") {
				if (moment(date).isBefore(this.selectedFilters.dateBegin)) {
					return false;
				}
			}

			return true;
		},
		resetFilters () {
			this.selectedFilters = {
				dateBegin: "",
				dateEnd: "",
			};

			this.filteredReservations = this.allReservations;
			this.$forceUpdate();
		},
		createEXCEL () {
			const wb = XLSX.utils.book_new();

			// Remove ID
			const rezervariFiltrateWrite = _.cloneDeep(this.rezervariFiltrate);
			rezervariFiltrateWrite.forEach(element => {
				delete element.id;
			});

			const ws = XLSX.utils.json_to_sheet(rezervariFiltrateWrite);
			XLSX.utils.book_append_sheet(wb, ws, "Rezervari");
			XLSX.writeFile(wb, "Rezervari.xlsx");
		},
		isMobile () {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true;
			} else {
				return false;
			}
		},
		stillEditable (item: IFilterReservations) {
			if (moment(item.data, "DD.MM.YYYY").isAfter(moment().startOf("day"))) {
				return true;
			} else {
				return false;
			}
		},
		editReservation (id: string) {
			const reservation = this.allReservations.find((item) => item._id === id);

			if (reservation) {
				this.deleteReservationItem = reservation;
				this.editReservationDialog = true;

				this.userVehicles = this.allVehicles.filter((vehicle) => vehicle.user_id === reservation.user_id && vehicle.active === true);
				this.selectedReservationCar = this.userVehicles.find((vehicle) => vehicle._id === reservation.car_id)?._id || "";
			} else {
				this.$emit("showError", "A apărut o eroare la editarea rezervării!");
			}
		},
		async updateEditReservation (reservation: IParkingReservation) {
			if (this.selectedReservationCar !== "") {
				reservation.car_id = this.selectedReservationCar;
			} else {
				return this.$emit("showError", "A apărut o eroare la editarea rezervării!");
			}

			this.$store.dispatch("parkingStore/update", {
				parkingReservation: reservation,
			});

			this.filteredReservations = [];
			this.editReservationDialog = false;
			await this.fetchData();
		},
		deleteReservation (id: string) {
			const reservation = this.allReservations.find((item) => item._id === id);

			if (reservation) {
				this.deleteReservationDialog = true;
				this.deleteReservationItem = reservation;
			} else {
				this.$emit("showError", "A apărut o eroare la ștergerea rezervării!");
			}
		},
		async updateDeleteReservation (reservation: IParkingReservation) {
			reservation.type = IParkingReservationType.RESERVATION_CANCELED;

			if (reservation.reservation_confirmed === false) {
				reservation.reservation_confirmed = true;

				this.$store.dispatch("userStore/deleteIndisciplineScore", {
					user: reservation.user_id
				});
			}

			await this.$store.dispatch("parkingStore/update", {
				parkingReservation: reservation,
			});

			this.filteredReservations = [];
			this.deleteReservationDialog = false;
			await this.fetchData();
		},
		formatDate (date: Date) {
			return moment(date).format("DD.MM.YYYY");
		},
		getVehicle (id: string) {
			const vehicle = this.allVehicles.find((item) => item._id === id);

			if (vehicle) {
				return vehicle.license_plate;
			} else {
				return "N/A";
			}
		},
		getUser (id: string) {
			const user = this.allUsers.find((item) => item.uuid === id);

			if (user) {
				return user.name;
			} else {
				return "N/A";
			}
		},
		allowedDates (val: string) {
			var isValid = true;

			if (new Date(val).getDay() === 0 || new Date(val).getDay() === 6) {
				isValid = false;
			}

			if (moment(val).isBefore(moment().add(1, "day").startOf("day"))) {
				isValid = false;
			}

			return isValid;
		},
		userAllowedDates (val: string) {
			if (this.selectedReservationUser !== "") {
				const userReservationsInFuture = this.allReservations.filter((reservation) => reservation.user_id === this.selectedReservationUser &&
					moment(reservation.reservation_date).isAfter(moment().startOf("day")) &&
					reservation.type !== IParkingReservationType.RESERVATION_CANCELED
				);

				const userReservationsInFutureDates = userReservationsInFuture.map((reservation) => moment(reservation.reservation_date).format("DD.MM.YYYY"));
				if (userReservationsInFutureDates.includes(moment(val).format("DD.MM.YYYY"))) {
					return false;
				}
			}

			return true && this.allowedDates(val);
		},
		async createReservation () {
			let queueGenerationHour: number;
			const queueGenerationHourConfig = this.configState.find((item: IConfig) => item.name === ConfigType.FRIDAY_CUTOFF_HOUR);

			if (queueGenerationHourConfig) {
				queueGenerationHour = parseInt(queueGenerationHourConfig.value);
			} else {
				queueGenerationHour = DefaultConfig.FRIDAY_CUTOFF_HOUR;
			}

			this.dateAdd.forEach(async reservationDate => {
				const reservationType = () => {
					// If the reservation is in the current week it shall be marked as FIRST_ROUND_RESERVED
					// If the reservation is the next week it shall be marked as IN_QUEUE
					// If the reservation is the next week but we are after the queue generation it shall be marked as FIRST_ROUND_RESERVED
					if (moment(reservationDate).isBefore(moment().add(1, "week").startOf("isoWeek"))) {
						return IParkingReservationType.FIRST_ROUND_RESERVED;
					} else if (moment(reservationDate).isBefore(moment().add(2, "week").startOf("isoWeek")) && moment().isAfter(moment().isoWeekday(5).hour(queueGenerationHour).minute(0).second(59))) {
						return IParkingReservationType.FIRST_ROUND_RESERVED;
					} else {
						return IParkingReservationType.IN_QUEUE;
					}
				};

				const newReservation: IParkingReservation = {
					date_added: moment().toDate(),
					reservation_date: moment(reservationDate).hour(8).toDate(),
					user_id: this.selectedReservationUser,
					car_id: this.selectedReservationCar,
					type: reservationType(),
					indiscipline_score: 0
				};

				await this.$store.dispatch("parkingStore/update", {
					parkingReservation: newReservation,
				});
			});

			this.dateAdd = [];
			this.selectedReservationCar = "";
			this.selectedReservationUser = "";

			this.filteredReservations = [];
			this.reservationDialogNew = false;
			await this.fetchData();
		}
	},
	watch: {
		selectedReservationUser () {
			this.userVehicles = this.allVehicles.filter((vehicle) => vehicle.user_id === this.selectedReservationUser && vehicle.active === true);
		},
		"selectedFilters.dateEnd" () {
			if (this.selectedFilters.dateBegin !== "" && this.selectedFilters.dateEnd !== "") {
				if (
					moment(this.selectedFilters.dateBegin).isAfter(
						this.selectedFilters.dateEnd
					)
				) {
					const temp = this.selectedFilters.dateBegin;
					this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					this.selectedFilters.dateEnd = temp;
				}
			}
		},
		filteredReservations () {
			this.rezervariFiltrate = [];
			let filter = _.cloneDeep(this.filteredReservations);

			filter = filter.sort((a, b) => {
				return moment(a.reservation_date).diff(moment(b.reservation_date));
			});

			let maxReservations = DefaultConfig.MAX_PARKING_SPACES;
			if (this.configState.length > 0) {
				maxReservations = parseInt(this.configState.find((item: IConfig) => item.name === ConfigType.MAX_PARKING_SPACES).value);
				if (maxReservations === undefined) {
					maxReservations = DefaultConfig.MAX_PARKING_SPACES;
				}
			}

			filter.forEach((reservation) => {
				const date = moment(reservation.reservation_date).format("DD.MM.YYYY");
				const user = this.allUsers.find((user) => user.uuid === reservation.user_id);
				const vehicle = this.allVehicles.find((vehicle) => vehicle._id === reservation.car_id);
				let status = "N/A";

				switch (reservation.type) {
					case IParkingReservationType.IN_QUEUE:
						status = "In asteptare";
						break;
					case IParkingReservationType.ABSENT:
						status = "Absent";
						break;
					case IParkingReservationType.NO_RESERVATION:
						status = "Nealocat";
						break;
					case IParkingReservationType.RESERVATION_CANCELED:
						status = "Anulata";
						break;
					case IParkingReservationType.FIRST_ROUND_RESERVED:
						status = "Rezervare prima runda";
						break;
					case IParkingReservationType.SECOND_ROUND_RESERVED:
						if (reservation.reservation_confirmed !== false) {
							status = "Rezervare a doua runda";
						} else {
							status = "Absent";
						}

						break;
					default:
						status = "N/A";
						break;
				}

				const rezervare = {
					id: reservation._id ? reservation._id : "-1",
					data: date,
					vehicul: vehicle ? vehicle.license_plate : "N/A",
					utilizator: user ? user.name : "N/A",
					email: user ? user.email : "N/A",
					status: status,
					grading: user?.graded ? "Da" : "Nu",
					masinaFunctie: vehicle?.function_car ? "Da" : "Nu"
				};

				this.rezervariFiltrate.push(rezervare);
			});
		},
		configState () {
			this.fetchData();
			this.$forceUpdate();
		},
		selectedFilters: {
			handler () {
				this.filteredReservations = this.allReservations;

				if (this.selectedFilters.dateBegin) {
					if (!this.selectedFilters.dateEnd || moment(this.selectedFilters.dateEnd).isBefore(this.selectedFilters.dateBegin)) {
						this.selectedFilters.dateEnd = this.selectedFilters.dateBegin;
					}
				}

				if (this.selectedFilters.dateEnd) {
					if (!this.selectedFilters.dateBegin || moment(this.selectedFilters.dateBegin).isAfter(this.selectedFilters.dateEnd)) {
						this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					}
				}

				if (this.selectedFilters.dateBegin && this.selectedFilters.dateEnd) {
					this.filteredReservations = this.filteredReservations.filter(rez => {
						if (moment(rez.reservation_date).isBetween(moment(this.selectedFilters.dateBegin).startOf("day"), moment(this.selectedFilters.dateEnd).endOf("day"))) {
							return rez;
						}
					});
				}
			},
			deep: true
		},
		reservationDialogNew (isDialogOpen) {
			if (!isDialogOpen) {
				this.selectedReservationCar = "";
				this.selectedReservationUser = "";
				this.dateAdd = [];
			}
		}
	}
});
