



















































import { Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
export default Vue.extend({
	name: "Password",
	data () {
		return {
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
			errorAlert: false,
			errorMessage: "",
			loading: false,
			showPassword1: false,
			showPassword2: false,
			showPassword3: false
		};
	},
	computed: {
		...mapGetters({
			userState: "userStore/user",
		})
	},
	methods: {
		async changePassword () {
			this.loading = true;

			if (this.newPassword.length > 5) {
				if (this.newPassword === this.confirmPassword) {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${this.$store.getters.API_URL}/users/changePassword`,
						data: {
							user: this.userState.userInfo,
							oldPassword: this.oldPassword,
							newPassword: this.newPassword
						}
					};
					const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						await this.$store.dispatch("userStore/logout", {
							token: this.userState.token
						});

						this.$router.push({ path: "/login", query: { reset: "true" } });
					} else {
						this.errorMessage = "Parola veche este incorectă";
						this.errorAlert = true;
					}
				} else {
					this.errorMessage = "Parolele nu corespund";
					this.errorAlert = true;
				}
			} else {
				this.errorAlert = true;
				this.errorMessage = "Parola trebuie să aibă cel puțin 6 caractere";
			}

			this.loading = false;
		}
	}
});
