


























import Vue from "vue";

export default Vue.extend({
	name: "Legend",
	props: {
		admin: Boolean
	},
	data () {
		return {
			closed: true,
			entries: [
				{
					show: true,
					width: "1.5em",
					height: "1.5em",
					class: "mx-1",
					color: "green",
					textSize: "0.99em",
					text: "Nerezervat"
				},
				{
					show: true,
					width: "1.5em",
					height: "1.5em",
					class: "mx-1",
					color: "red",
					textSize: "0.99em",
					text: "Rezervat de către altcineva"
				},
				{
					show: true,
					width: "1.5em",
					height: "1.5em",
					class: "mx-1",
					color: "purple",
					textSize: "0.99em",
					text: "Rezervat de tine"
				},
				{
					show: true,
					width: "1.5em",
					height: "1.5em",
					class: "mx-1",
					color: "orange",
					textSize: "0.99em",
					text: "Sală de reuniuni"
				},
				{
					show: true,
					width: "1.5em",
					height: "1.5em",
					class: "mx-1",
					color: "blue",
					textSize: "0.99em",
					text: "Birou alocat"
				}
			]
		};
	},
});
