import { BRDBuildings } from "./users";

export enum GroupType {
	DEPARTAMENT = "Departament",
	PROJECT = "Proiect",
	NONE = ""
}

export interface IGroup {
	_id?: string;
	name: string;
	type: GroupType;
	parkingSpots: number;
	building: BRDBuildings;
};

export function isGroup(obj: any, withId:boolean = false): boolean {
	const isName = obj.name !== undefined && obj.name !== null;
    const isType = obj.type !== undefined && obj.type !== null;
	const isParkingSpots = obj.parkingSpots !== undefined && obj.parkingSpots !== null;
	const isBuilding = obj.building !== undefined && obj.building !== null;

    let result = isName && isType && isParkingSpots && isBuilding;
    if (withId) {
        const isId = obj._id !== undefined && obj._id !== null;
        result = result && isId;
    }

    return result;
};

export const NO_GROUP:IGroup = {
	_id: "-1",
	name: "",
	type: GroupType.DEPARTAMENT,
	parkingSpots: -1,
	building: BRDBuildings.Flexioffice
};