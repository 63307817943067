

















































































import { Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import moment from "moment";
import { IGroup } from "@common/groups";
import { IReservedDesk } from "@common/reservations";
import { IDesk } from "@common/desks";
export default Vue.extend({
	name: "Admin",
	computed: {
		...mapGetters({
			userState: "userStore/user",
			desksState: "deskStore/desks"
		}),
		headers () {
			return [
				{
					text: "Entitate",
					align: "center",
					value: "name"
				},
				{
					text: "Nr. Alocări",
					align: "center",
					value: "aloc"
				},
				{
					text: "Nr. Rezervări",
					align: "center",
					value: "reserv"
				},
				{
					text: "Nr. Prezențe",
					align: "center",
					value: "pres"
				},
				{
					text: "Nr. Prezențe/Nr. Rezervări",
					align: "center",
					value: "presReserv"
				},
				{
					text: "Nr. Rezervări/Nr. Alocate",
					align: "center",
					value: "reservAloc"
				},
				{
					text: "Nr. Prezențe/Nr. Alocate",
					align: "center",
					value: "presAloc"
				}
			];
		},
		// dateRangeText (): string {
		// 	if (this.selectedFilters.dates[0]) {
		// 		return this.selectedFilters.dates
		// 			.map((date: string) => moment(date).format("DD.MM.YYYY"))
		// 			.join(" - ");
		// 	} else {
		// 		return "Selectează intervalul";
		// 	}
		// }
		dateBeginFormatted (): string {
			if (this.selectedFilters.dateBegin) {
				return moment(this.selectedFilters.dateBegin).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
		dateEndFormatted (): string {
			if (this.selectedFilters.dateEnd) {
				return moment(this.selectedFilters.dateEnd).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
	},
	data () {
		const items: {
			name: string,
			aloc: number,
			reserv: number,
			pres: number,
			presReserv: string,
			reservAloc: string,
			presAloc: string
		}[] = [];
		// const selectedFilters: {
		// 	dates: string[];
		// } = {
		// 	dates: [
		// 		"",
		// 		""
		// 	],
		// };
		const selectedFilters: {
			dateBegin: string,
			dateEnd: string
		} = {
			dateBegin: moment().startOf("month").format("YYYY-MM-DD"),
			dateEnd: moment().endOf("month").format("YYYY-MM-DD")
		};
		const allGroupsReservations: {
			group: IGroup,
			reservedDesks: IReservedDesk[],
			totalDesks: IDesk[]
		}[] = [];

		return {
			loading: false,
			items: items,
			selectedFilters: selectedFilters,
			dateMenuBegin: false,
			dateMenuEnd: false,
			allGroupsReservations: allGroupsReservations,
			firstDate: "2021-02-08"
		};
	},
	async mounted () {
		await this.fetchData();
		this.recalculateItems();
	},
	methods: {
		async fetchData () {
			this.loading = true;
			const allGroups = (await this.$store.dispatch("userStore/loadGroups", { role: "admin" })).groupList;
			const allReservedDesks = await this.$store.dispatch("deskStore/fetchAll");
			const allDesks = await this.$store.dispatch("deskStore/loadAll");

			for (const group of allGroups) {
				if (group.type === "Departament") {
					this.allGroupsReservations.push({
						group: group,
						reservedDesks: allReservedDesks.filter((desk: IReservedDesk) => desk.groupId === group._id),
						totalDesks: allDesks.filter((desk: IDesk) => desk.groupsIdList.includes(group._id))
					});

					const alocari = this.calcBusinessDays(this.firstDate, moment().format("YYYY-MM-DD")) * this.allGroupsReservations[this.allGroupsReservations.length - 1].totalDesks.length;
					const reserv = this.allGroupsReservations[this.allGroupsReservations.length - 1].reservedDesks.length;
					const pres = this.allGroupsReservations[this.allGroupsReservations.length - 1].reservedDesks.filter((desk: IReservedDesk) => desk.reservation_used === true).length;

					this.items.push({
						name: group.name,
						aloc: alocari,
						reserv: reserv,
						pres: pres,
						presReserv: `${pres}/${reserv}`,
						reservAloc: `${reserv}/${alocari}`,
						presAloc: `${pres}/${alocari}`
					});
				}
			}
			this.loading = false;
			this.$forceUpdate();
		},
		calcBusinessDays (startDate: string, endDate: string) {
			const day = moment(startDate);
			let businessDays = 0;
			while (day.isSameOrBefore(endDate, "day")) {
				if (day.day() !== 0 && day.day() !== 6) {
					businessDays++;
				}
				day.add(1, "d");
			}
			return businessDays;
		},
		resetData () {
			this.items = [];
			for (const groupReserv of this.allGroupsReservations) {
				const reserv = groupReserv.reservedDesks.length;
				const pres = groupReserv.reservedDesks.filter((desk: IReservedDesk) => desk.reservation_used === true).length;
				const alocari = this.calcBusinessDays(this.firstDate, moment().format("YYYY-MM-DD")) * groupReserv.totalDesks.length;
				this.items.push({
					name: groupReserv.group.name,
					aloc: alocari,
					reserv: reserv,
					pres: pres,
					presReserv: `${pres}/${reserv}`,
					reservAloc: `${reserv}/${alocari}`,
					presAloc: `${pres}/${alocari}`
				});
			}
		},
		createCSV () {
			let csvContent = "data:text/csv;charset=utf-8," + "Entitate,Nr. Alocari,Nr. Rezervari,Nr. Prezente,Nr. Prezente/Nr. Rezervari,Nr. Rezervari/Nr. Alocate,Nr. Prezente/Nr. Alocate\n";
			for (const item of this.items) {
				csvContent += `${item.name},${item.aloc}, ${item.reserv},${item.pres},${item.presReserv},${item.reservAloc},${item.presAloc}\n`;
			}

			const url = encodeURI(csvContent);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "Admin.csv");
			document.body.appendChild(link);
			link.click();
		},
		recalculateItems () {
			this.items = [];

			for (const group of this.allGroupsReservations) {
				const reserv = group.reservedDesks.filter((desk: IReservedDesk) => {
					return (moment(desk.reservationDate).isBetween(
						this.selectedFilters.dateBegin,
						this.selectedFilters.dateEnd
					) || moment(desk.reservationDate).isSame(this.selectedFilters.dateEnd, "day"));
				}).length;
				const pres = group.reservedDesks.filter((desk: IReservedDesk) => desk.reservation_used === true && (moment(desk.reservationDate).isBetween(
					this.selectedFilters.dateBegin,
					this.selectedFilters.dateEnd
				) || moment(desk.reservationDate).isSame(this.selectedFilters.dateEnd, "day"))).length;
				const alocari = this.calcBusinessDays(this.selectedFilters.dateBegin, this.selectedFilters.dateEnd) * group.totalDesks.length;
				this.items.push({
					name: group.group.name,
					aloc: alocari,
					reserv: reserv,
					pres: pres,
					presReserv: `${pres}/${reserv}`,
					reservAloc: `${reserv}/${alocari}`,
					presAloc: `${pres}/${alocari}`
				});
			}

			this.$forceUpdate();
		},
		disableOldDates (date: Date) {
			if (this.selectedFilters.dateBegin !== "") {
				if (moment(date).isBefore(this.selectedFilters.dateBegin)) {
					return false;
				}
			}

			return true;
		},
		disableFutureDates (date: Date) {
			if (this.selectedFilters.dateEnd !== "") {
				if (moment(date).isAfter(this.selectedFilters.dateEnd)) {
					return false;
				}
			}

			return true;
		}
	},
	watch: {
		"selectedFilters.dateEnd" () {
			if (this.selectedFilters.dateBegin !== "" && this.selectedFilters.dateEnd !== "") {
				if (
					moment(this.selectedFilters.dateBegin).isAfter(
						this.selectedFilters.dateEnd
					)
				) {
					// this.selectedFilters.dates = [
					// 	this.selectedFilters.dates[1],
					// 	this.selectedFilters.dates[0]
					// ];
					const temp = this.selectedFilters.dateBegin;
					this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					this.selectedFilters.dateEnd = temp;
				}

				this.recalculateItems();
			}
		},
		"selectedFilters.dateBegin" () {
			if (this.selectedFilters.dateBegin !== "" && this.selectedFilters.dateEnd !== "") {
				if (
					moment(this.selectedFilters.dateBegin).isAfter(
						this.selectedFilters.dateEnd
					)
				) {
					// this.selectedFilters.dates = [
					// 	this.selectedFilters.dates[1],
					// 	this.selectedFilters.dates[0]
					// ];
					const temp = this.selectedFilters.dateBegin;
					this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					this.selectedFilters.dateEnd = temp;
				}

				this.recalculateItems();
			}
		}
	}
});
