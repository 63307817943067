



































































































import { Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { NO_RESERVED_CONFERENCE_ROOM, NO_RESERVED_DESK, ReservationStatus } from "@common/reservations";
import { NO_DESK } from "@common/desks";
import moment from "moment-timezone";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { IConferenceRoom, NO_CONFERENCE_ROOM } from "@common/conferenceRooms";
import { ServerError } from "@common/errors";
export default Vue.extend({
	name: "Confirmation",
	computed: {
		...mapGetters({
			userState: "userStore/user"
		})
	},
	async mounted () {
		try {
			if (this.userState.userInfo.uuid !== "-1") {
				await this.checkReservation();
			} else {
				// console.log("notLoggedIN");
				this.loggedIn = false;
				// TODO FORCE LOGIN
			}
		} catch (error) {
			console.error(error);
		}
	},
	watch: {
		loggedIn: {
			immediate: false,
			handler (newLogging) {
				// console.log("NewLogging");
				if (newLogging) {
					this.checkReservation();
				}
			}
		}
	},
	data () {
		const res = { ...NO_DESK, ...NO_RESERVED_DESK, ...NO_RESERVED_CONFERENCE_ROOM, ...NO_CONFERENCE_ROOM };
		return {
			currentReservation: res,
			type: "desk",
			username: "",
			password: "",
			inc: false,
			loggedIn: true,
			roomName: "",
			showPassword: false
		};
	},
	methods: {
		moment (value:string) {
			return moment(value);
		},
		async getRoomName (id: string) {
			const options: AxiosRequestConfig = {
				method: "GET",
				url: `${this.$store.getters.API_URL}/conference-rooms/info/${id}`
			};

			const response: AxiosResponse<IServerRES<IConferenceRoom>> = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				if (response.data.payload.details) {
					this.roomName = response.data.payload.details.name;
				} else {
					this.roomName = "Sala de conferință";
				}
			} else {
				this.roomName = "Sala de conferință";
			}
		},
		getData (data:string, isDesk:boolean) {
			return isDesk ? moment(data).subtract(moment.duration("03:00:00")).format("DD.MM.YYYY") : moment(data).tz("Europe/Bucharest").format("DD.MM.YYYY HH:mm");
		},
		async cancelReservation () {
			try {
				if (this.type === "desk") {
					this.currentReservation.status = ReservationStatus.BUSY;
					const response = await this.$store.dispatch("deskStore/cancel", { reservationEntry: this.currentReservation });
					if (response) {
						let aux:Date | string = new Date(this.currentReservation.reservationDate);
						aux.setHours(11, 0, 0, 0);
						aux = aux.toISOString();

						await this.$store.dispatch("deskStore/load", {
							token: this.userState.token,
							groupId: this.userState.userInfo.mainGroup,
							date: aux
						});
						this.$forceUpdate();
						this.$router.push("/flexioffice");
					} else {
						// TODO ADD ERR
						console.error("NORESPONSE");
					}
				} else {
					this.currentReservation.status = ReservationStatus.BUSY;
					const response = await this.$store.dispatch("roomStore/cancel", { reservationEntry: this.currentReservation });
					if (response) {
						let aux:Date | string = new Date(this.currentReservation.dateBegin);
						aux.setHours(11, 0, 0, 0);
						aux = aux.toISOString();

						await this.$store.dispatch("roomStore/load", {
							token: this.userState.token,
							groupId: this.userState.userInfo.mainGroup,
							date: aux
						});
						this.$router.push("/flexioffice");
					} else {
						// TODO ADD ERR
						console.error("NORESPONSE");
					}
				}
			} catch (error) {
				console.error(error);
			}
		},
		dashboard () {
			this.$router.push("/flexioffice");
		},
		async checkReservation ():Promise<void> {
			try {
				const reservationId = this.$route.params.reservationId;
				if (reservationId !== undefined && reservationId !== "") {
					const reservation = await this.$store.dispatch("deskStore/fetch", { reservationId });
					if (reservation && reservation._id !== undefined && reservation._id !== "") {
						if (reservation.uuid === this.userState.userInfo.uuid && reservation.status !== ReservationStatus.BUSY) {
							this.currentReservation = reservation;
						} else {
							// console.log(reservation);
							// console.log(this.userState.userInfo);
							// console.log("NOT THE USER");
							// this.$router.push("/");
						}
					} else {
						// console.log("NO DESK RESERVATION");

						const roomReservation = await this.$store.dispatch("roomStore/fetch", { reservationId });
						// console.log(roomReservation);
						this.type = "room";
						if (roomReservation && roomReservation._id !== undefined && roomReservation._id !== -1) {
							if (roomReservation.uuid === this.userState.userInfo.uuid && roomReservation.status !== ReservationStatus.BUSY) {
								this.currentReservation = roomReservation;

								await this.getRoomName(this.currentReservation.conferenceRoomId);
							} else {
								// console.log("NOT THE USER");
								// this.$router.push("/");
							}
						} else {
							// console.log("NO ROOM RESERVATION");
							this.$router.push("/flexioffice");
						}
					}
				} else {
					// console.log("NOID");
					this.$router.push("/flexioffice");
				}
			} catch (error) {
				console.error(error);
			}
		},
		async loginFunction ():Promise<void> {
			try {
				const response:string = await this.$store.dispatch("userStore/login", {
					username: this.username,
					password: this.password
				});
				if (response) {
					this.loggedIn = true;
				} else {
					console.error("LOGIN FAILED");
					this.inc = true;
					setTimeout(() => {
						this.inc = false;
					}, 5000);
				}
			} catch (error) {
				console.error(error);
			}
		},
		canCancelReservation () {
			if (this.type !== "desk") {
				return true;
			}

			return !moment(this.currentReservation.reservationDate).subtract(1, "days").isBefore(moment());
		}
	},
});
