



























































































































































































import Vue from "vue";
import moment from "moment";
import { IParkingReservation, IParkingReservationType } from "@common/parkingReservations";
import _ from "lodash";
import * as XLSX from "xlsx";
import { ConfigType, DefaultConfig, IConfig } from "@common/config";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { BRDBuildings } from "@common/users";
import { ServerError } from "@common/errors";

export default Vue.extend({
	name: "StatisticsDays",
	data () {
		const selectedFilters: {
			dateBegin: string;
			dateEnd: string;
		} = {
			dateBegin: "",
			dateEnd: "",
		};

		const allReservations: IParkingReservation[] = [];
		const filteredReservations: IParkingReservation[] = [];
		const rezervariFiltrate: {
			data: string;
			numarCereriNealocate: number;
			numarRezervari: number;
			numarDisponibil: number;
			numarOcupatEfectiv: number;
			numarRezervariAbsent: number;
		}[] = [];

		const parkingDayLogging: {
			date: Date,
			// eslint-disable-next-line camelcase
			parking_size: number,
		}[] = [];

		const configState: IConfig[] = [];

		return {
			loading: false,
			selectedFilters: selectedFilters,
			dateMenuBegin: false,
			dateMenuEnd: false,
			allReservations: allReservations,
			filteredReservations: filteredReservations,
			rezervariFiltrate: rezervariFiltrate,
			parkingDayLogging: parkingDayLogging,
			configState: configState
		};
	},
	mounted () {
		this.fetchData();
	},
	computed: {
		dateBeginFormatted (): string {
			if (this.selectedFilters.dateBegin) {
				return moment(this.selectedFilters.dateBegin).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
		dateEndFormatted () : string {
			if (this.selectedFilters.dateEnd) {
				return moment(this.selectedFilters.dateEnd).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
		headers () {
			return [
				{
					text: "Data",
					align: "center",
					value: "data",
					tooltip: "Data"
				},
				{
					text: "Numar Cereri Totale",
					align: "center",
					value: "numarRezervari",
					tooltip: "Numarul total al cererilor efectuate, cu exceptia celor anulate"
				},
				{
					text: "Numar Locuri Disponibile",
					align: "center",
					value: "numarDisponibil",
					tooltip: "Numarul locurilor disponibile din ziua respectiva"
				},
				{
					text: "Numar Cereri Nealocate",
					align: "center",
					value: "numarCereriNealocate",
					tooltip: "Numarul cererilor pentru care nu a fost aprobat un loc de parcare"
				},
				{
					text: "Numar Absenti",
					align: "center",
					value: "numarRezervariAbsent",
					tooltip: "Numarul rezervarilor care nu au fost onorate"
				},
				{
					text: "Numar Ocupat Efectiv",
					value: "numarOcupatEfectiv",
					align: "center",
					tooltip: "Numarul locurilor ocupate efectiv"
				}
			];
		},
	},
	methods: {
		async fetchData () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					url: `${this.$store.getters.API_URL}/config`
				};
				const response: AxiosResponse<IServerRES<IConfig[]>> = await axios(options);
				if (response.data.err === ServerError.NO_ERROR) {
					this.configState = response.data.payload.filter((param: IConfig) => param.building === BRDBuildings.Turn);
				}
			} catch (error) {
				console.error(error);
			}

			this.parkingDayLogging = await this.$store.dispatch("parkingStore/fetchParkingDayLogging");
			this.allReservations = await this.$store.dispatch("parkingStore/fetchAll");
			this.filteredReservations = this.allReservations;

			this.loading = false;
		},
		disableFutureDates (date: Date) {
			if (this.selectedFilters.dateEnd !== "") {
				if (moment(date).isAfter(this.selectedFilters.dateEnd)) {
					return false;
				}
			}

			return true;
		},
		disableOldDates (date: Date) {
			if (this.selectedFilters.dateBegin !== "") {
				if (moment(date).isBefore(this.selectedFilters.dateBegin)) {
					return false;
				}
			}

			return true;
		},
		resetFilters () {
			this.selectedFilters = {
				dateBegin: "",
				dateEnd: "",
			};

			this.filteredReservations = this.allReservations;
			this.$forceUpdate();
		},
		createEXCEL () {
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(this.rezervariFiltrate);
			XLSX.utils.book_append_sheet(wb, ws, "Rezervari");
			XLSX.writeFile(wb, "Rezervari.xlsx");
		},
		isMobile () {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true;
			} else {
				return false;
			}
		}
	},
	watch: {
		"selectedFilters.dateEnd" () {
			if (this.selectedFilters.dateBegin !== "" && this.selectedFilters.dateEnd !== "") {
				if (
					moment(this.selectedFilters.dateBegin).isAfter(
						this.selectedFilters.dateEnd
					)
				) {
					const temp = this.selectedFilters.dateBegin;
					this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					this.selectedFilters.dateEnd = temp;
				}
			}
		},
		filteredReservations () {
			this.rezervariFiltrate = [];
			let filter = _.cloneDeep(this.filteredReservations);

			filter = filter.sort((a, b) => {
				return moment(a.reservation_date).diff(moment(b.reservation_date));
			});

			filter.forEach((reservation) => {
				const date = moment(reservation.reservation_date).format("DD.MM.YYYY");
				const size = this.parkingDayLogging.find((item) => moment(item.date).isSame(moment(reservation.reservation_date), "day"));

				let index = this.rezervariFiltrate.findIndex((item) => item.data === date);

				if (index === -1) {
					index = this.rezervariFiltrate.length;

					let maxDayReservations;
					if (size) {
						maxDayReservations = size.parking_size;
					} else {
						const day = moment(reservation.reservation_date).isoWeekday();
						let spaces;

						switch (day) {
							case 1:
								spaces = this.configState.find((item: IConfig) => item.name === ConfigType.MAX_PARKING_SPACES_MONDAY);

								if (spaces) {
									maxDayReservations = parseInt(spaces.value);
								} else {
									maxDayReservations = DefaultConfig.MAX_PARKING_SPACES;
								}
								break;
							case 2:
								spaces = this.configState.find((item: IConfig) => item.name === ConfigType.MAX_PARKING_SPACES_TUESDAY);

								if (spaces) {
									maxDayReservations = parseInt(spaces.value);
								} else {
									maxDayReservations = DefaultConfig.MAX_PARKING_SPACES;
								}
								break;
							case 3:
								spaces = this.configState.find((item: IConfig) => item.name === ConfigType.MAX_PARKING_SPACES_WEDNESDAY);

								if (spaces) {
									maxDayReservations = parseInt(spaces.value);
								} else {
									maxDayReservations = DefaultConfig.MAX_PARKING_SPACES;
								}
								break;
							case 4:
								spaces = this.configState.find((item: IConfig) => item.name === ConfigType.MAX_PARKING_SPACES_THURSDAY);

								if (spaces) {
									maxDayReservations = parseInt(spaces.value);
								} else {
									maxDayReservations = DefaultConfig.MAX_PARKING_SPACES;
								}
								break;
							case 5:
								spaces = this.configState.find((item: IConfig) => item.name === ConfigType.MAX_PARKING_SPACES_FRIDAY);

								if (spaces) {
									maxDayReservations = parseInt(spaces.value);
								} else {
									maxDayReservations = DefaultConfig.MAX_PARKING_SPACES;
								}
								break;
							default:
								maxDayReservations = DefaultConfig.MAX_PARKING_SPACES;
								break;
						}
					}

					this.rezervariFiltrate.push({
						data: date,
						numarCereriNealocate: 0,
						numarRezervari: 0,
						numarDisponibil: maxDayReservations,
						numarOcupatEfectiv: 0,
						numarRezervariAbsent: 0
					});
				}

				if (reservation.type === IParkingReservationType.IN_QUEUE || reservation.type === IParkingReservationType.NO_RESERVATION) {
					this.rezervariFiltrate[index].numarRezervari++;
					this.rezervariFiltrate[index].numarCereriNealocate++;
				} else if (reservation.type === IParkingReservationType.FIRST_ROUND_RESERVED || reservation.type === IParkingReservationType.SECOND_ROUND_RESERVED) {
					this.rezervariFiltrate[index].numarRezervari++;

					if (reservation.reservation_confirmed) {
						this.rezervariFiltrate[index].numarOcupatEfectiv++;
					} else {
						if (moment().isAfter(moment(reservation.reservation_date), "day")) {
							this.rezervariFiltrate[index].numarRezervariAbsent++;
						}
					}
				} else if (reservation.type === IParkingReservationType.ABSENT) {
					this.rezervariFiltrate[index].numarRezervari++;
					this.rezervariFiltrate[index].numarRezervariAbsent++;
				}
			});
		},
		selectedFilters: {
			handler () {
				this.filteredReservations = this.allReservations;

				if (this.selectedFilters.dateBegin) {
					if (!this.selectedFilters.dateEnd || moment(this.selectedFilters.dateEnd).isBefore(this.selectedFilters.dateBegin)) {
						this.selectedFilters.dateEnd = this.selectedFilters.dateBegin;
					}
				}

				if (this.selectedFilters.dateEnd) {
					if (!this.selectedFilters.dateBegin || moment(this.selectedFilters.dateBegin).isAfter(this.selectedFilters.dateEnd)) {
						this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					}
				}

				if (this.selectedFilters.dateBegin && this.selectedFilters.dateEnd) {
					this.filteredReservations = this.filteredReservations.filter(rez => {
						if (moment(rez.reservation_date).isBetween(moment(this.selectedFilters.dateBegin).startOf("day"), moment(this.selectedFilters.dateEnd).endOf("day"))) {
							return rez;
						}
					});
				}
			},
			deep: true
		}
	}
});
