



































































































































import { Vue } from "vue-property-decorator";
import moment from "moment";
import { IReservedConferenceRoom, IReservedDesk } from "@common/reservations";
import { IUser, Roles } from "@common/users";
import { IConferenceRoom } from "@common/conferenceRooms";
import { IUserGroups } from "@common/ui";

export default Vue.extend({
	name: "Statistics",
	data () {
		const rezervari: {
			department: string;
			name: string;
			etaj: string;
			data: string;
			ocupant: string;
			present: string;
			reservationDate: string;
			reservedBy: string;
			parking: string;
		}[] = [];
		const selectedFilters: {
			departments: string;
			rooms: string;
			floor: string;
			// dates: string[];
			dateBegin: string;
			dateEnd: string;
			occupier: string;
			reserved: string;
		} = {
			departments: "Toate",
			rooms: "Toate",
			floor: "Toate",
			// dates: [
			// 	"",
			// 	""
			// ],
			dateBegin: "",
			dateEnd: "",
			occupier: "Oricine",
			reserved: "Oricine"
		};
		const filters: {
			departments: string[];
			rooms: string[];
			floor: string[];
			// dates: string[];
			dateBegin: string;
			dateEnd: string;
			occupier: string[];
			reserved: string[];
		} = {
			departments: ["Toate"],
			rooms: ["Toate"],
			floor: ["Toate", "4", "5"],
			// dates: [
			// 	moment().format("YYYY-MM-DD"),
			// 	moment().format("YYYY-MM-DD")
			// ],
			dateBegin: moment().format("YYYY-MM-DD"),
			dateEnd: moment().format("YYYY-MM-DD"),
			occupier: ["Oricine"],
			reserved: ["Oricine"]
		};
		return {
			rezervari: rezervari,
			rezervariFiltrate: rezervari,
			filters: filters,
			selectedFilters: selectedFilters,
			// dateMenu: false,
			dateMenuBegin: false,
			dateMenuEnd: false,
			loading: true
		};
	},
	computed: {
		headers () {
			return [
				{
					text: "Departament",
					align: "center",
					value: "department"
				},
				{
					text: "Birou/Sală",
					align: "center",
					value: "name"
				},
				{
					text: "Etaj",
					value: "etaj",
					align: "center"
				},
				{
					text: "Ocupant",
					value: "ocupant",
					align: "center"
				},
				{
					text: "Prezent",
					value: "present",
					align: "center"
				},
				{
					text: "Data Cererii",
					value: "data",
					width: "120px",
					align: "center"
				},
				{
					text: "Data Rezervării",
					value: "reservationDate",
					align: "center",
					width: "200px"
				},
				{
					text: "Rezervat de",
					value: "reservedBy",
					align: "center"
				},
				{
					text: "Loc de Parcare",
					value: "parking",
					align: "center"
				}
			];
		},
		dateBeginFormatted (): string {
			if (this.selectedFilters.dateBegin) {
				return moment(this.selectedFilters.dateBegin).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
		dateEndFormatted () : string {
			if (this.selectedFilters.dateEnd) {
				return moment(this.selectedFilters.dateEnd).format("DD.MM.YYYY");
			} else {
				return "Selectează data";
			}
		},
	},
	methods: {
		allowedDates (val: string) {
			return new Date(val).getDay() !== 0 && new Date(val).getDay() !== 6;
		},
		async fetchAll () {
			const deskReservations: IReservedDesk[] = await this.$store.dispatch(
				"deskStore/fetchAll"
			);
			const roomReservations: IReservedConferenceRoom[] = await this.$store.dispatch(
				"roomStore/fetchAll"
			);
			const departments: IUserGroups = await this.$store.dispatch("userStore/loadGroups", {
				role: Roles.ADMIN
			});
			const users: IUser[] = await this.$store.dispatch("userStore/loadAllUsers");
			await this.$store.dispatch("roomStore/loadDetails", {});
			const rooms: IConferenceRoom[] = this.$store.getters["roomStore/roomsDetails"];

			const newReservations: {
				department: string;
				name: string;
				etaj: string;
				data: string;
				ocupant: string;
				present: string;
				reservationDate: string;
				reservedBy: string;
				parking: string;
			}[] = [];

			for (const desk of deskReservations) {
				const ocupant = users.find(el => el.uuid === desk.uuid)?.name;
				const resevedBy = users.find(el => el.uuid === desk.creatorUuid)?.name;
				const groupName = departments.groupList.find(el => el._id === desk.groupId)?.name;

				// this.filters.rooms.push(desk.deskId);

				if (ocupant) {
					this.filters.occupier.push(ocupant);
				}

				if (resevedBy) {
					this.filters.reserved.push(resevedBy);
				}

				const department = groupName || "-";
				if (this.filters.departments.indexOf(department) === -1) this.filters.departments.push(department);

				newReservations.push({
					department: department,
					name: "-",
					etaj: desk.deskId.split("-")[0][1],
					data: moment(desk.creationDate).format("YYYY-MM-DD"),
					ocupant: ocupant || "",
					present: "-",
					reservationDate: moment(desk.reservationDate).format(
						"YYYY-MM-DD"
					),
					reservedBy: resevedBy || "",
					parking: desk.parkingSpot ? "Da" : "Nu"
				});
			}

			for (const room of roomReservations) {
				const reservedBy = users.find(el => el.uuid === room.uuid)?.name;
				const roomName = rooms.find(el => el.id === room.conferenceRoomId)?.details?.name;

				if (roomName) {
					this.filters.rooms.push(roomName);
				}

				if (reservedBy) {
					this.filters.reserved.push(reservedBy);
				}

				if (moment(room.dateBegin).day() === moment(room.dateEnd).day() && moment(room.dateBegin).month() === moment(room.dateEnd).month()) {
					newReservations.push({
						department: "-",
						name: roomName || "",
						etaj: room.conferenceRoomId.split("-")[0][1],
						data: moment(room.creationDate).format("YYYY-MM-DD"),
						ocupant: "-",
						present: "-",
						reservationDate: moment(room.dateBegin).format(
							"DD/MM/YYYY hh:mm"
						) + " - " + moment(room.dateEnd).format("hh:mm"),
						reservedBy: reservedBy || "",
						parking: "-"
					});
				} else {
					newReservations.push({
						department: "-",
						name: roomName || "",
						etaj: room.conferenceRoomId.split("-")[0][1],
						data: moment(room.creationDate).format("YYYY-MM-DD"),
						ocupant: "-",
						present: "-",
						reservationDate: moment(room.dateBegin).format(
							"DD/MM/YYYY hh:mm"
						) + " - " + moment(room.dateEnd).format("DD/MM/YYYY hh:mm"),
						reservedBy: reservedBy || "",
						parking: "-"
					});
				}
			}

			newReservations.sort((a, b) => moment(a.data).diff(moment(b.data)));
			this.rezervari = newReservations;
			this.rezervariFiltrate = newReservations;

			this.loading = false;
		},
		resetFilters () {
			this.selectedFilters = {
				departments: "Toate",
				rooms: "Toate",
				floor: "Toate",
				dateBegin: "",
				dateEnd: "",
				occupier: "Oricine",
				reserved: "Oricine"
			};

			this.rezervariFiltrate = this.rezervari;
			this.$forceUpdate();
		},
		createCSV () {
			let csvContent = "data:text/csv;charset=utf-8," + "Departament,Birou/Sală,Etaj,Ocupant,Prezent,Data Cererii,Data Rezervării,Rezervat de,Loc de Parcare\n";
			for (const resv of this.rezervari) {
				csvContent += `${resv.department},${resv.name},${resv.etaj}, ${resv.ocupant},${resv.present},${resv.data}, ${resv.reservationDate},${resv.reservedBy},${resv.parking}\n`;
			}

			const url = encodeURI(csvContent);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "Rezervari.csv");
			document.body.appendChild(link);
			link.click();
		},
		disableOldDates (date: Date) {
			if (this.selectedFilters.dateBegin !== "") {
				if (moment(date).isBefore(this.selectedFilters.dateBegin)) {
					return false;
				}
			}

			return true;
		},
		disableFutureDates (date: Date) {
			if (this.selectedFilters.dateEnd !== "") {
				if (moment(date).isAfter(this.selectedFilters.dateEnd)) {
					return false;
				}
			}

			return true;
		}
	},
	watch: {
		"selectedFilters.dateEnd" () {
			if (this.selectedFilters.dateBegin !== "" && this.selectedFilters.dateEnd !== "") {
				if (
					moment(this.selectedFilters.dateBegin).isAfter(
						this.selectedFilters.dateEnd
					)
				) {
					// this.selectedFilters.dates = [
					// 	this.selectedFilters.dates[1],
					// 	this.selectedFilters.dates[0]
					// ];
					const temp = this.selectedFilters.dateBegin;
					this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					this.selectedFilters.dateEnd = temp;
				}
			}
		},
		selectedFilters: {
			handler () {
				this.rezervariFiltrate = this.rezervari;

				if (this.selectedFilters.departments !== "Toate") {
					this.rezervariFiltrate = this.rezervariFiltrate.filter(
						el => el.department === this.selectedFilters.departments
					);
				}

				if (this.selectedFilters.rooms !== "Toate") {
					this.rezervariFiltrate = this.rezervariFiltrate.filter(rez => rez.name === this.selectedFilters.rooms);
				}

				if (this.selectedFilters.floor !== "Toate") {
					this.rezervariFiltrate = this.rezervariFiltrate.filter(rez => rez.etaj === this.selectedFilters.floor);
				}

				if (this.selectedFilters.occupier !== "Oricine") {
					this.rezervariFiltrate = this.rezervariFiltrate.filter(rez => rez.ocupant === this.selectedFilters.occupier);
				}

				if (this.selectedFilters.reserved !== "Oricine") {
					this.rezervariFiltrate = this.rezervariFiltrate.filter(rez => rez.reservedBy === this.selectedFilters.reserved);
				}

				if (this.selectedFilters.dateBegin) {
					if (!this.selectedFilters.dateEnd || moment(this.selectedFilters.dateEnd).isBefore(this.selectedFilters.dateBegin)) {
						this.selectedFilters.dateEnd = this.selectedFilters.dateBegin;
					}
				}

				if (this.selectedFilters.dateEnd) {
					if (!this.selectedFilters.dateBegin || moment(this.selectedFilters.dateBegin).isAfter(this.selectedFilters.dateEnd)) {
						this.selectedFilters.dateBegin = this.selectedFilters.dateEnd;
					}
				}

				if (this.selectedFilters.dateBegin && this.selectedFilters.dateEnd) {
					this.rezervariFiltrate = this.rezervariFiltrate.filter(rez => {
						// If the reservation date is in a single day format
						if (rez.reservationDate.split("/").length === 3 || rez.reservationDate.split("-").length === 3) {
							let data = rez.reservationDate.split(" ")[0];

							if (data.includes("/")) {
								const dataMarkers = data.split("/");

								data = `${dataMarkers[2]}-${dataMarkers[1]}-${dataMarkers[0]}`;
							}

							if (moment(data).isBetween(this.selectedFilters.dateBegin, this.selectedFilters.dateEnd) || moment(data).isSame(this.selectedFilters.dateBegin) || moment(data).isSame(this.selectedFilters.dateEnd)) {
								return rez;
							}
						// Else if the reservation date is in a range format
						} else if (rez.reservationDate.split("/").length === 5) {
							let dateBegin = rez.reservationDate.split(" ")[0];
							let dateEnd = rez.reservationDate.split(" ")[3];

							if (dateBegin.includes("/")) {
								const dataMarkers = dateBegin.split("/");

								dateBegin = `${dataMarkers[2]}-${dataMarkers[1]}-${dataMarkers[0]}`;
							}

							if (dateEnd.includes("/")) {
								const dataMarkers = dateEnd.split("/");

								dateEnd = `${dataMarkers[2]}-${dataMarkers[1]}-${dataMarkers[0]}`;
							}

							// Check if the intervals overlap (dateBegin - dateEnd) and (this.selectedFilters.dateBegin - this.selectedFilters.dateEnd)
							const dateBeginTs = moment(dateBegin).unix();
							const dateEndTs = moment(dateEnd).unix();
							const dateBeginFilterTs = moment(this.selectedFilters.dateBegin).unix();
							const dateEndFilterTs = moment(this.selectedFilters.dateEnd).unix();

							if (dateBeginTs <= dateEndFilterTs && dateEndTs >= dateBeginFilterTs) {
								return rez;
							}
						}
					});
				}
			},
			deep: true
		}
	},
	mounted () {
		this.fetchAll();
		this.$forceUpdate();
	}
});
