var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"z-index":"2"}},[(!_vm.isMobile())?[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v("Filtre")]),_c('v-card-text',[_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de început","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateBeginFormatted),callback:function ($$v) {_vm.dateBeginFormatted=$$v},expression:"dateBeginFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1149031925),model:{value:(_vm.dateMenuBegin),callback:function ($$v) {_vm.dateMenuBegin=$$v},expression:"dateMenuBegin"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.disableFutureDates},model:{value:(_vm.selectedFilters.dateBegin),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "dateBegin", $$v)},expression:"selectedFilters.dateBegin"}})],1)],1),_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de sfarsit","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateEndFormatted),callback:function ($$v) {_vm.dateEndFormatted=$$v},expression:"dateEndFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,2974860834),model:{value:(_vm.dateMenuEnd),callback:function ($$v) {_vm.dateMenuEnd=$$v},expression:"dateMenuEnd"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.disableOldDates},model:{value:(_vm.selectedFilters.dateEnd),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "dateEnd", $$v)},expression:"selectedFilters.dateEnd"}})],1)],1),_c('v-row',{staticClass:"pa-2",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"width":"100%"},on:{"click":_vm.resetFilters}},[_vm._v("Șterge filtrele")])],1),_c('v-row',{staticClass:"pa-2",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"width":"100%"},on:{"click":_vm.createEXCEL}},[_vm._v("Descarcă XLS")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{staticClass:"pl-4 pr-4",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.rezervariFiltrate,"footer-props":{
						'items-per-page-options': [10, 15, 20]
					},"items-per-page":15,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
					var headers = ref.props.headers;
return [_c('thead',{staticClass:"active-header"},[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.value,style:({textAlign: h.align})},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.tooltip))])])],1)}),0)])]}}],null,false,778717168)})],1)],1)]:[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',[_c('v-card-title',[_vm._v("Filtre")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de început","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateBeginFormatted),callback:function ($$v) {_vm.dateBeginFormatted=$$v},expression:"dateBeginFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuBegin),callback:function ($$v) {_vm.dateMenuBegin=$$v},expression:"dateMenuBegin"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.disableFutureDates},model:{value:(_vm.selectedFilters.dateBegin),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "dateBegin", $$v)},expression:"selectedFilters.dateBegin"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dateMenuBegin = false}}},[_vm._v(" Selecteaza ")])],1)],1),_c('v-col',[_c('v-dialog',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de sfarsit","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateEndFormatted),callback:function ($$v) {_vm.dateEndFormatted=$$v},expression:"dateEndFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuEnd),callback:function ($$v) {_vm.dateMenuEnd=$$v},expression:"dateMenuEnd"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.disableOldDates},model:{value:(_vm.selectedFilters.dateEnd),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "dateEnd", $$v)},expression:"selectedFilters.dateEnd"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dateMenuEnd = false}}},[_vm._v(" Selecteaza ")])],1)],1)],1),_c('v-row',{staticClass:"pa-2",attrs:{"justify":"center"}},[_c('v-col',[_c('v-btn',{attrs:{"width":"100%"},on:{"click":_vm.resetFilters}},[_vm._v("Șterge filtrele")])],1),_c('v-col',[_c('v-btn',{attrs:{"width":"100%"},on:{"click":_vm.createEXCEL}},[_vm._v("Descarcă XLS")])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-data-table',{staticClass:"pl-4 pr-4",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.rezervariFiltrate,"footer-props":{
					'items-per-page-options': [5, 10, 20]
				},"items-per-page":5}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }