export interface ICar {
    _id?: string;
    license_plate: string;
    active: boolean;
    date_removed?: Date;
    user_id?: string;
    brand: string;
    permanent_access?: boolean;
    function_car?: boolean;
};

export function isCar(obj: any): obj is ICar {
    const isLicensePlate = obj.license_plate !== undefined && obj.license_plate !== null;
    const isActive = obj.active !== undefined && obj.active !== null;
    const isBrand = obj.brand !== undefined && obj.brand !== null;

    return isLicensePlate && isActive && isBrand;
}

export const NO_CAR : ICar = {
    _id: "-1",
    license_plate: "",
    active: false,
    brand: ""
}