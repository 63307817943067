















import { IConferenceRoomState } from "@common/ui";
import Vue from "vue";

export default Vue.extend({
	name: "ConfList",
	props: {
		departament: {
			type: String
		}
	},
	methods: {
		close () {
			this.$emit("close-room-list-dialog");
		},
		openRoomDialog (room:IConferenceRoomState) {
			this.$emit("open-room-dialog", { room: room, status: "room_dot.png" });
		}
	}
});
