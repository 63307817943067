




















































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import { ICar } from "@common/cars";
import { IConfig, ConfigType, DefaultConfig } from "@common/config";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IUser } from "@common/users";
import moment from "moment-timezone";

export default Vue.extend({
	name: "Account",
	computed: {
		...mapGetters({
			userState: "userStore/user",
			configState: "configStore/configs"
		})
	},
	mounted () {
		this.fetchData();
	},
	data () {
		const phoneNumber = "0777777777";
		const floorNumber = "1";
		const roomNumber = 42;
		const indisciplineScore = 0;
		const myCars: ICar[] = [];
		const brandNames: string[] = ["Alta marca", "Acura", "Aixam", "Alfa Romeo", "Aston Martin", "Audi", "Aro",
			"Bentley", "BMW", "Buick", "BYD", "Cadilac", "Chevrolet", "Chrysler", "Citroen", "Cupra",
			"Dacia", "Daewoo", "Daihatsu", "Daimler", "De Lorean", "Dodge", "DongFeng", "DS",
			"Ferrari", "Fiat", "Ford", "Geely", "GMC", "Honda", "Hummer", "Hyundai",
			"Infiniti", "Isuzu", "Iveco", "Jaguar", "Jeep", "KIA", "Koenigsegg",
			"Lada", "Lamborghini", "Lancia", "Land Rover", "Lexus", "Lincoln", "Lotus", "Lucid",
			"Maserati", "Maybach", "Mazda", "Mazzanti", "McLaren", "Mercedes-Benz", "MG", "Mini",
			"Mitsubishi", "NIO", "Nissan", "Opel", "Pagani", "Peugeot", "Polaris", "Polestar", "Pontiac",
			"Porsche", "Puma", "RAM", "Renault", "Rimac", "Rivian", "Rolls-Royce", "Rover",
			"Saab", "Seat", "Skoda", "Smart", "SsangYong", "Subaru", "Suzuki",
			"Tata", "Tesla", "Toyota", "Trabant", "Vauxhall", "Volkswagen", "Volvo", "XPENG"].sort();

		return {
			phoneNumber: phoneNumber,
			newPhoneNumber: phoneNumber,
			floorNumber: floorNumber,
			newFloorNumber: floorNumber,
			roomNumber: roomNumber,
			newRoomNumber: roomNumber,
			indisciplineScore: indisciplineScore,
			changePasswordDialog: false,
			carListDialog: false,
			addCarDialog: false,
			removedCars: myCars,
			myCars: myCars,
			brandNames: brandNames,
			foreignNumber: false,
			licensePlateNumber: "",
			brandName: "Alta marca",
			licensePlateRules: [
				(value: string) => !!value || "Necesar",
				(value: string) => {
					value = value.replaceAll(" ", "").toUpperCase().replaceAll("-", "");

					// Primul grup verifica numerele de tipul JJ 12 ABC si B 12 ABC, al doilea grup verifica B 123 ABC, al treilea grup verifica numerele rosii
					const licenseRegex = new RegExp("^((AB|AG|AR|B|BC|BH|BN|BR|BT|BV|BZ|CJ|CL|CS|CT|CV|DB|DJ|GJ|GL|GR|HD|HR|IF|IL|IS|MH|MM|MS|NT|OT|PH|SB|SJ|SM|SV|TL|TM|TR|VL|VN|VS)[0-9]{2}[A-Z]{3})$|^(B[0-9]{3}[A-Z]{3})$|^((AB|AG|AR|B|BC|BH|BN|BR|BT|BV|BZ|CJ|CL|CS|CT|CV|DB|DJ|GJ|GL|GR|HD|HR|IF|IL|IS|MH|MM|MS|NT|OT|PH|SB|SJ|SM|SV|TL|TM|TR|VL|VN|VS)[0-9]{3,6})$");

					return licenseRegex.test(value) || "Numarul de inmatriculare nu este valid";
				}
			],
			phoneNumberRules: [
				(value: string) => !!value || "Necesar",
				(value: string) => {
					const phoneRegex = new RegExp(/(^[0-9]{10}$)|(^\+[0-9]{11}[0-9]*$)/g);
					return phoneRegex.test(value) || "Numarul de telefon nu este valid";
				}
			],
			licensePlateAllValidRules: [
				(value: string) => !!value || "Necesar"
			],
			showPassword1: false,
			showPassword2: false,
			showPassword3: false,
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
			loading: true,
			errorAlert: false,
			errorMessage: "",
			changePhoneNumberDialog: false,
			changeRoomDialog: false,
			changeDepartmentDialog: false,
			newDepartmentName: "",
			rating: 5,
			color: "red",
			setDeleteCarDialog: false,
			deletingCar: {} as ICar
		};
	},
	methods: {
		getVersion () {
			return this.$store.getters.version;
		},
		async logout () {
			try {
				const res = await this.$store.dispatch("userStore/logout", {
					token: this.userState.token
				});
				if (res) {
					// TODO dispatch notification
					this.$router.push("/login");
				} else {
					console.error("ERROR on logout");
					// TODO error
				}
			} catch (error) {
				console.error(error);
			}
		},
		checkLicenseDoesntExist (license: string) {
			const inMyCars = this.myCars.find((car: ICar) => car.license_plate.toString() === license.toString());
			const inMyRemovedCars = this.removedCars.find((car: ICar) => car.license_plate.toString() === license.toString());

			return inMyCars === undefined && inMyRemovedCars === undefined;
		},
		checkValidLicense (license: string) {
			if (this.foreignNumber) {
				return true;
			}

			// Primul grup verifica numerele de tipul JJ 12 ABC si B 12 ABC, al doilea grup verifica B 123 ABC, al treilea grup verifica numerele rosii
			const licenseRegex = new RegExp("^((AB|AG|AR|B|BC|BH|BN|BR|BT|BV|BZ|CJ|CL|CS|CT|CV|DB|DJ|GJ|GL|GR|HD|HR|IF|IL|IS|MH|MM|MS|NT|OT|PH|SB|SJ|SM|SV|TL|TM|TR|VL|VN|VS)[0-9]{2}[A-Z]{3})$|^(B[0-9]{3}[A-Z]{3})$|^((AB|AG|AR|B|BC|BH|BN|BR|BT|BV|BZ|CJ|CL|CS|CT|CV|DB|DJ|GJ|GL|GR|HD|HR|IF|IL|IS|MH|MM|MS|NT|OT|PH|SB|SJ|SM|SV|TL|TM|TR|VL|VN|VS)[0-9]{3,6})$");
			license = license.replaceAll(" ", "").toUpperCase().replaceAll("-", "");

			return licenseRegex.test(license);
		},
		async fetchData () {
			this.loading = true;

			await this.$store.dispatch("configStore/load");
			let timeToRemove = parseInt(this.configState.find((config: IConfig) => config.name === ConfigType.CAR_CAN_BE_ADDED_AGAIN_AFTER_DAYS).value);

			if (timeToRemove === -1) {
				timeToRemove = DefaultConfig.CAR_CAN_BE_ADDED_AGAIN_AFTER_DAYS;
			}

			const allMyCars = await this.$store.dispatch("carsStore/fetch", { role: "user", active: false });
			this.removedCars = allMyCars.filter((car: ICar) => car.active === false);
			this.removedCars = this.removedCars.filter((car: ICar) => moment(car.date_removed).add(timeToRemove, "days").isAfter(moment()));

			this.myCars = allMyCars.filter((car: ICar) => car.active);

			const indisciplineScoreNew = await this.$store.dispatch("userStore/getIndiscipline");
			this.indisciplineScore = indisciplineScoreNew;

			// Indiscipline score is between 0 and 3, rating is between 0 and 3
			this.rating = 3 - this.indisciplineScore;
			if (this.rating < 0) {
				this.rating = 0;
			}

			this.color = this.rating === 0 ? "red" : this.rating === 1 ? "orange" : this.rating === 2 ? "yellow" : "green";

			this.$forceUpdate();
			this.loading = false;
		},
		async addCar () {
			if (!this.licensePlateNumber || (!this.checkValidLicense(this.licensePlateNumber) && !this.foreignNumber) || !this.brandName) {
				this.errorMessage = "Datele introduse nu sunt valide";
				this.errorAlert = true;

				return;
			}

			if (!this.checkLicenseDoesntExist(this.licensePlateNumber)) {
				this.errorMessage = "Masina cu acest numar de inmatriculare exista deja sau a existat in ultimele 60 de zile.";
				this.errorAlert = true;

				return;
			}

			const addCar: ICar = {
				brand: this.brandName,
				license_plate: this.licensePlateNumber.toUpperCase().replaceAll(" ", "").replaceAll("-", ""),
				active: true,
				user_id: this.userState.userInfo.uuid,
			};

			const res = await this.$store.dispatch("carsStore/add", { car: addCar });

			if (res) {
				this.addCarDialog = false;
				this.fetchData();
			} else {
				this.errorMessage = "Exista deja o masina cu acest numar de inmatriculare sau a existat in ultimele 60 de zile.";
				this.errorAlert = true;
			}
		},
		async deleteCar (car: ICar) {
			const res = await this.$store.dispatch("carsStore/delete", { car: car });

			if (res) {
				this.fetchData();
				this.setDeleteCarDialog = false;
			} else {
				console.error("ERROR on delete car");
				// TODO error
			}
		},
		async changeDepartmentName () {
			if (this.newDepartmentName === "" || this.newDepartmentName === undefined) {
				this.errorMessage = "Numele departamentului nu poate fi gol";
				this.errorAlert = true;

				return;
			}

			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/users/changeDepartmentName`,
				data: {
					departmentName: this.newDepartmentName
				}
			};
			const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				this.changeDepartmentDialog = false;
				this.userState.userInfo.departmentName = this.newDepartmentName;
				this.fetchData();
			} else {
				this.errorMessage = "Numele departamentului nu poate fi gol";
				this.errorAlert = true;
			}
		},
		async changePassword () {
			if (this.newPassword.length > 5) {
				if (this.newPassword === this.confirmPassword) {
					const options: AxiosRequestConfig = {
						method: "POST",
						url: `${this.$store.getters.API_URL}/users/changePassword`,
						data: {
							user: this.userState.userInfo,
							oldPassword: this.oldPassword,
							newPassword: this.newPassword
						}
					};
					const response: AxiosResponse<IServerRES<boolean>> = await axios(options);

					if (response.data.err === ServerError.NO_ERROR) {
						await this.$store.dispatch("userStore/logout", {
							token: this.userState.token
						});

						this.$router.push({ path: "/login", query: { reset: "true" } });
					} else {
						this.errorMessage = "Parola veche este incorectă";
						this.errorAlert = true;
					}
				} else {
					this.errorMessage = "Parolele nu corespund";
					this.errorAlert = true;
				}
			} else {
				this.errorAlert = true;
				this.errorMessage = "Parola trebuie să aibă cel puțin 6 caractere";
			}

			this.loading = false;
		},
		openChangePasswordDialog () {
			this.oldPassword = "";
			this.newPassword = "";
			this.confirmPassword = "";

			this.changePasswordDialog = true;
		},
		openAddCarDialog () {
			this.brandName = "";
			this.licensePlateNumber = "";
			this.foreignNumber = false;

			this.addCarDialog = true;
		},
		openChangePhoneNumber () {
			this.newPhoneNumber = this.userState.userInfo.phoneNumber;

			this.changePhoneNumberDialog = true;
		},
		openChangeRoom () {
			this.newRoomNumber = this.userState.userInfo.officeRoom;
			this.newFloorNumber = this.userState.userInfo.officeFloor;

			this.changeRoomDialog = true;
		},
		openChangeDepartament () {
			this.newDepartmentName = this.userState.userInfo.departmentName ? this.userState.userInfo.departmentName : "";
			this.changeDepartmentDialog = true;
		},
		async changePhoneNumber () {
			if (!this.phoneNumberRulesFnc(this.newPhoneNumber)) {
				return;
			}

			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/users/changePhoneNumber`,
				data: {
					phoneNumber: this.newPhoneNumber
				}
			};
			const response: AxiosResponse<IServerRES<IUser>> = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				this.changePhoneNumberDialog = false;

				this.$store.commit("userStore/user", {
					userInfo: response.data.payload,
					token: this.userState.token
				});
				this.fetchData();
			} else {
				this.errorMessage = "Numarul de telefon nu a putut fi schimbat";
				this.errorAlert = true;
			}
		},
		async changeRoom () {
			if (!this.roomNumber && !this.floorNumber.length) {
				return;
			}

			const options: AxiosRequestConfig = {
				method: "POST",
				url: `${this.$store.getters.API_URL}/users/changeRoom`,
				data: {
					officeRoom: this.newRoomNumber,
					officeFloor: this.newFloorNumber
				}
			};
			const response: AxiosResponse<IServerRES<IUser>> = await axios(options);

			if (response.data.err === ServerError.NO_ERROR) {
				this.changeRoomDialog = false;

				this.$store.commit("userStore/user", {
					userInfo: response.data.payload,
					token: this.userState.token
				});
				this.fetchData();
			} else {
				this.errorMessage = "Camera nu a putut fi schimbata";
				this.errorAlert = true;
			}
		},
		phoneNumberRulesFnc (number: string) {
			// eslint-disable-next-line
			const phoneRegex = new RegExp(/(^[0-9]{10}$)|(^\+[0-9]{11}[0-9]*$)/g);
			return phoneRegex.test(number);
		},
		openDeleteCarDialog (car: ICar) {
			this.setDeleteCarDialog = true;
			this.deletingCar = car;
		}
	},
	watch: {
		licensePlateNumber () {
			if (this.checkValidLicense(this.licensePlateNumber)) {
				this.checkLicenseDoesntExist(this.licensePlateNumber);
			}
		},
		foreignNumber () {
			if (this.checkValidLicense(this.licensePlateNumber)) {
				this.checkLicenseDoesntExist(this.licensePlateNumber);
			}
		},
		userState: {
			immediate: true,
			handler (newUserState, oldUserState) {
				if (oldUserState === undefined) {
					this.fetchData();
					return;
				}

				if (newUserState.userInfo.id !== oldUserState.userInfo.id) {
					this.fetchData();
				}
			},
			deep: true
		}
	}
});
