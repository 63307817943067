


































































































































import Vue from "vue";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import { IParkingReservation, IParkingReservationType, NO_PARKING_RESERVATION } from "@common/parkingReservations";
import { IUserState } from "@common/ui";
import { ICar } from "@common/cars";
import { ConfigType, DefaultConfig, IConfig } from "@common/config";

export default Vue.extend({
	name: "Reservations",
	computed: {
		...mapGetters({
			userState: "userStore/user",
			configState: "configStore/configs"
		})
	},
	data () {
		const reservations: IParkingReservation[] = [
			{
				date_added: moment("2023-02-28").toDate(),
				reservation_date: moment("2023-03-01").toDate(),
				user_id: "1",
				car_id: "1",
				reservation_confirmed: true,
				type: IParkingReservationType.FIRST_ROUND_RESERVED
			},
			{
				date_added: moment("2023-02-28").toDate(),
				reservation_date: moment("2023-03-02").toDate(),
				user_id: "1",
				car_id: "1",
				reservation_confirmed: true,
				type: IParkingReservationType.IN_QUEUE
			},
			{
				date_added: moment("2023-02-28").toDate(),
				reservation_date: moment("2023-03-03").toDate(),
				user_id: "1",
				car_id: "1",
				reservation_confirmed: true,
				type: IParkingReservationType.SECOND_ROUND_RESERVED
			},
			{
				date_added: moment("2023-02-28").toDate(),
				reservation_date: moment("2023-03-04").toDate(),
				user_id: "1",
				car_id: "1",
				reservation_confirmed: true,
				type: IParkingReservationType.ABSENT
			},
			{
				date_added: moment("2023-02-28").toDate(),
				reservation_date: moment("2023-03-05").toDate(),
				user_id: "1",
				car_id: "1",
				reservation_confirmed: true,
				type: IParkingReservationType.NO_RESERVATION
			}
		];

		const legendItems: {
			color: string,
			text: string,
			type: IParkingReservationType
		}[] = [
			{
				color: "blue",
				text: "Cerere in așteptare",
				type: IParkingReservationType.IN_QUEUE
			},
			{
				color: "green",
				text: "Rezervare confirmată în prima rundă de rezervări",
				type: IParkingReservationType.FIRST_ROUND_RESERVED
			},
			{
				color: "orange",
				text: "Rezervare confirmată după prima rundă de rezervări",
				type: IParkingReservationType.SECOND_ROUND_RESERVED
			},
			{
				color: "purple",
				text: "Rezervare neonorată",
				type: IParkingReservationType.ABSENT
			},
			{
				color: "red",
				text: "Rezervare neaprobată",
				type: IParkingReservationType.NO_RESERVATION
			}
		];

		const cars: ICar[] = [];

		return {
			dateBegin: new Date().toISOString().substring(0, 10),
			legendsDialog: false,
			legendItems: legendItems,
			reservations: reservations,
			rerenderDatePicker: 0,
			loading: true,
			selectedDate: "",
			reservationDialog: false,
			selectedRezervare: NO_PARKING_RESERVATION,
			cars: cars,
			availableCars: cars,
			confirmationDeadline: DefaultConfig.CONFIRMATION_DEADLINE_HOUR,
			snackbar: false,
			snackbarText: "",
			modifyCarDialog: false
		};
	},
	methods: {
		canBeDeleted (): boolean {
			let canDelete = true;

			const checkDate = moment(this.selectedRezervare.reservation_date)
				.subtract(1, "day")
				.set({ hour: this.confirmationDeadline, minute: 0, second: 0 })
				.toDate();

			if (this.selectedRezervare.type === IParkingReservationType.NO_RESERVATION ||
			this.selectedRezervare.type === IParkingReservationType.ABSENT ||
			this.selectedRezervare.reservation_confirmed === true ||
			moment().isAfter(moment(checkDate))) {
				canDelete = false;
			}

			return canDelete;
		},
		selectedReservationCarPlate (): string {
			return this.cars.find((car) => car._id === this.selectedRezervare.car_id)?.license_plate || "";
		},
		selectedDateStr (): string {
			return moment(this.selectedDate).format("YYYY-MM-DD");
		},
		selectedReservationStatus (): string {
			return this.legendItems.find((item) => item.type === this.selectedRezervare.type)?.text || "";
		},
		allowedDates (val: string) {
			return new Date(val).getDay() !== 0 && new Date(val).getDay() !== 6;
		},
		checkDate (date: string) {
			const reservation = this.reservations.find((reservation) => moment(reservation.reservation_date).isSame(moment(date), "day"));
			if (reservation) {
				return this.legendItems.find((item) => item.type === reservation.type)?.color;
			}

			return false;
		},
		async fetchData () {
			this.loading = true;
			this.cars = await this.$store.dispatch("carsStore/fetch", { role: "user", active: false });
			this.reservations = await this.$store.dispatch("parkingStore/fetch", { uuid: this.userState.userInfo.uuid });
			this.rerenderDatePicker++;

			await this.$store.dispatch("configStore/load");

			const everydayConf = this.configState.find((config: IConfig) => config.name === ConfigType.CONFIRMATION_DEADLINE_HOUR);
			if (everydayConf) {
				this.confirmationDeadline = parseInt(everydayConf.value);
			}

			this.$forceUpdate();
			this.loading = false;
		},
		closeReservation () {
			this.reservationDialog = false;
			this.modifyCarDialog = false;
			this.selectedRezervare = NO_PARKING_RESERVATION;
			this.selectedDate = "";
		},
		async deleteReservation () {
			const result = await this.$store.dispatch("parkingStore/delete", { parkingReservationId: this.selectedRezervare._id });

			if (result) {
				this.closeReservation();
				await this.fetchData();
			} else {
				this.snackbar = true;
				this.snackbarText = "Eroare la anularea rezervării";
			}
		},
		async modifyCar () {
			const result = await this.$store.dispatch("parkingStore/modifyCar", { parkingReservationId: this.selectedRezervare._id, carId: this.selectedRezervare.car_id });

			if (result) {
				this.closeReservation();
				await this.fetchData();
			} else {
				this.snackbar = true;
				this.snackbarText = "Eroare la modificarea mașinii";
			}
		},
		validChangeCar () {
			// Can change the car if the reservation is IN QUEUE, FIRST_ROUND_RESERVED or SECOND_ROUND_RESERVED
			// and the current time is before the cut-off hour in the previous day
			if (this.selectedRezervare.type !== IParkingReservationType.IN_QUEUE &&
				this.selectedRezervare.type !== IParkingReservationType.FIRST_ROUND_RESERVED &&
				this.selectedRezervare.type !== IParkingReservationType.SECOND_ROUND_RESERVED) {
				return false;
			}

			const checkDate = moment(this.selectedRezervare.reservation_date)
				.startOf("day")
				.subtract(1, "day")
				.hour(this.confirmationDeadline);

			if (moment().isAfter(checkDate)) {
				return false;
			}

			return true;
		}
	},
	watch: {
		userState: {
			immediate: true,
			async handler (newState: IUserState) {
				if (newState.userInfo) {
					await this.fetchData();
				}
			}
		},
		reservationDialog: {
			immediate: true,
			handler (newVal: boolean) {
				if (!newVal) {
					this.selectedDate = "";
				}
			}
		},
		selectedDate: {
			immediate: true,
			handler (newDate: string) {
				if (newDate) {
					const reservation = this.reservations.find((reservation) => moment(reservation.reservation_date).isSame(moment(newDate), "day"));
					if (reservation) {
						this.reservationDialog = true;
						this.selectedRezervare = reservation;
					}
				}
			}
		},
		cars: {
			immediate: true,
			handler (newCars: ICar[]) {
				this.availableCars = newCars.filter((car) => car.active === true);
			}
		}
	}
});
